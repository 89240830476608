import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import Constants from "variables/Constants";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@mui/material/FormControl";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(styles);

const Content = () => {
  const classes = useStyles();
  const [subjects, setSubjects] = useState([]);
  const [subjectTitle, setSubjectTitle] = useState("");
  const [subjectWeight, setSubjectWeight] = useState("");
  const [subjectForm, setSubjectForm] = useState(false);
  const [subjectDeleteCon, setSubjectDeleteCon] = useState(false);
  const [subjectToDelete, setSubjectToDelete] = useState(null);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedWeight, setUpdatedWeight] = useState("");
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [updateFormOpen, setUpdateFormOpen] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [subjectId, setSubjectId] = useState(null);
  const [chapterModal, setChapterModal] = useState(false);
  const [addChapter, setAddChapter] = useState(false);
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterWeight, setChapterWeight] = useState("");
  const [updateChapterTitle, setUpdateChapterTitle] = useState("");
  const [updateChapterWeight, setUpdateChapterWeight] = useState("");
  const [updateChaptersubChap, setUpdateChaptersubChap] = useState();
  const [chapUpdateForm, setChapUpdateForm] = useState(false);
  const [updateChapterId, setUpdateChapterId] = useState(null);
  const [chapterDeleteCon, setChapterDeleteCon] = useState(false);
  const [chapterToDelete, setChapterToDelete] = useState(null);
  const [subChapters, setSubChapters] = useState([]);
  const [chapterId, setChapterId] = useState(null);
  const [subchapModal, setSubchapModal] = useState(false);
  const [addSubChapModal, setAddSubChapModal] = useState(false);
  const [subChapTitle, setSubChapTitle] = useState("");
  const [subChapWeight, setSubChapWeight] = useState("");
  const [updatedSubChapTitle, setUpdatedSubChapTitle] = useState("");
  const [updatedSubChapWeight, setUpdatedSubChapWeight] = useState("");
  const [subchapUpdateModal, setSubchapUpdateModal] = useState(false);
  const [updateSubChapID, setUpdateSubChapID] = useState(null);
  const [deleteSubChapterId, setDeleteSubChapterId] = useState(null);
  const [subChapDeleteCon, setSubChapDeleteCon] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [updateSnackbar, setUpdateSnackbar] = useState(false);
  const [createSnackBar,setCreateSnackBar] = useState(false)

  useEffect(() => {
    fetchAllSubject();
    fetchAllChapters(setSubjectId);
    fetchAllSubChapter(chapterId);
  }, []);

  const fetchAllSubject = async () => {
    try {
      const res = await axios.get(Constants.TrackerSubject.getAll);
      setSubjects(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createSubject = async (event) => {
    event.preventDefault();
    const payLoad = { title: subjectTitle, description: subjectWeight };
    try {
      await axios.post(Constants.TrackerSubject.postSuject, payLoad);
      setCreateSnackBar(true)
      setSubjectForm(false);
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };

  const delSubject = async () => {
    try {
      await axios.delete(
        Constants.TrackerSubject.getAll + "/" + subjectToDelete
      );
      setSubjectDeleteCon(false);
      setDeleteSnackbarOpen(true);
      setSubjectToDelete(null);
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };

  const subjectUpdate = async (e) => {
    e.preventDefault();
    if (!selectedSubject) return;

    const payLoad = { title: updatedTitle, description: updatedWeight };
    try {
      await axios.post(
        `${Constants.TrackerSubject.getAll}/${selectedSubject.id}`,
        payLoad
      );
      setUpdateFormOpen(false);
      setUpdateSnackbar(true);
      setSelectedSubject(null);
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllChapters = async (subjectId) => {
    if (!subjectId) {
      console.error("Subject ID is required to fetch chapters.");
      return;
    }
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/chapter?subject_id=${subjectId}`
      );
      setChapters(res.data.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const createChapter = async (event) => {
    event.preventDefault();
    const payload = {
      title: chapterTitle,
      description: chapterWeight,
      subject_id: subjectId,
    };
    try {
      const res = await axios.post(Constants.TrackerChapter.getAll, payload);
      setCreateSnackBar(true)
      fetchAllChapters(subjectId);
      setAddChapter(false);
    } catch (error) {
      console.log(error);
    }
  };

  const chapterUpdate = async (event) => {
    event.preventDefault();
    const payLoad = {
      title: updateChapterTitle,
      description: updateChapterWeight,
      subject_id: subjectId,
    };
    try {
      await axios.post(
        `${Constants.TrackerChapter.getAll}/${updateChapterId}`,
        payLoad
      );
      fetchAllChapters(subjectId);
      setChapUpdateForm(false);
      setUpdateSnackbar(true);
    } catch (error) {
      console.error(error);
    }
  };
  const delChapter = async () => {
    try {
      await axios.delete(
        `${Constants.TrackerChapter.getAll}/${chapterToDelete}`
      );

      setChapterDeleteCon(false);
      setDeleteSnackbarOpen(true);
      setChapterToDelete(null);
      fetchAllChapters(subjectId);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSubChapter = async (chapterId) => {
    if (!chapterId) return; // Ensure chapterId is provided
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/subchapter?chapter_id=${chapterId}`
      );
      setSubChapters(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const creteSubChapter = async (e) => {
    e.preventDefault();
    const payload = {
      title: subChapTitle,
      description: subChapWeight,
      subject_id: subjectId,
      chapter_id: chapterId,
    };
    try {
      const res = await axios.post(
        // `https://admin.akclasses.in/api/subchapter/`
        Constants.TrackerSubChapter.getAll,
        payload
      );
      setCreateSnackBar(true)
     fetchAllSubChapter(chapterId)
      setAddSubChapModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Update subchapter function
  const updateSubChapter = async (e) => {
    e.preventDefault();
    const payload = {
      title: updatedSubChapTitle,
      description: updatedSubChapWeight,
      subject_id: subjectId,
      chapter_id: chapterId,
    };
    try {
      await axios.post(
        `${Constants.TrackerSubChapter.getAll}/${updateSubChapID}`,
        payload
      );
      setSubchapUpdateModal(false);
      setUpdateSnackbar(true);
      fetchAllSubChapter(chapterId);
    } catch (error) {
      console.log(error);
    }
  };

  const delSubChapter = async () => {
    try {
      await axios.delete(
        Constants.TrackerSubChapter.getAll + "/" + deleteSubChapterId
      );
      setDeleteSnackbarOpen(true);
      setSubChapDeleteCon(false)
      fetchAllSubChapter(chapterId);
    } catch (error) {
      console.log(error);
    }
  };
  const subjectStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(`${Constants.TrackerSubject.getAll}/${id}`, {
        status: updatedStatus,
      });

      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };
  const chapterStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(`${Constants.TrackerChapter.getAll}/${id}`, {
        status: updatedStatus,
      });

      fetchAllChapters(subjectId);
    } catch (error) {
      console.log(error);
    }
  };

  const subChapterStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(Constants.TrackerSubChapter.getAll + "/" + id, {
        status: updatedStatus,
      });

      fetchAllChapters(subjectId);
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 763,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const modalContentStyle = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  return (
    <div>
      <Modal
        open={subjectForm}
        onClose={() => setSubjectForm(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Subject Form
          </Typography>
          <form onSubmit={createSubject}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setSubjectTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              onChange={(e) => setSubjectWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setSubjectForm(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={subjectDeleteCon}
        onClose={() => setSubjectDeleteCon(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2 }}>
            <strong>Are you sure you want to delete this subject?</strong>
          </Typography>
          <Button
            className="btn-round"
            color="danger"
            variant="contained"
            onClick={delSubject}
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
          <Button
            className="btn-round"
            color="info"
            variant="contained"
            onClick={() => setSubjectDeleteCon(false)}
            sx={{ mt: 2, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal
        open={updateFormOpen}
        onClose={() => setUpdateFormOpen(false)}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Subject
          </Typography>
          <form onSubmit={subjectUpdate}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              value={updatedTitle}
              onChange={(e) => setUpdatedTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              value={updatedWeight}
              onChange={(e) => setUpdatedWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setUpdateFormOpen(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={chapterModal}
        onClose={() => setChapterModal(false)}
        aria-labelledby="chapter-modal-title"
        aria-describedby="chapter-modal-description"
      >
        <Box sx={modalStyle}>
          <Button
            onClick={() => {
              setAddChapter(true);
            }}
            color="primary"
          >
            Add Chapter
          </Button>
          <CancelIcon
            style={{ marginLeft: 530, cursor: "pointer",position:"absolute",top:0,right:0 }}
            onClick={() => setChapterModal(false)}
          />

          <Typography id="chapter-modal-title" variant="h6" component="h2">
            Chapter Table
          </Typography>

          <Box sx={modalContentStyle}>
            {" "}
            {/* Apply the new style here */}
            <Table
              tableHead={[
                "ID",
                "SUBJECT NAME",
                "TITLE",
                "Weightage",
                "STATUS",
                "Actions",
                "Subchapter",
              ]}
              tableData={chapters.map((item, index) => [
                index + 1,
                item.subject?.title,
                item.title,
                item.description,
                [
                  <Select
                    key={index}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={item.status}
                    inputProps={{
                      name: "status",
                    }}
                    onChange={(e) =>
                      chapterStatusChange(item.id, e.target.value)
                    } // Call the function here
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="active"
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="inactive"
                    >
                      Inactive
                    </MenuItem>
                  </Select>,
                ],
                [
                  // <Button
                  //   key={`update-${index}`}
                  //   onClick={() => {
                  //     setUpdateChapterId(item.id);
                  //     setUpdateChapterTitle(item.title);
                  //     setUpdateChapterWeight(item.description);
                  //     setChapUpdateForm(true);
                  //   }}
                  //   className="btn-round"
                  //   color="warning"
                  // >
                  //   Update chapter
                  // </Button>,
                  <Tooltip title="Update Chapter">
                   <CreateIcon style={{cursor: "pointer",color:"blue"}}
                   onClick={() => {
                    setUpdateChapterId(item.id);
                    setUpdateChapterTitle(item.title);
                    setUpdateChapterWeight(item.description);
                    setChapUpdateForm(true);
                  }}/>
                  </Tooltip>,

                  // <Button
                  //   key={`delete-${index}`}
                  //   onClick={() => {
                  //     setChapterToDelete(item.id);
                  //     setChapterDeleteCon(true);
                  //   }}
                  //   className="btn-round"
                  //   color="danger"
                  // >
                  //   Delete
                  // </Button>,
                  <Tooltip title="Delete Chapter">
                    <DeleteIcon style={{cursor:"pointer",color:"red"}}
                  onClick={() => {
                    setChapterToDelete(item.id);
                    setChapterDeleteCon(true);
                  }}/>
                  </Tooltip>
                ],
                [
                  <Button
                    onClick={() => {
                      setChapterId(item.id);
                      fetchAllSubChapter(item.id);
                      setSubchapModal(true);
                    }}
                    className="btn-round"
                    color="danger"
                  >
                    Subchapter
                  </Button>,
                ],
              ])}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={addChapter}
        onClose={() => setAddChapter(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            chapter Form
          </Typography>
          <form onSubmit={createChapter}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setChapterTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              onChange={(e) => setChapterWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setAddChapter(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={chapUpdateForm}
        onClose={() => setChapUpdateForm(false)}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Chapter
          </Typography>
          <form onSubmit={chapterUpdate}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              value={updateChapterTitle}
              onChange={(e) => setUpdateChapterTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              value={updateChapterWeight}
              onChange={(e) => setUpdateChapterWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setChapUpdateForm(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={subchapModal}
        onClose={() => setSubchapModal(false)}
        aria-labelledby="chapter-modal-title"
        aria-describedby="chapter-modal-description"
      >
        <Box sx={modalStyle} style={{ width: 763 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "50%",
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <Button
              onClick={() => {
                setAddSubChapModal(true);
              }}
              color="primary"
            >
              Add subchapterter
            </Button>

            <CancelIcon
              style={{ cursor: "pointer" }}
              onClick={() => setSubchapModal(false)}
            />
          </div>

          <Typography id="chapter-modal-title" variant="h6" component="h2">
            subchapter Table
          </Typography>

          <Box sx={modalContentStyle}>
            <Table
              tableHead={[
                "ID",
                "SUBJECT NAME",
                "Chapter Name",
                "TITLE",
                "Weightage",
                "STATUS",
                "Actions",
              ]}
              tableData={subChapters.map((item, index) => [
                index + 1,
                item.subject?.title,
                item.chapter?.title,
                item.title,
                item.description,
                [
                  <Select
                    key={index}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={item.status}
                    inputProps={{
                      name: "status",
                    }}
                    onChange={(e) =>
                      subChapterStatusChange(item.id, e.target.value)
                    }
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="active"
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="inactive"
                    >
                      Inactive
                    </MenuItem>
                  </Select>,
                ],
                [
              
                  <Tooltip title="Update SubChapter">
                 <CreateIcon style={{cursor: "pointer",color:"blue"}}
                  onClick={() => {
                    setUpdateSubChapID(item.id);
                    setUpdatedSubChapTitle(item.title);
                    setUpdatedSubChapWeight(item.description);
                    setSubchapUpdateModal(true); 
                  }}/></Tooltip>,
               
                  <Tooltip title="delete subchapter">
                    <DeleteIcon style={{cursor:"pointer",color:"red"}}
                  onClick={() => {
                    setDeleteSubChapterId(item.id);
                    setSubChapDeleteCon(true);
                  }}
                  /></Tooltip>
                ],
              ])}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={chapterDeleteCon}
        onClose={() => setChapterDeleteCon(false)}
        aria-labelledby="delete-chapter-modal-title"
        aria-describedby="delete-chapter-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="delete-chapter-modal-title"
            variant="h6"
            component="h2"
          >
            Confirm Deletion
          </Typography>
          <Typography id="delete-chapter-modal-description" sx={{ mt: 2 }}>
            <strong>Are you sure you want to delete this chapter?</strong>
          </Typography>
          <Button
            className="btn-round"
            color="danger"
            variant="contained"
            onClick={delChapter}
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
          <Button
            className="btn-round"
            color="info"
            variant="contained"
            onClick={() => setChapterDeleteCon(false)}
            sx={{ mt: 2, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal
        open={addSubChapModal}
        onClose={() => setAddSubChapModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Sub chapter Form
          </Typography>
          <form onSubmit={creteSubChapter}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setSubChapTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              onChange={(e) => setSubChapWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setAddSubChapModal(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={subchapUpdateModal}
        onClose={() => setSubchapUpdateModal(false)}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Subchapter
          </Typography>
          <form onSubmit={updateSubChapter}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              value={updatedSubChapTitle} // Use the state variable
              onChange={(e) => setUpdatedSubChapTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              value={updatedSubChapWeight} // Use the state variable
              onChange={(e) => setUpdatedSubChapWeight(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setSubchapUpdateModal(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal
        open={subChapDeleteCon}
        onClose={() => setSubChapDeleteCon(false)}
        aria-labelledby="delete-chapter-modal-title"
        aria-describedby="delete-chapter-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="delete-chapter-modal-title"
            variant="h6"
            component="h2"
          >
            Confirm Deletion
          </Typography>
          <Typography id="delete-chapter-modal-description" sx={{ mt: 2 }}>
            <strong>Are you sure you want to delete this subchapter?</strong>
          </Typography>
          <Button
            className="btn-round"
            color="danger"
            variant="contained"
            onClick={delSubChapter}
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
          <Button
            className="btn-round"
            color="info"
            variant="contained"
            onClick={() => setSubChapDeleteCon(false)}
            sx={{ mt: 2, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
            textAlign: "center",
          },
        }}
      />

      <Snackbar
        open={updateSnackbar}
        autoHideDuration={3000}
        onClose={() => setUpdateSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
            textAlign: "center",
          },
        }}
      />
      <Snackbar
        open={createSnackBar}
        autoHideDuration={3000}
        onClose={() => setCreateSnackBar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Created Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
            textAlign: "center",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
              <Button
                className="btn-round"
                color="info"
                onClick={() => setSubjectForm(true)}
              >
                Add Subject
              </Button>
              <Table
                tableHead={[
                  "Id",
                  "Subjects",
                  "Weightage",
                  "Status",
                  "Actions",
                  "Chapters",
                ]}
                tableData={subjects.map((item, index) => [
                  index + 1,
                  item.title,
                  item.description,
                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status}
                      inputProps={{
                        name: "status",
                      }}
                      onChange={(e) =>
                        subjectStatusChange(item.id, e.target.value)
                      } // Call the function here
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <Tooltip title="Update Subject">

                  
                      <CreateIcon style={{cursor: "pointer",color:"blue"}}
                       onClick={() => {
                        setSelectedSubject(item);
                        setUpdatedTitle(item.title);
                        setUpdatedWeight(item.description);
                        setUpdateFormOpen(true);
                      }}
                      />
                        </Tooltip>,
                       <Tooltip title="Delete Subject">
                      <DeleteIcon style={{cursor:"pointer",color:"red"}}
                       onClick={() => {
                        setSubjectToDelete(item.id);
                        setSubjectDeleteCon(true);
                      }}
                      />
                      </Tooltip> 
                  
                  ],
                  [
                    <Button
                      onClick={() => {
                        setSubjectId(item.id);
                        fetchAllChapters(item.id);
                        setChapterModal(true);
                        setChapterId(item.id);
                      }}
                      className="btn-round"
                      color="rose"
                    >
                      Chapter
                    </Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Content;
