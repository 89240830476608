import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Constants from "../ak_web/src/components/common/Constants";
// const dummyData = [
//   {
//     id: 1,
//     name:"n1",
//     amount: 250,
//     phone: "4455667788",
//     date: "04-03-2024",
//     status: "Settled",
//   },
//   {
//     id: 2,
//     name:"n2",
//     amount: 500,
//     phone: "1122334455",
//     date: "05-03-2024",
//     status: "Pending",
//   },
//   {
//     id: 3,
//     name:"n3",
//     amount: 750,
//     phone: "9988776655",
//     date: "06-03-2024",
//     status: "Settled",
//   },
// ];

function ApprovedWallet() {
  let [walletData, setWalletData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [search, setSearch] = useState("");

  function getAllData() {
    setLoading(true);
    console.log("test");
    axios
      .get(Constants.getUrls.getallWallet + `?status=approved&q=${search}`)
      .then((res) => {
        console.log("wallet data approved", res);
        setWalletData(res?.data?.data?.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAllData();
  }, [search]);

  function walletSearch(e) {
    setSearch(e.target.value);
    // setPage(1);
  }

  return (
    <div>
      <div>
        <h3>Approved Wallet</h3>
      </div>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          variant="contained"
          sx={{
            color: "white",
            "&:hover": {
              color: "white",
            },
          }}
          color="warning"
          component={Link}
          to="/admin/walletmanage"
        >
          Pending
        </Button>

        <Box
          component="form"
          sx={{ "& > :not(style)": { width: "30ch" } }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Search by Name & Mobile"
            size="small"
            onChange={walletSearch}
            variant="outlined"
          />
        </Box>
      </Box>

      {/* <Button
      variant="contained"
      sx={{
        color: "white",
        "&:hover": {
          color: "white",
        },
      }}
      color="warning"
      component={Link}
      to="/admin/walletmanage"
    >
      Pending
    </Button> */}

      <TableContainer component={Paper} style={{ marginTop: "25px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Amount(Rs)</TableCell>
              <TableCell>Approved Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <h3>Loading...</h3>
              </TableCell>
            </TableRow>
          ) : walletData?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <h3>No data found!</h3>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {walletData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.user?.FIRST_NAME ? item?.user?.FIRST_NAME : "NA"}
                  </TableCell>
                  <TableCell>
                    {item?.user?.MOBILE ? item?.user?.MOBILE : "NA"}
                  </TableCell>
                  <TableCell>{item?.amount ? item?.amount : "NA"}</TableCell>
                  <TableCell>
                    {item?.approved_at ? item?.approved_at : "NA"}
                  </TableCell>
                  <TableCell>{item?.status ? item?.status : "NA"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default ApprovedWallet;
