import DashboardIcon from "@material-ui/icons/Dashboard";
import Place from "@material-ui/icons/Place";
import WidgetsIcon from "@material-ui/icons/Widgets";
import AboutUs from "views/AboutUs";
import AchieveGoalSection from "views/AchieveGoalSection";
import AdministratorProfile from "views/AdministratorProfile";
import BatchTimings from "views/BatchTimings";
import BookCategories from "views/BookCategories";
import BookLevel from "views/BookLevel";
import BookOfflineOrders from "views/BookOfflineOrders";
import BookOnlineOrders from "views/BookOnlineOrders";
import BooksMaster from "views/BooksMaster";
import BookStream from "views/BookStream";
import BookUsers from "views/BookUsers";
import CashbackAmount from "views/CashbackAmount";
import Categories from "views/Categories";
import ChangePassword from "views/ChangePassword";
import ContactUs from "views/ContactUs";
import CouponCodes from "views/CouponCodes";
import CourseLevels from "views/CourseLevels";
import CoursesMaster from "views/CoursesMaster";
import CourseStreams from "views/CourseStreams";
import Dashboard from "views/Dashboard/Dashboard.js";
import DeviceInfo from "views/DeviceInfo";
import ExpenseModes from "views/ExpenseModes";
import ExpensesEntry from "views/ExpensesEntry";
import ExpensesReport from "views/ExpensesReport";
import ExtensionMaster from "views/ExtensionMaster";
import Faculty from "views/Faculty";
import FacultyOnlineOrders from "views/FacultyOnlineOrders";
import FacultyReport from "views/FacultyReport";
import FAQs from "views/FAQs";
import FooterAboutUs from "views/FooterAboutUs";
import FooterTechnicalSupport from "views/FooterTechnicalSupport";
import FranchiseeCenters from "views/FranchiseeCenters";
import FreeUsers from "views/FreeUsers";
import HomeSliderImages from "views/HomeSliderImages";
import IncExpCat from "views/IncExpCat";
import IncomeEntry from "views/IncomeEntry";
import IncomeReport from "views/IncomeReport";
import IncomeType from "views/IncomeType";
import Logistics from "views/Logistics";
import MailTemplates from "views/MailTemplates";
import Mediatype from "views/Mediatype";
import OfflineOrders from "views/OfflineOrders";
import OldUsers from "views/OldUsers";
import OnlineOrders from "views/OnlineOrders";
import OStype from "views/OStype";
import Otps from "views/Otps";
import PremiumUsers from "views/PremiumUsers";
import ProductKeys from "views/ProductKeys";
import Questions from "views/Questions";
import ReferenceCodes from "views/ReferenceCodes";
import ServicesText from "views/ServicesText";
import SocialMediaLinks from "views/SocialMediaLinks";
import Subjects from "views/Subjects";
import SummaryReport from "views/SummaryReport";
import TechnicalSupport from "views/TechnicalSupport";
import TestCategories from "views/TestCategories";
import TestimonialMaster from "views/TestimonialMaster";
import TestInstructions from "views/TestInstructions";
import TestLevels from "views/TestLevels";
import TestSeries from "views/TestSeries";
import TestStreams from "views/TestStreams";
import TextBelowSlider from "views/TextBelowSlider";
import TrackOrder from "views/TrackOrder";
import VendorOrders from "views/VendorOrders";
import Vendors from "views/Vendors";
import ViewsValidity from "views/ViewsValidity";
import VsmartOrders from "views/VsmartOrders";
import YoutubeVideo from "views/YoutubeVideo";
// import FacultyBookOrders from "views/FacultyBookOrders";
import AddFacultyOrder from "AddViews/AddFacultyOrder";
import ImportUsers from "AddViews/ImportUsers";
import ActivationProcess from "views/ActivationProcess";
import Ads from "views/Ads";
import Announcements from "views/Announcements";
import AppDashboard from "views/AppDashboard";
import AppFaculty from "views/AppFaculty";
import AppQuestions from "views/AppQuestions";
import AppSubjects from "views/AppSubjects";
import BookSubjects from "views/BookSubjects";
import BudgetMode from "views/BudgetMode";
import BudgetReport from "views/BudgetReport";
import Chapters from "views/Chapters";
import CourseSubjects from "views/CourseSubjects";
import CustomTestSeries from "views/CustomTestSeries";
import Downloadables from "views/Downloadables";
import DownloadMaster from "views/DownloadMaster";
import FacultyOfflineOrders from "views/FacultyOfflineOrders";
import FacultyPassword from "views/FacultyPassword";
import Levels from "views/Levels";
import LiveQuiz from "views/LiveQuiz";
import LiveStreaming from "views/LiveStreaming";
import MarketingPanel from "views/MarketingPanel";
import Messages from "views/Messages";
import Notifications from "views/Notifications";
import QuatraEntries from "views/QuatraEntries";
import QuatraReport from "views/QuatraReport";
import Queries from "views/Queries";
import Results from "views/Results";
import Schedule from "views/Schedule";
import SingleFacultyReport from "views/SingleFacultyReport";
import SingleVendorOrders from "views/SingleVendorOrders";
import Strategies from "views/Strategies";
import Templates from "views/Templates";
import TestGiven from "views/TestGiven";
import TestSubjects from "views/TestSubjects";
import Topics from "views/Topics";
import TopScores from "views/TopScores";
import UploadAnAnswer from "views/UploadAnAnswer";
import VendorOfflineOrders from "views/VenderOfflineOrders";
import VendorDashboardOrders from "views/VendorDashBoardOrders";
import VendorPassword from "views/VendorPassword";
// import OnlinePendingOrders from "views/OnlinePendingOrders";
import Attempts from "views/Attempts";
import Meetings from "views/Meetings";
import MentorSessions from "views/MentorSessions";
import QuestionBank from "views/QuestionBank";
import QuestionBankChapter from "views/QuestionBankChapter";
import QuestionBankLevel from "views/QuestionBankLevel";
import QuestionBankSubject from "views/QuestionBankSubject";
//scanner componenets
import Activate_Strategies from "views/activateStrategies";
import Amenities from "views/Amenities";
import Batches from "views/Batches";
import BatchWings from "views/BatchWings";
import CommissionReport from "views/CommissionReport";
import DocumentManager from "views/DocumentManager/DocumentManager";
import FaceToFace from "views/FaceToFace";
import Face_To_Face_Batch from "views/FaceToFaceBatch";
import FaceToFaceCategory from "views/FaceToFaceCategory";
import FaceToFaceLevel from "views/FaceToFaceLevel";
import FaceToFaceOfflineOrder from "views/FaceToFaceOfflineOrder";
import FaceToFaceOrder from "views/FaceToFaceOrder";
import FaceToFaceStream from "views/FaceToFaceStream";
import FaceToFaceSubject from "views/FaceToFaceSubject";
import FaceToFaceUsers from "views/FaceToFaceUsers";
import FacultyCommission from "views/FacultyCommission";
import FacultyCommissionReport from "views/FacultyCommissionReport";
import LecturesLink from "views/LecturesLink";
import Manual_Notification from "views/ManualNotification";
import MobileSliderImages from "views/MobileSliderImages";
import Monitoring_System from "views/MonitoringSystem";
import Monitoring_System_Dashboard from "views/MonitoringSystemDashboard";
import ScannerChapter from "views/ScannerChapter";
import ScannerLevel from "views/ScannerLevel";
import ScannerQuestion from "views/ScannerQuestion";
import ScannerSource from "views/ScannerSource";
import ScannerSubject from "views/ScannerSubject";
import Source from "views/Source";
import SubjectFilter from "views/SubjectFilter";
import VendorCommission from "views/VendorCommission";
import VendorCommissionReport from "views/VendorCommissionReport";
import VendorOrdersOffline from "views/VendorOrdersOffline";
import VersionNumber from "views/VersionNumber";
import WhatStudentsSay from "views/WhatStudentsSay";
import MainMaster from "./components/youtube/MainMaster"

import SubChapter from "./components/youtube/SubChapter"
import Rchapter from "./components/youtube/Rchapter";
import Tracker from "./components/youtube/Tracker";
import YoutubeTry from "./components/youtube/YoutubeTry"
import FinalMaster from "./components/youtube/FinalMaster";
import Test1 from "./components/Testing/Test1"
import Test2 from "./components/Testing/Test2"
import Trackerdrops from "./components/youtube/Trackerdrops"
import Content from "./components/youtube/Content"
import WalletManagement from "views/WalletManagement"






// import StrategyStats from "views/StrategyStats";
// import SearchOrders from "views/SearchOrders";
// import axios from "axios";
// import init from "Helper/WindowToken";
var dashRoutes;
// var role;
// if (init() === "success") {
//   axios.get("https://admin.akclasses.in/api/userdetail").then(resp => {
//     console.log(resp.data);
//     role = resp.data.role;
//   });
// }
let role = localStorage.getItem("joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ");

//faculty token
if (
  role ===
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZTA0ZTIyNTg4MDAzNDk0NTVlZDQ1YzgwNDRkMzEzZWRlNDc3NjEzNTMyYmYxM2Y1NzcwM2QiLCJpYXQiOjE1OTAwNzM2MDIsIm5iZiI6MTU5MDA3MzYwMiwiZXhwIjoxNjIxNjA5NjAyLCJzdWIiOiIyMCIsInNjb3BlcyI6W119.TC5AQTbkQJSpfKgUfSe_FQluwCdVSrsBHg0qxBh2mX1lGvJNqiRkD52TOuQ2HhfI_XZEw5a-BSwfgxqLZ8fhaj9OiJ3cvwS6C8-cDqwyGkSsRhjwRI5tinjaghLULIm06GtJcXedzlx_s9puY83kRHHPA_wAkOqA6UIWPwzSoGI9b0v2sTEpIjWURwarR-40f3V5VLLs5g953wfjGivr-KUrKYuLxKvE1riYJQbpWx--f1C0hPPc3bIT9PtDG_ruXNyEGUOqlMElwIqOZc89HmDWMvrIrwRzUNWejDjyITwUjH94a-EhoGm8egrmcSTivmuti1HTwEyG3qMBiFLC8TyQWuZrLomK0lsYVKuviUH2yvnrxTYdbODzPAlNHQPsyuuGnBdL4MmVWYUG71bh1XKUTUWBb2BBttXVBNme7ioRy0vem0NfccQQqEMbGMo4V1DOe4BFQfDy3SqHZCAyjAJ5j9Z813rfCqDmv3wmTRzvjbxoH_8VbJ7uPNGO_a8fwItS8CFgyp_l91ow-4E4EDiJE7Sk5RmayHl442K-veNrUyCx1K_So_CvIeAy3KI2QqfNam-WsDQwZKzwOHwwDow3bpD3rIWNaUTnGzyAorXwTiaOjKJ3i1LmTkDtWtsSmOlQHX8sGgDb34XnFkaaEOErVlU6fqwKlsrdFJGiNAU"
) {
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Orders List",
      icon: Place,
      state: "8",
      views: [
        {
          path: "/onlineorders",
          name: "Online Orders",
          component: FacultyOnlineOrders,
          layout: "/admin",
        },
        {
          path: "/offlineorders",
          name: "Offline Orders",
          component: FacultyOfflineOrders,
          layout: "/admin",
        },
        // {
        //   path: "/bookorders",
        //   name: "Book Orders",
        //   component: FacultyBookOrders,
        //   layout: "/admin",
        // },
      ],
    },
    {
      collapse: true,
      name: "Payment Reports",
      icon: Place,
      state: "8GTT",
      views: [
        {
          path: "/facultypaymentreports",
          name: "Reports",
          component: SingleFacultyReport,
          layout: "/admin",
        },
        {
          path: "/facultyCommissionReport",
          name: "Commission Report",
          component: FacultyCommissionReport,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/monitoringdashboard",
      name: "Monitering System",
      icon: Place,
      component: Monitoring_System_Dashboard,
      layout: "/admin",
    },
    {
      path: "/subjects",
      name: "Paper Master",
      icon: Place,
      component: Subjects,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Batch Live Meetings",
      icon: Place,
      state: "9",
      views: [
        {
          path: "/meetings",
          name: "Zoom Meetings",
          component: Meetings,
          layout: "/admin",
        },
        {
          path: "/schedules",
          name: "Schedule Meetings",
          component: Schedule,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Live Quiz",
      icon: Place,
      state: "9G",
      views: [
        {
          path: "/meetings",
          name: "livequiz",
          component: LiveQuiz,
          layout: "/admin",
        },
        {
          path: "/results",
          name: "Results",
          component: Results,
          layout: "/admin",
        },
        {
          path: "/topscores",
          name: "Top Scores",
          component: TopScores,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Strategies Section",
      icon: Place,
      state: "s1877",
      views: [
        {
          path: "/strategies",
          name: "Strategies",
          component: Strategies,
          layout: "/admin",
        },
        {
          path: "/activateStrategy",
          name: "Activate Strategy",
          component: Activate_Strategies,
          layout: "/admin",
        },
      ],
    },
    // {
    //   collapse: true,
    //   name: "Batch Live Meetings",
    //   icon: Place,
    //   state: "s1512",
    //   views: [
    //     {
    //       path: "/schedules",
    //       name: "Schedule Meetings",
    //       component: Schedule,
    //       layout: "/admin",
    //     },
    //   ],
    // },
    {
      path: "/changepassword",
      name: "Change Password",
      icon: Place,
      component: FacultyPassword,
      layout: "/admin",
    },
  ];
}
if (
  role ===
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJlNTk0N2Q5MzFmMzhlYjViOWMzNDAzNjE3YzBmYjJkMDEyOGM4YTc1OGM3MzA0MDZlZDEyNzMyZDNiMTgyYmYyOTkxOTU5MTQ1YWU1OTk2In0.eyJhdWQiOiIxNCIsImp0aSI6ImJlNTk0N2Q5MzFmMzhlYjViOWMzNDAzNjE3YzBmYjJkMDEyOGM4YTc1OGM3MzA0MDZlZDEyNzMyZDNiMTgyYmYyOTkxOTU5MTQ1YWU1OTk2IiwiaWF0IjoxNTk3NjU3MjMwLCJuYmYiOjE1OTc2NTcyMzAsImV4cCI6MTYyOTE5MzIzMCwic3ViIjoiMiIsInNjb3BlcyI6W119.kCfmogPJsgd07Fih84_BL7gE2grIgPxjiddyKD4QhzsWAZgMelogelaQqFis5wPsCPIUgUzXyn0lbWRCcM9n-LIu_3-EmHH296YCjfymceCHmICmC5fsBc_TzySUanLcTjnGJDeFQUIFtCZEAX0hEquWabM-iM0NeP5aJdv_Hhbjlz1M1zOSFRUwYlEYApu_DHqAW4nnoavztHeS_e9EATooFZRezzQevxAfnQj89NHamEPL0ikAFdU71x8GtZFR7JANz3-R78s-pk5st87Io5T7sjCkglxbtiuIU5ti6PQKb-S4ONnU5V1uAB_65YraRTlQ6rV8Z0g8XymUYLrV_89FRFNFRg67o7jqkp0dGHIiTUq9oWXKkadUIV5qi0eIm2zXTbTMVh9eXIeX8pjbeU9r8bjvV0_JlE_Qa9ewWlWDbMrYc6ApuK2mu3Ej0JGfzq80vLqT3Tro_WytAYrl16XR6EuehBKiJ6eCDdb67RgnAnxntt2Kd8Zmr4eUz7rZYiJFG0YGJtXrXurnbHbZ71YMMNsM940iS_SQHbuM1YWMWJNvINKWH5vc8ngyPGykweF9DKZXRFXfxwBOuW3N4U2Qx7ow16-AAUucT-cXXQJUNhRDkLsDKzrYwbRK6VfwEzVbzGmQRwpyK2ZdmzFIeHtF3_g4ogvt1nXft1FF5bA"
) {
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: AppDashboard,
      layout: "/admin",
    },
    {
      path: "/ads",
      name: "Ads",
      icon: Place,
      component: Ads,
      layout: "/admin",
    },
    {
      path: "/levels",
      name: "Levels",
      icon: Place,
      component: Levels,
      layout: "/admin",
    },
    {
      path: "/appsubjects",
      name: "Subjects",
      icon: Place,
      component: AppSubjects,
      layout: "/admin",
    },
    {
      path: "/chapters",
      name: "Chapters",
      icon: Place,
      component: Chapters,
      layout: "/admin",
    },
    {
      path: "/queries",
      name: "Queries",
      icon: Place,
      component: Queries,
      layout: "/admin",
    },
    {
      path: "/appquestions",
      name: "Questions",
      icon: Place,
      component: AppQuestions,
      layout: "/admin",
    },
    {
      path: "/uploadananswer",
      name: "Upload An Answer",
      icon: Place,
      component: UploadAnAnswer,
      layout: "/admin",
    },
    {
      path: "/downloadables",
      name: "Downloadables",
      icon: Place,
      component: Downloadables,
      layout: "/admin",
    },
    {
      path: "/facultydetails",
      name: "Faculty Details",
      icon: Place,
      component: AppFaculty,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Strategies Section",
      state: "s1877",
      icon: Place,
      views: [
        {
          path: "/topics",
          name: "Topics",
          component: Topics,
          layout: "/admin",
        },
        {
          path: "/strategies",
          name: "Strategies",
          component: Strategies,
          layout: "/admin",
        },
        {
          path: "/activateStrategy",
          name: "Activate Strategy",
          component: Activate_Strategies,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/homescreenvideo",
      name: "Home Screen Video",
      icon: Place,
      component: LiveStreaming,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: Place,
      component: Notifications,
      layout: "/admin",
    },
    {
      path: "/announcements",
      name: "Announcements",
      icon: Place,
      component: Announcements,
      layout: "/admin",
    },
  ];
}
if (
  role ===
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODJjNDM3OTRmYjlhY2EwZWM4MDYxNDJlZDE2ODUyNTUyZWVhYTQxNDllOGUxNDlhODM4MThiZGM0ZTVhODcxMjE3MWUwYjJmZmE5ZWRkNjMiLCJpYXQiOjE1OTQ0NTQwMDMsIm5iZiI6MTU5NDQ1NDAwMywiZXhwIjoxNjI1OTkwMDAzLCJzdWIiOiIzNzM4Iiwic2NvcGVzIjpbXX0.zDZkBVHl23e29QJGTE-5op0qP9MzxK_6-qmk_e_dXB1CoGGj5v6JRSp7SUOPoLXUPbZG96Y7noEeK4XAwb1oR94Qd2rUlE11z4qyNozCdUto9qe1gUeZA5DV_9qE5BbDqppbyK-E-sVIn3vELnyX_u4NjWsHnyhvBQLME479iN6ZzfGyXiBBvmOZQUhSwdiivXM2LXCq5SjEJnBlUESi29UBB5QyGgxEtI7pMCQWeDqD4yJk7MpTOcOPr-Dwum4UO69XI56x6k6K84m8xknjcPrRmN4T-h7W1a15AY3IN5nsPdQFS8YYgJ-h4iDRVdJ3eoIT-FNg32ObbGe7mN-Ikl5Afdg6hTYlS30qi769T88GpOD9DZAneH2rKN2xpff7xs31ytqJZ__8QtHgG5kQYHuTGUxzHamXfjZYTp0675R2todD6qYtuKRknW3AgXL7RqNdRhMBBuMbYdjIVQMGMQ3tt_68EaC2NwrYacrS5uP05ZtgRWOGjvd3nEOFhsQTHu9nMT5qeiVntlIzvSAAx83lcYMNKXCJgTVS2olqpaF40CafyUzCNuVvqvmO3_lud0d4isA0BoTdNy5rp2L49SL-RoPOSAGUH2cNP2k3TZ8rYZ77yvsmHcylb7qqFZTPy3fPZh_mcfzlvb3qVLaqEb20iqZUSzePToqeOyojopE"
) {
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Orders List",
      icon: Place,
      state: "8h",
      views: [
        {
          path: "/vendorofflineorders",
          name: "Vendor Added Offline Orders",
          component: VendorOfflineOrders,
          layout: "/admin",
        },
        {
          path: "/vendorordersoffline",
          name: "Offline Orders",
          component: VendorOrdersOffline,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Payment Reports",
      icon: Place,
      state: "8vh",
      views: [
        {
          path: "/vendorpaymentreports",
          name: "Reports",
          component: SingleVendorOrders,
          layout: "/admin",
        },
        {
          path: "/vendorCommissionReport",
          name: "Commission Report",
          component: VendorCommissionReport,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/changepassword",
      name: "Change Password",
      icon: Place,
      component: VendorPassword,
      layout: "/admin",
    },
  ];
}
if (
  role ===
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOTg1MjBlYWNkMGU0YTU4NTg3NWI2YWE4MjhlYjQxYWViYjc0MDc0ZDc4ZDk0YTEwMGQ0MzU2OTcwNGIzZDQ0ZjNlZWFjYWJhYzM1MWNjYTIiLCJpYXQiOjE1OTEyNjkzMTMsIm5iZiI6MTU5MTI2OTMxMywiZXhwIjoxNjIyODA1MzEzLCJzdWIiOiIzNzQ4Iiwic2NvcGVzIjpbXX0.T1ORgzbVOG3GTHd5PqM0RWufJrzxk-0DcN397GlhCENGbH7L5juugqLMW2UgEaKN-JpBE06Nma1K_vW6pYXBCxz382z_SlQp_wJtSFoP6rLTJuicd5FINdB0eT_WKRqly8IOjnvc9-67dUfeHOY4KuNry8UaeEV8kG5k2YCkyLkgz1hXHwV4kcnsEloQcxh9knVzRxeuNHAizoD83XGQJvbG7a5its9pbeFYsz-Hgj8zMj19bHsK2dDz2s9glG_9ws_FXCCFPmsJSFL2zWxOTwKrnPT0jhe2G0DWxccI1YySQn632Q7xocYB8OHBQkWslISFpjwohAXoQD8EkazKoWioNOzwAfpKMARqUShB5yWKC7J9hnm5Uz-t46LfCN_zR9l7uKoSem3XJRlT-uI1PxVW3QZMC41NcIahAZuTBenhJuFZG4YIZrWOt_gii2DXnl1KoN6jSZ5myCxCVoAXNJ4N0s909fHfbO0wK46myjB-fjOiG2QLZOjoovQXpULaNimN8FAsPixu8ZyGZt0CaORXPfOIPLunUMDXTm7yNaj_MlvELxG-J_c-L0B715GKRSmaGR7-NSP6xvhmrJW2ooS3eL9Wgj3lzd6J7tNXC9uZMpakiYjSniCMt_vJuf_qbJSv2mCba1lYmWLFcn8unJkTwGtVAo29JLfHDkB6GNY"
) {
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Orders List",
      icon: Place,
      state: "a",
      views: [
        {
          path: "/onlineorders",
          name: "Online Orders",
          component: OnlineOrders,
          layout: "/admin",
        },
        {
          path: "/offlineorders",
          name: "Offline Orders",
          component: OfflineOrders,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Coupon Codes",
      state: "b",
      icon: Place,
      views: [
        {
          path: "/cashbackamount",
          name: "Cashback Amount",
          component: CashbackAmount,
          layout: "/admin",
        },
        {
          path: "/referencecodes",
          name: "Reference Codes",
          component: ReferenceCodes,
          layout: "/admin",
        },
        {
          path: "/couponcodes",
          name: "Coupon Codes",
          component: CouponCodes,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/productkeys",
      icon: Place,
      name: "Product Keys",
      component: ProductKeys,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Test Series Mgmt",
      state: "l",
      icon: Place,
      views: [
        {
          path: "/testcategories",
          name: "Categories",
          component: TestCategories,
          layout: "/admin",
        },
        {
          path: "/testseries",
          name: "Test Series Master",
          component: TestSeries,
          layout: "/admin",
        },
        {
          path: "/subjects",
          name: "Paper Master",
          component: Subjects,
          layout: "/admin",
        },
        {
          path: "/questions",
          name: "Questions Master",
          component: Questions,
          layout: "/admin",
        },
        {
          path: "/testinstructions",
          name: "Test Instructions",
          component: TestInstructions,
          layout: "/admin",
        },
        {
          path: "/testlevels",
          name: "Levels",
          component: TestLevels,
          layout: "/admin",
        },
        {
          path: "/teststreams",
          name: "Streams",
          component: TestStreams,
          layout: "/admin",
        },
        {
          path: "/testsubjects",
          name: "Subject Master",
          component: TestSubjects,
          layout: "/admin",
        },
        {
          path: "/testgiven",
          icon: Place,
          name: "Test Series Given",
          component: TestGiven,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "uvr",
      name: "User Data",
      icon: Place,
      views: [
        {
          path: "/freeusers",
          name: "Free Users",
          component: FreeUsers,
          layout: "/admin",
        },
        {
          path: "/bookusers",
          name: "Book Users",
          component: BookUsers,
          layout: "/admin",
        },
        {
          path: "/premiumusers",
          name: "Smart Classroom",
          component: PremiumUsers,
          layout: "/admin",
        },
        {
          path: "/facetofaceusers",
          name: "FaceToFace Users",
          component: FaceToFaceUsers,
          layout: "/admin",
        },
        {
          path: "/oldusers",
          name: "Old Users",
          component: OldUsers,
          layout: "/admin",
        },
        {
          path: "/otps",
          name: "OTPs",
          component: Otps,
          layout: "/admin",
        },
      ],
    },
  ];
}
if (
  role ===
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiM2JkYTQzZDA5ZjE0OTk4MDY5OGYwMjkzODcwOTc5NjMyMDI5OTI2NjQwODgxN2I3YmQ5NzNhYjdmMmY1MzNiOTM2ODY5MDI1ZWJhYmJjNGMiLCJpYXQiOjE1OTA1OTcxMDAsIm5iZiI6MTU5MDU5NzEwMCwiZXhwIjoxNjIyMTMzMDk5LCJzdWIiOiIyNzgyIiwic2NvcGVzIjpbXX0.JijU-lbj0UaL6pKKaD24udVQtKfMJ15IwW7tc-lhYR77kZSBO2E_UiGpzg61e6L2Ft7KKhpe4-P1ARs1RXRGAKggp7DDmqio0gQ4gMRt4lXelz_fxjhZos1S2QdrbV5oqjyHNFOrf6js3TRX1P3FWxUHi0bEAiM11KE16KE4kcOOK8E2awP6NcLu__CfjpJnh4maIcqDrhOsKAf15YN_AgAQlJxRs4GjJc_m93IOTEhhoF5yEbZNDkvYKTnPn0g9lTvQx_LJk1I5AnPz_T0EAemDOyOyf832Y8orlfoKBeQySYWctAPV0lWDZLdbJ2eefUoV8k5k97SbN4ZYIx2B6Ssz23MNxFHODRPRDp9Q1Chk3LoATju5a1A-vwCQXPgR2zx_UyAJXWeHd5Ln4db31ZtQceWCjnQwH-8bwFpvfZ2ryLYS27Hrxsr0j4GIw-ki5cXNsclOGRSIvd_3Fuje0UEj_1Msx_Q4DtHwqAnprOo7giLxC_rrRgoQBJkF9jHl5sLvjpfpQRleVi8cka0PdILaLe1nxgQV4-pykT0rNMNcg0bgE1nzEcJCe_Sp7qUoWxAg94TVZ3TkVDOkM-b__yc-xzgdR_H4ZbAsazUe4d22N_8h2Uj8i-bxif-sEMopd700jNGB-Qv4eqpvaF9aBHOCozIyI5DuXU5L_H8svEg"
) {
  dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Orders List",
      icon: Place,
      state: "a",
      views: [
        //     {
        //       collapse: true,
        //       name: "Online Orders",
        //       state: "a189",
        //       views: [
        {
          path: "/onlineorders",
          name: "Online Orders",
          component: OnlineOrders,
          layout: "/admin",
        },
        //     {
        //       path: "/onlinependingorders",
        //       name: "Online Pending Orders",
        //       component: OnlinePendingOrders,
        //       layout: "/admin"
        //     }
        //   ]
        // },
        {
          path: "/offlineorders",
          name: "Offline Orders",
          component: OfflineOrders,
          layout: "/admin",
        },
        {
          path: "/bookonlineorders",
          name: "Book Online Orders",
          component: BookOnlineOrders,
          layout: "/admin",
        },
        {
          path: "/bookofflineorders",
          name: "Book Offline Orders",
          component: BookOfflineOrders,
          layout: "/admin",
        },
        {
          path: "/vsmartorders",
          name: "Vsmart Orders",
          component: VsmartOrders,
          layout: "/admin",
        },
        {
          path: "/facetofaceorders",
          name: "Face To Face Orders",
          component: FaceToFaceOrder,
          layout: "/admin",
        },
        {
          path: "/pendingorders",
          name: "Center Orders",
          component: VendorDashboardOrders,
          layout: "/admin",
        },
        // {
        //   path: "/backuporders",
        //   name: "Backup Orders",
        //   component: BackupOrders,
        //   layout: "/admin"
        // }
        // {
        //   path: "/searchorders",
        //   name: "Search Orders",
        //   component: SearchOrders,
        //   layout: "/admin"
        // }
      ],
    },
    {
      collapse: true,
      name: "Course(s) Mgmt",
      state: "c",
      icon: Place,
      views: [
        {
          path: "/categories",
          name: "Categories",
          component: Categories,
          layout: "/admin",
        },
        {
          path: "/courselevels",
          name: "Levels",
          component: CourseLevels,
          layout: "/admin",
        },
        {
          path: "/coursestreams",
          name: "Streams",
          component: CourseStreams,
          layout: "/admin",
        },
        {
          path: "/amenities",
          // icon: Place,
          name: "Amenities",
          component: Amenities,
          layout: "/admin",
        },
        {
          path: "/batches",
          // icon: Place,
          name: "Batches",
          component: Batches,
          layout: "/admin",
        },
        {
          path: "/batchwings",
          name: "Batch Wings",
          component: BatchWings,
          layout: "/admin",
        },
        {
          path: "/coursesubjects",
          name: "Subjects",
          component: CourseSubjects,
          layout: "/admin",
        },
        {
          path: "/subjectsFilter",
          name: "Subject Filter",
          component: SubjectFilter,
          layout: "/admin",
        },
        {
          path: "/coursesmaster",
          name: "Courses Master",
          component: CoursesMaster,
          layout: "/admin",
        },
        {
          path: "/deviceInfo",
          name: "Device Information",
          component: DeviceInfo,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Face To Face Mgmt",
      state: "t",
      icon: Place,
      // views:[
      //   {
      //     path: "/facetofacecategories",
      //     name: "Face Categories",
      //     component: FaceToFaceCategory,
      //     layout: "/admin"
      //   },
      //   {
      //     path: "/facetoface",
      //     name: "Face To Face",
      //     component: FaceToFace,
      //     layout: "/admin",

      //   }
      // ]
      views: [
        {
          path: "/facetoface",
          name: "Face To Face",
          component: FaceToFace,
          layout: "/admin",
        },
        {
          path: "/facetofacecategories",
          name: "Categories",
          component: FaceToFaceCategory,
          layout: "/admin",
        },
        {
          path: "/facetofacelevels",
          name: "Levels",
          component: FaceToFaceLevel,
          layout: "/admin",
        },
        {
          path: "/facetofacestreams",
          name: "Streams",
          component: FaceToFaceStream,
          layout: "/admin",
        },
        {
          path: "/facetofacesubjects",
          name: "Subjects",
          component: FaceToFaceSubject,
          layout: "/admin",
        },
        {
          path: "/facetofaceOfflineOrder",
          name: "Face To Face Offline Order",
          component: FaceToFaceOfflineOrder,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Coupon Codes",
      state: "b",
      icon: Place,
      views: [
        {
          path: "/cashbackamount",
          name: "Cachback Amount",
          component: CashbackAmount,
          layout: "/admin",
        },
        {
          path: "/referencecodes",
          name: "Reference Codes",
          component: ReferenceCodes,
          layout: "/admin",
        },
        {
          path: "/couponcodes",
          name: "Coupon Codes",
          component: CouponCodes,
          layout: "/admin",
        },
      ],
    },
    // {
    //   path: "/productkeys",
    //   icon: Place,
    //   name: "Product Keys",
    //   component: ProductKeys,
    //   layout: "/admin"
    // },
    {
      path: "/facetofacebatch",
      icon: Place,
      name: "Attendance Management",
      component: Face_To_Face_Batch,
      layout: "/admin",
    },

    {
      path: "/mentorsessions",
      icon: Place,
      name: "Mentor Sessions",
      component: MentorSessions,
      layout: "/admin",
    },
    {
      path: "/customtestseries",
      icon: Place,
      name: "Custom Test Series",
      component: CustomTestSeries,
      layout: "/admin",
    },
    {
      path: "/extensionmaster",
      icon: Place,
      name: "Extension Master",
      component: ExtensionMaster,
      layout: "/admin",
    },
    // {
    //   path: "/extensionextend",
    //   icon: Place,
    //   name: "Extend Product Key",
    //   component: ExtendProductkey,
    //   layout: "/admin"
    // },
    {
      collapse: true,
      name: "Test Series Mgmt",
      state: "l",
      icon: Place,
      views: [
        {
          path: "/testcategories",
          name: "Categories",
          component: TestCategories,
          layout: "/admin",
        },
        {
          path: "/testseries",
          name: "Test Series Master",
          component: TestSeries,
          layout: "/admin",
        },
        {
          path: "/subjects",
          name: "Paper Master",
          component: Subjects,
          layout: "/admin",
        },
        {
          path: "/questions",
          name: "Questions Master",
          component: Questions,
          layout: "/admin",
        },
        {
          path: "/testinstructions",
          name: "Test Instructions",
          component: TestInstructions,
          layout: "/admin",
        },
        {
          path: "/testlevels",
          name: "Levels",
          component: TestLevels,
          layout: "/admin",
        },
        {
          path: "/teststreams",
          name: "Streams",
          component: TestStreams,
          layout: "/admin",
        },
        {
          path: "/testsubjects",
          name: "Subject Master",
          component: TestSubjects,
          layout: "/admin",
        },
        {
          path: "/testgiven",
          // icon: Place,
          name: "Test Series Given",
          component: TestGiven,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "y",
      name: "Books Mgmt",
      icon: Place,
      views: [
        {
          path: "/bookcategories",
          name: "Categories",
          component: BookCategories,
          layout: "/admin",
        },
        {
          path: "/booklevels",
          name: "Levels",
          component: BookLevel,
          layout: "/admin",
        },
        {
          path: "/bookstreams",
          name: "Streams",
          component: BookStream,
          layout: "/admin",
        },
        {
          path: "/booksmaster",
          name: "Books Master",
          component: BooksMaster,
          layout: "/admin",
        },
        {
          path: "/booksubjects",
          name: "Subjects",
          component: BookSubjects,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "x",
      name: "Faculty Management",
      icon: Place,
      views: [
        {
          path: "/faculty",
          name: "Faculty",
          component: Faculty,
          layout: "/admin",
        },
        {
          path: "/report",
          name: "Faculty Payment Due Report",
          component: FacultyReport,
          layout: "/admin",
        },
        {
          path: "/addorder",
          name: "Faculty Order",
          component: AddFacultyOrder,
          layout: "/admin",
        },
        {
          path: "/facultycommission",
          name: "Faculty Commission",
          component: FacultyCommission,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "xyz",
      name: "Notes & DPP",
      icon: Place,
      views: [
        {
          path: "/documentmanager",
          name: "Downloadables",
          component: DocumentManager,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/logistics",
      icon: Place,
      name: "Logistics",
      component: Logistics,
      layout: "/admin",
    },
    {
      collapse: true,
      state: "k",
      name: "Vendor Master",
      icon: Place,
      views: [
        {
          path: "/vendors",
          name: "Vendors",
          component: Vendors,
          layout: "/admin",
        },
        {
          path: "/vendororders",
          name: "Vendor Orders",
          component: VendorOrders,
          layout: "/admin",
        },
        {
          path: "/vendorcommission",
          name: "Vendor Commission",
          component: VendorCommission,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "j",
      name: "Other Mgmt",
      icon: Place,
      views: [
        {
          path: "/mediatypes",
          name: "Media Type",
          component: Mediatype,
          layout: "/admin",
        },
        {
          path: "/ostypes",
          name: "OS Type",
          component: OStype,
          layout: "/admin",
        },
        {
          path: "/viewsandvalidity",
          name: "Views & Validity",
          component: ViewsValidity,
          layout: "/admin",
        },
      ],
    },

    {
      collapse: true,
      state: "u",
      name: "User Data",
      icon: Place,
      views: [
        {
          path: "/freeusers",
          name: "Free Users",
          component: FreeUsers,
          layout: "/admin",
        },
        {
          path: "/bookusers",
          name: "Book Users",
          component: BookUsers,
          layout: "/admin",
        },
        {
          path: "/premiumusers",
          name: "Smart Classroom",
          component: PremiumUsers,
          layout: "/admin",
        },
        {
          path: "/facetofaceusers",
          name: "FaceToFace Users",
          component: FaceToFaceUsers,
          layout: "/admin",
        },
        {
          path: "/oldusers",
          name: "Old Users",
          component: OldUsers,
          layout: "/admin",
        },
        {
          path: "/otps",
          name: "OTPs",
          component: Otps,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "v",
      name: "Monitoring System",
      icon: Place,
      views: [
        {
          path: "/monitoringdashboard",
          name: "Monitoring System Dashboard",
          component: Monitoring_System_Dashboard,
          layout: "/admin",
        },
        {
          path: "/monitoringSystem",
          name: "Monitoring System",
          // icon: Place,
          component: Monitoring_System,
          layout: "/admin",
        },
      ],
    },

    {
      name: "Common Menus",
      icon: WidgetsIcon,
    },
    {
      collapse: true,
      name: "Manage Modules",
      state: "f",
      icon: Place,
      views: [
        {
          path: "/batchtimings",
          name: "Batch Timings",
          component: BatchTimings,
          layout: "/admin",
        },
        {
          path: "/downloadsmaster",
          name: "Downloads Master",
          component: DownloadMaster,
          layout: "/admin",
        },
        {
          path: "/faqs",
          name: "FAQs",
          component: FAQs,
          layout: "/admin",
        },
        {
          path: "/franchiseecenters",
          name: "Franchisee Centers",
          component: FranchiseeCenters,
          layout: "/admin",
        },
        {
          path: "/socialmedialinks",
          name: "Social Media Links",
          component: SocialMediaLinks,
          layout: "/admin",
        },
        {
          path: "/testimonialmaster",
          name: "Testimonial Master",
          component: TestimonialMaster,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "e",
      name: "Revenue Mgmt",
      icon: Place,
      views: [
        // {
        //   collapse: true,
        //   state: "1",
        //   name: "Reports",
        //   views: [
        {
          path: "/expensesreport",
          name: "Expenses Report",
          component: ExpensesReport,
          layout: "/admin",
        },
        {
          path: "/incomereport",
          name: "Income Report",
          component: IncomeReport,
          layout: "/admin",
        },
        {
          path: "/commissionreport",
          name: "Commission Report",
          component: CommissionReport,
          layout: "/admin",
        },
        {
          path: "/summaryreport",
          name: "Summary Report",
          component: SummaryReport,
          layout: "/admin",
        },
        {
          path: "/quatrareport",
          name: "Quatra Report",
          component: QuatraReport,
          layout: "/admin",
        },
        // //   ],
        // // },
        // // {
        //   collapse: true,
        //   state: "3",
        //   name: "Entries",
        //   // icon: Place,
        //   views: [
        {
          path: "/expensesentry",
          name: "Expenses Entry",
          component: ExpensesEntry,
          layout: "/admin",
        },
        {
          path: "/incomeentry",
          name: "Income Entry",
          component: IncomeEntry,
          layout: "/admin",
        },
        {
          path: "/incometype",
          name: "Income Type",
          component: IncomeType,
          layout: "/admin",
        },
        {
          path: "/quatraentries",
          name: "Quatra Entries",
          component: QuatraEntries,
          layout: "/admin",
        },
        //   ],
        // },
        // {
        //   collapse: true,
        //   state: "2",
        //   name: "Categories",
        //   // icon: Place,
        //   views: [
        {
          path: "/incexpcategories",
          name: "Inc/Exp Modes",
          component: IncExpCat,
          layout: "/admin",
        },
        {
          path: "/expensesmodes",
          name: "Inc/Exp Categories",
          component: ExpenseModes,
          layout: "/admin",
        },
        //   ],
        // },
      ],
    },
    {
      collapse: true,
      state: "g",
      name: "Manage Contents",
      icon: Place,
      views: [
        {
          path: "/aboutus",
          name: "About Us",
          component: AboutUs,
          layout: "/admin",
        },
        {
          path: "/contactus",
          name: "Contact Us",
          component: ContactUs,
          layout: "/admin",
        },
        {
          path: "/footertechnicalsupport",
          name: "Footer Technical Support",
          component: FooterTechnicalSupport,
          layout: "/admin",
        },
        {
          path: "/footeraboutus",
          name: "Footer About Us",
          component: FooterAboutUs,
          layout: "/admin",
        },
        {
          path: "/mailtemplates",
          name: "Mail Templates",
          component: MailTemplates,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      state: "d",
      name: "Home Management",
      icon: Place,
      views: [
        {
          path: "/homesliderimages",
          name: "Home Slider Images",
          component: HomeSliderImages,
          layout: "/admin",
        },
        {
          path: "/mobilesliderimages",
          name: "Mobile Slider Images",
          component: MobileSliderImages,
          layout: "/admin",
        },
        {
          path: "/textbelowslider",
          name: "Tag Line",
          component: TextBelowSlider,
          layout: "/admin",
        },
        {
          path: "/servicestext",
          name: "Services Text",
          component: ServicesText,
          layout: "/admin",
        },
        {
          path: "/achievegoal",
          name: "Achieve Goal Section",
          component: AchieveGoalSection,
          layout: "/admin",
        },
        {
          path: "/youtubevideo",
          name: "Youtube Video",
          component: YoutubeVideo,
          layout: "/admin",
        },
        {
          path: "/trackorder",
          name: "Track Order Link",
          component: TrackOrder,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Batch Live Meetings",
      state: "5",
      icon: Place,
      views: [
        {
          path: "/meetings",
          name: "Zoom Meetings",
          component: Meetings,
          layout: "/admin",
        },
        {
          path: "/schedules",
          name: "Schedule Meetings",
          component: Schedule,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Activation Process",
      state: "6",
      icon: Place,
      views: [
        {
          path: "/activationprocess",
          name: "Activation Process",
          component: ActivationProcess,
          layout: "/admin",
        },
        {
          path: "/lectureslink",
          name: "Lectures link",
          component: LecturesLink,
          layout: "/admin",
        },
      ],
    },
    // {
    //   path: "/activationprocess",
    //   icon: Place,
    //   name: "Activation Process",
    //   component: ActivationProcess,
    //   layout: "/admin"
    // },
    {
      collapse: true,
      name: "Budget Analysis",
      state: "s1",
      icon: Place,
      views: [
        {
          path: "/budgetmodes",
          name: "Budget Modes",
          component: BudgetMode,
          layout: "/admin",
        },
        {
          path: "/budgetreports",
          name: "Budget Reports",
          component: BudgetReport,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Marketing Panel",
      state: "s177",
      icon: Place,
      views: [
        {
          path: "/akstudents",
          name: "AK Students",
          component: MarketingPanel,
          layout: "/admin",
        },
        {
          path: "/importusers",
          name: "Import Users",
          component: ImportUsers,
          layout: "/admin",
        },
        {
          path: "/messages",
          name: "Messages",
          component: Messages,
          layout: "/admin",
        },
        {
          path: "/templates",
          name: "Message Templates",
          component: Templates,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Live Quiz",
      state: "s17",
      icon: Place,
      views: [
        {
          path: "/livequiz",
          name: "Live Quiz",
          component: LiveQuiz,
          layout: "/admin",
        },
        // {
        //   path: "/seriesquestions",
        //   name: "Questions",
        //   component: SeriesQuestion,
        //   layout: "/admin"
        // },
        // {
        //   path: "/results",
        //   name: "Results",
        //   component: Results,
        //   layout: "/admin",
        // },
        {
          path: "/topscores",
          name: "Top Scores",
          component: TopScores,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Question Bank",
      state: "s18",
      icon: Place,
      views: [
        {
          path: "/questionbanklevel",
          name: "Level",
          component: QuestionBankLevel,
          layout: "/admin",
        },
        {
          path: "/questionbanksubject",
          name: "Subject",
          component: QuestionBankSubject,
          layout: "/admin",
        },
        {
          path: "/questionbankchapter",
          name: "Chapter",
          component: QuestionBankChapter,
          layout: "/admin",
        },
        // {
        //   path: "/uploadQuestionDocx",
        //   name: "Upload Question Docs",
        //   component: Upload_Question_Docs,
        //   layout: "/admin",
        // },
        {
          path: "/questionbank",
          name: "Question Bank",
          component: QuestionBank,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Scanner",
      state: "s19",
      icon: Place,
      views: [
        {
          path: "/scannerlevel",
          name: "Level",
          component: ScannerLevel,
          layout: "/admin",
        },
        {
          path: "/scannersubject",
          name: "Subject",
          component: ScannerSubject,
          layout: "/admin",
        },
        {
          path: "/scannerchapter",
          name: "Chapter",
          component: ScannerChapter,
          layout: "/admin",
        },
        {
          path: "/scannersource",
          name: "Source",
          component: ScannerSource,
          layout: "/admin",
         
        },
        {
          path: "/scannerquestion",
          name: "Scanner Question",
          component: ScannerQuestion,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/source",
      icon: Place,
      name: "Source",
      component: Source,
      layout: "/admin",
    },
    {
      path: "/attempts",
      icon: Place,
      name: "Attempts",
      component: Attempts,
      layout: "/admin",
    },
    {
      path: "/whatStudentsSay",
      icon: Place,
      name: "What Students Say",
      component: WhatStudentsSay,
      layout: "/admin",
    },
    {
      path: "/manualNotification",
      icon: Place,
      name: "Mannual Notification",
      component: Manual_Notification,
      layout: "/admin",
    },
  

    {
      collapse: true,
      name: "Admin Settings",
      state: "h",
      icon: Place,
      views: [
        {
          path: "/administratorprofile",
          name: "Administrator Profile",
          component: AdministratorProfile,
          layout: "/admin",
        },
        {
          path: "/changepassword",
          name: "Change Password",
          component: ChangePassword,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Revison Tracker",
      state: "s20",
      icon: Place,
      views: [
        {
          path:"/content",
          name:"Tracker Content",
          component:Content,
          layout:"/admin",
        },
        // {
        //   path: "/mainmaster",
        //   name: "Revison Subject",
        //   component: MainMaster,
        //   layout: "/admin",
        // },

        // {
        //   path:"/revichapter",
        //   name:"Revison Chapter",
        //   component:Rchapter,
        //   layout:"/admin",

        // },

        // {
        //   path: "/subchapter",
        //   name: "Revison SubChapter",
        //   component: SubChapter,
        //   layout: "/admin",
        // },

      {
        path:"/tracker",
        name:"Tracker",
        component:Tracker,
        layout:"/admin",
      },
      // {
      //   path:"/finalmainmaster",
      //   name:"Main Master",
      //   component:FinalMaster,
      //   layout:"/admin",

      // },
      // {
      //   path:"/Trackerdrops",
      //   name:"Track",
      //   component:Trackerdrops,
      //   layout:"/admin",

      // },
      ],
    },

    {
      path:"/youtubeManager",
      name: "Youtube Manager",
      icon: Place,
      component:YoutubeTry,
      layout: "/admin",
    },
    {
      path: "/technicalsupport",
      name: "Support",
      icon: Place,
      component: TechnicalSupport,
      layout: "/admin",
    },
    {
      path: "/version_no",
      name: "Version Number",
      icon: Place,
      component: VersionNumber,
      layout: "/admin",
    },

    {
      path: "/walletmanage",
      name: "Wallet Management",
      icon: Place,
      component: WalletManagement,
      layout: "/admin",
    },

    // {
    //   collapse: true,
    //   name: "Testing",
    //   state: "h1",
    //   icon: Place,
    //   views: [
    //     {
    //       path: "/test1",
    //       name: "Testing 1",
    //       component: Test1,
    //       layout: "/admin",
    //     },
    //     {
    //       path: "/test2",
    //       name: "Testing 2",
    //       component: Test2,
    //       layout: "/admin",
    //     },
    //   ],
    // },

   
   
  ];
}

export default dashRoutes;

