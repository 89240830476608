import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Loader from "components/Loader";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import FormControl from "@material-ui/core/FormControl";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from "react-router-dom";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class OfflineOrders extends Component {
  state = {
    notifyStatus: false,
    mail: false,
    secondaryCategories: [],
    status: "",
    emailMess: "",
    pKey: "",
    frmDate: "",
    strMessage: "",
    toDate: "",
    osType: "",
    mediaType: "",
    orders: ["Order Placed", "Shipped"],
    extensions: [],
    referenceCodes: [],
    viewValidity: "",
    attempt: "",
    ordType: "Order Placed",
    trackingNo: "",
    city: "",
    state: "",
    pro: 0,
    email: "",
    password: "",
    contact: "",
    message: "",
    per_page: "10",
    active: 1,
    act: 0,
    id: 0,
    mobile: 0,
    logic: "",
    options: [],
    notification: false,
    delModal: false,
    delStrategyModal: false,
    stratId: "",
    mess: false,
    redirect: false,
    repButton: false,
    logistics: [],
    visible: false,
    loading: false,
    delLoading: false,
    extend: false,
    ord: true,
    empty: false,
    statLoading: false,
    userSearch: false,
    proDetail: false,
    notifyDelete: false,
    logDetail: false,
    showStrategy: false,
    show: false,
    mailS: false,
    mailFail: false,
    comment: false,
    print: false,
    printView: true,
    notifyNote: false,
    product: "",
    address: "",
    searchOrderId: "",
    pincode: "",
    notes: "",
    courierCom: "",
    tracLink: "",
    delStatus: "",
    language: "",
    price: "",
    rowId: "",
    title: "",
    category: "",
    student: "",
    payStatus: "",
    shippingDate: "",
    type: "Send Written Message",
    grandTotal: 0,
    productId: 0,
    warn: false,
    pkey: "",
    user: {},
    viewMessage: true,
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    faculties: [],
    allStrategies: [],
    strategies: [],
    previousStrategies: [],
    remainingStrategies: [],
    filteredStrategies: [],
    lastPage: 0,
    total: 0,
    from: 0,
    to: 0,
    lastPageView: false,
    firstPageView: false,
    view: false,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    products: [],
    types: [],
    userData: {},
    addedStrategies: [],
    notaddedStrategies: [],
    notAddedStrats: [],
    ftof_key: "",
    search_by: "",
    ftofbatchId: "",
    fbatchName: "",
    selectedBatchId: "",
    showFbatchName: false,
    ftofbatches: [],
    ftofbatch: false,
    userId: "",
    productId: "",
    orderId: "",
    testModal: false,
    notifyTest: false,
    showPermissionModal: false,
    testSeries: [],
    showTestSeries: false,
    allotedTestSeries: [],
    notallotedTestSeries: [],
    test: [],
    test_seriesId: [],
    showcourseAllotMessage: false,
    courseAllotMessage: "",
    extensions: [],
    extension: false,
    selectedExtension: "",
    notifyExt: false,
    expiryDate: "",
    extPrice: "",
    courses: [],
    extOrders: false,
    extensionOrders: [],
    notExpiredCourse: false,
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
      logDetail: false,
      mail: false,
      proDetail: false,
      import: false,
      showStrategy: false,
      delModal: false,
      delStrategyModal: false,
      print: false,
      addShip: false,
      mess: false,
      comment: false,
      notaddedStrategies: [],
      ftofbatch: false,
    });
  }
  componentDidMount() {
    let category = localStorage.getItem("category");
    axios.get("https://admin.akclasses.in/api/faculty").then((resp) => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/course_with_variant")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          products: resp.data,
        });
      });
    axios
      .get(
        "https://admin.akclasses.in/api/courses?type=COURSES&per_page=100&is_featured=&attempt_id=&category_id=&level_id=&stream_id=&subject_id=&lang="
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          courses: resp.data.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/get_type").then((resp) => {
      //   console.log(resp.data);
      this.setState({
        types: resp.data,
      });
    });
    this.setState({ category }, () => {
      axios
        .get(
          // "https://admin.akclasses.in/api/offlineOrders?order_type=" +
          Constants.getUrls.offlineOrders +
            "?order_type=" +
            this.state.category +
            "&status=" +
            this.state.ordType +
            "&per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          console.log(resp.data.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    });
    axios
      .get("https://admin.akclasses.in/api/extend_index_without_paginate")
      .then((resp) => {
        this.setState({ extensions: resp.data });
      });
    axios.get("https://admin.akclasses.in/api/logistic").then((resp) => {
      //console.log(resp.data.data);
      this.setState({
        logistics: resp.data.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/couponCodes?type=REFERENCE_CODE")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          referenceCodes: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/strategy").then((resp) => {
      // console.log(resp.data);
      this.setState({
        allStrategies: resp.data,
      });
    });
  }

  handleTestSeries = (sec) => {
    console.log(sec);
    const payload = {
      user_id: sec.user.ID,
      order_no: sec.ORDER_ID,
      course_id: sec.course.ID,
      order_type: "Online",
    };
    axios
      .post(Constants.postUrls.getAllotNotAllotTest, payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState(
            {
              testModal: true,
              showTestSeries: false,
              allotedTestSeries: resp.data.allotedTitles,
              notallotedTestSeries: resp.data.notAllotedTitles,
              userId: sec.user.ID,
              productId: sec.course.ID,
              orderId: sec.ORDER_ID,
              testSeries: [],
            },
            () => {
              this.state.notallotedTestSeries.map((val) => {
                console.log(val);
                this.state.testSeries.push({
                  label: val?.title,
                  value: val?.id,
                });
                this.setState(
                  {
                    testSeries: this.state.testSeries,
                  },
                  () => {
                    console.log(this.state.testSeries);
                  }
                );
              });
            }
          );
        }
        this.setState({
          // testModal: true,
          showcourseAllotMessage: true,
          courseAllotMessage: resp.data.message,
        });
      });
    this.openModal();
  };

  saveTestSeries = () => {
    console.log(this.state.productId, this.state.orderId, this.state.userId);
    const payload = {
      user_id: this.state.userId,
      course_id: this.state.productId,
      order_no: this.state.orderId,
      order_type: "Offline",
    };
    axios.post(Constants.postUrls.allotTestSeries, payload).then((resp) => {
      console.log(resp);
      if (resp.data.status == "success") {
        this.setState(
          {
            notifyTest: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                visible: false,
                notifyTest: false,
              });
            }, 2000);
          }
        );
      }
    });
  };

  comparer = (otherArray) => {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.id == current.id;
        }).length == 0
      );
    };
  };
  importUser = () => {
    this.setState({ loading: true });
    let payLoad = {
      type: this.state.type,
      name: this.state.student,
      email: this.state.email,
      mobile: this.state.mobile,
      key: this.state.pKey,
      // password: this.state.password,
      date: this.state.orderDate,
    };
    axios.post("https://admin.akclasses.in/api/user_sms", payLoad).then(() => {
      // console.log(resp);
      this.setState({
        notifyNote: true,
        loading: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };
  sendEmail = () => {
    this.setState({ loading: true });
    let strategies = [];
    this.state.notAddedStrats.forEach((v) => {
      strategies.push(v.value);
    });
    console.log(strategies);
    axios
      // .post("https://admin.akclasses.in/api/addUser_strategy", {
      .post(Constants.postUrls.addUser_strategy, {
        order_id: this.state.rowId,
        offline: "",
        strIds: strategies,
      })
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "failed") {
          this.setState({
            loading: false,
            mailFail: true,
            strMessage: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              mailFail: false,
              strMessage: "",
            });
          }, 4000);
        } else {
          this.setState({
            loading: false,
            mailS: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      });
  };
  findReferenceCode = (id) => {
    let obj = this.state.referenceCodes.find((v) => v.REF_ORDER_NO === id);
    return obj === undefined ? "NA" : obj.CODE;
  };
  findFaculty = (data) => {
    let obj = this.state.faculties.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  findCourse = (id) => {
    let obj = this.state.products.find((v) => v.course.ID === id);
    // console.log(obj);
    if (obj !== undefined) {
      return obj.course.faculty.length > 1
        ? "AK Team"
        : this.findFaculty(obj.course.faculty);
    }
  };
  applyKey = () => {
    axios
      .post(
        "https://admin.akclasses.in/api/extend_validity?product_key=" +
          this.state.pkey +
          "&product_id=" +
          this.state.pro
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.status === "success") {
          this.setState({
            redirect: true,
            loading: false,
            user: resp.data.user,
            pKey: resp.data.extended_product_key,
            productId: resp.data.product.extend_course_id,
            title: resp.data.product.extend_course.TITLE,
            grandTotal: resp.data.product.extend_course_price,
          });
        } else {
          this.setState({ warn: true, message: resp.data.message });
          setTimeout(() => {
            this.setState({ warn: false });
          }, 2000);
        }
      });
  };
  sendMessage = () => {
    this.setState({ loading: true });
    let payLoad = {
      message:
        this.state.type === "Send Written Message"
          ? this.state.message
          : this.state.type === "Send Login Credentials"
          ? "Hey," +
            this.state.student +
            " Your Email Id is " +
            this.state.email +
            " and Password is " +
            this.state.password
          : this.state.type === "Send Product Key"
          ? "Hey," +
            this.state.student +
            "\nYour Product Key is " +
            this.state.pKey
          : "Hey," +
            this.state.student +
            "\nCourier Company is " +
            this.state.courierCom +
            ",Tracking Id is " +
            this.state.trackingNo +
            " and Trackin Link is " +
            this.state.tracLink,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/user_off_ord_sms/" + this.state.rowId,
        payLoad
      )
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  sendMail = () => {
    this.setState({ loading: true });
    let payLoad = {
      messages: this.state.emailMess,
      name: this.state.student,
      email: this.state.email,
    };
    axios
      .post("https://admin.akclasses.in/api/email_user", payLoad)
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  downloadInvoice = (id) => {
    axios.get(Constants.getUrls.downloadOfflinePdf + id).then((resp) => {
      console.log(resp);
    });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/offlineOrders?order_type=" +
              this.state.category +
              "&status=" +
              this.state.ordType +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                pKey: "",
                trackingNo: "",
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  handleCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.category === "Extend_Validity") {
          this.setState(
            {
              orders: ["Order Placed", "Extended", "Shipped"],
            },
            () => {
              this.getData();
            }
          );
        } else if (this.state.category === "Test_Series") {
          this.setState(
            {
              orders: ["Order Placed"],
            },
            () => {
              this.getTestData();
            }
          );
        } else {
          this.setState(
            {
              orders: ["Order Placed", "Activated", "Shipped"],
            },
            () => {
              this.getData();
            }
          );
        }
      }
    );
  };
  getData = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/offlineOrders?order_type=" +
          this.state.category +
          "&status=" +
          this.state.ordType +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            pKey: "",
            trackingNo: "",
            btnIdArray,
            fromInc: 1,
            active: 1,
            toInc: 5,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  getTestData = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/offlineOrders?order_type=Test_Series&status=Delivered&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            pKey: "",
            trackingNo: "",
            btnIdArray,
            fromInc: 1,
            active: 1,
            toInc: 5,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/offlineOrders?status=" +
        this.state.ordType +
        "&order_type=" +
        this.state.category +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.userSearch
      ? "https://admin.akclasses.in/api/offlineOrder/show?name=" +
        this.state.searchOrderId +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/offline_order_datevise?order_type=" +
        this.state.category +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, ordId, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .post(
            "https://admin.akclasses.in/api/offlineOrder/order_status/" + id,
            // Constants.postUrls.offlineOrderStatus + id,
            {
              order_status: this.state.delStatus,
            }
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({
                notifyStatus: false,
              });
            }, 2000);
          });
      }
    );
  };

  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/offline_order_datevise?order_type=" +
          this.state.category +
          "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        //console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            pKey: "",
            trackingNo: "",
            btnIdArray,
            ord: false,
            repButton: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            userSearch: false,
            empty: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleModal = (sec) => {
    // console.log(sec)
    axios
      .get(
        "https://admin.akclasses.in/api/getExtensionOrders?orderId=" +
          sec.ORDER_ID
      )
      .then((resp) => {
        console.log(resp.data);
        console.log(resp.data.data.length);
        this.setState({
          extensionOrders: resp.data.data,
        });
      });
    this.setState(
      {
        product: sec.course ? sec.course.TITLE : "",
        orderId: sec.ORDER_ID,
        rowId: sec.ID,
        osType: sec.variant ? sec.variant.os_type.os_type : "",
        mediaType: sec.variant ? sec.variant.media_type.media_type : "",
        viewValidity: sec.variant
          ? sec.variant.view_and_validity.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
        language: sec.course ? sec.course.language : "",
        attempt: sec.attempt ? sec.attempt.title : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        // address: sec.ADDRESS ? sec.ADDRESS : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
        pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
        ftof_key: sec?.ftof_key,
        user_type: sec.user_type ? sec.user_type : "",
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        student: sec.NAME
          ? sec.NAME
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
          : "",
        password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        logic: sec.logistic_provider ? sec.logistic_provider.id : "",
        tracLink: sec.logistic_provider
          ? sec.logistic_provider.tracking_link
          : "",
        courierCom: sec.logistic_provider ? sec.logistic_provider.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        userData: sec.user,
        state: sec.STATE ? sec.STATE : "",
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
        reference: sec.vender_list ? sec.vender_list.id : "",
      },
      () => {
        if (this.state.delStatus === "Shipped") {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
              "Tracking Details",
            ],
          });
        } else {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
            ],
          });
        }
      }
    );
    this.openModal();
  };

  handleDelete = (id) => {
    // return console.log(id)
    this.setState({ delLoading: true });
    let payload = {
      order_id: id,
      type: "offline",
    };
    axios
      .post("https://admin.akclasses.in/api/order/delete", payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      });
  };

  handleStratDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/deleteAllotedStrategy/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    axios
      .post(
        "https://admin.akclasses.in/api/offlineOrder/notes/" + this.state.rowId,
        {
          note: this.state.notes,
        }
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notifyNote: false });
          window.location.reload();
        }, 2000);
      });
  };

  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      prod_key: this.state.pKey,
      logistic: this.state.logic,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/shipping_detail/" + this.state.orderId,
        // Constants.postUrls.shipping_detail + this.state.orderId,
        payLoad
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          notification: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  handleOrders = (btn, index) => {
    let value = btn === "Activated" || btn === "Extended" ? "Packaged" : btn;
    axios
      .get(
        "https://admin.akclasses.in/api/offlineOrders?status=" +
          // Constants.getUrls.offlineOrders + "?status=" +
          value +
          "&order_type=" +
          this.state.category +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            pKey: "",
            trackingNo: "",
            empty: false,
            fromInc: 1,
            btnIdArray,
            active: 1,
            toInc: 5,
            ordType: value,
            ord: true,
            act: index,
            userSearch: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.logic);
      }
    );
  };

  searchUser = () => {
    let payload;
    if (this.state.search_by === "user_name") {
      let payload = {
        name: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    if (this.state.search_by === "email") {
      let payload = {
        email: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    if (this.state.search_by === "mobile_no") {
      let payload = {
        mobile_no: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    // if (this.state.search_by === "batch_id") {
    //   let payload = {
    //     batch_id: this.state.user,
    //   };
    //   this.searchOrderId(payload);
    // }
  };

  searchOrderId = (e) => {
    let payload = {
      search: this.state.searchOrderId,
      per_page: "",
    };
    console.log(payload);
    // return
    axios
      .post("https://admin.akclasses.in/api/offlineOrder/show?", payload)
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            pKey: "",
            trackingNo: "",
            userSearch: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            ord: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleFtoFBatch = (sec, fBatchId) => {
    this.setState(
      {
        ftofbatchId: sec.ftofbatch,
        product: sec.course ? sec.course.TITLE : "",
        orderId: sec.ORDER_ID,
        rowId: sec.ID,
        osType: sec.variant ? sec.variant.os_type.os_type : "",
        mediaType: sec.variant ? sec.variant.media_type.media_type : "",
        viewValidity: sec.variant
          ? sec.variant.view_and_validity.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
        language: sec.course ? sec.course.language : "",
        attempt: sec.attempt ? sec.attempt.title : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        // address: sec.ADDRESS ? sec.ADDRESS : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
        pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
        ftof_key: sec?.ftof_key,
        user_type: sec.user_type ? sec.user_type : "",
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        student: sec.NAME
          ? sec.NAME
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
          : "",
        password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        logic: sec.logistic_provider ? sec.logistic_provider.id : "",
        tracLink: sec.logistic_provider
          ? sec.logistic_provider.tracking_link
          : "",
        courierCom: sec.logistic_provider ? sec.logistic_provider.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        userData: sec.user,
        state: sec.STATE ? sec.STATE : "",
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
        reference: sec.vender_list ? sec.vender_list.id : "",
      },
      () => {
        console.log(this.state.ftofbatchId);
        if (this.state.ftofbatchId !== null) {
          this.setState(
            {
              showFbatchName: true,
            },
            () => {
              if (this.state.showFbatchName === true) {
                axios
                  .get(
                    Constants.getUrls.getSingleFbatch + this.state.ftofbatchId
                  )
                  .then((res) => {
                    console.log(res);
                    this.setState({
                      fbatchName: res.data.data.batch_name,
                    });
                  });
              } else {
                this.setState({
                  fbatchName: "Not Found",
                });
              }
            }
          );
        } else {
          this.setState(
            {
              showFbatchName: false,
            },
            () => {
              if (this.state.showFbatchName === false) {
                this.setState({
                  fbatchName: "Not Found",
                });
              }
            }
          );
        }
        axios.get(Constants.getUrls.faceToFaceBatch).then((resp) => {
          console.log(resp);
          this.setState({
            ftofbatches: resp.data.data,
          });
        });
      }
    );
    this.openModal();
  };

  allotFToFBatch = (selectedid) => {
    console.log(selectedid);
    this.setState({ loading: true });
    let payLoad = {
      ftofbatch: selectedid,
    };
    axios
      .post(
        Constants.postUrls.updateFtoFBatchoffline + this.state.rowId,
        payLoad
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          // notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  addExtension = (orderId, extId, extDate, extRs) => {
    // console.log(orderId,extId,extDate,extRs);
    this.setState({ loading: true });
    let payLoad = {
      order_id: orderId,
      extension_course_id: extId,
      expiry_date: extDate,
      price: extRs,
    };
    console.log(payLoad);
    axios
      .post(Constants.postUrls.addExtensionOfflineOrder, payLoad)
      .then((resp) => {
        console.log(resp);
        this.setState({
          notifyExt: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  // saveTestSeries = (productId, orderId, userId) => {
  //   console.log(productId, orderId, userId);
  //   const payload = {
  //     user_id: this.state.userId,
  //     course_id: this.state.productId,
  //     order_no: this.state.orderId,
  //     order_type: "Offline",
  //   }
  //   axios.post(Constants.postUrls.checkAllotedTestExam, payload).then(resp => {
  //     console.log(resp);
  //     if(resp.data.status == "success")
  //     {
  //       this.setState({
  //         notifyTest: true,
  //       },() => {
  //         setTimeout(() => {
  //           this.setState({
  //             visible: false,
  //             notifyTest: false
  //           })
  //         }, 2000);
  //       })
  //     }
  //   })
  // }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/extendofflineorder/" + this.state.productId,
            state: {
              pKey: this.state.pKey,
              user: this.state.user,
              total: this.state.grandTotal,
              title: this.state.title,
            },
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.mailSent ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Email Sent Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}

            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody style={{ position: "relative" }}>
                  <FormControl
                    style={{
                      position: "absolute",
                      right: "10%",
                      width: "85px",
                    }}
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Per Page
                    </InputLabel>
                    <Select
                      s
                      key={1}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.per_page}
                      inputProps={{
                        name: "per_page",
                        id: "simple-select",
                        onChange: (e) => {
                          this.handlePage(e);
                        },
                      }}
                    >
                      {perPages.map((v, i) => (
                        <MenuItem
                          key={i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={v}
                        >
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl
                      style={{ marginTop: "12px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.category}
                        inputProps={{
                          name: "category",
                          id: "simple-select",
                          onChange: (e) => {
                            this.handleCategory(e);
                          },
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="Course"
                        >
                          Course
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="Test_Series"
                        >
                          Test Series
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="Books"
                        >
                          Books
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="Extend_Validity"
                        >
                          Extension
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <br />
                  <label
                    style={{
                      marginLeft: "12px",
                      color: "black",
                      fontSize: "17px",
                    }}
                  >
                    Filter The Orders
                  </label>
                  <br />
                  <br />
                  <label
                    style={{
                      color: "black",
                      position: "relative",
                      left: "10px",
                    }}
                  >
                    By Attempt
                  </label>
                  <br />
                  {this.state.orders.length === 1 ? (
                    <Button color="secondary">Order Placed</Button>
                  ) : (
                    this.state.orders.map((btn, index) => (
                      <Button
                        key={index}
                        id={btn}
                        style={{ marginRight: "10px", marginLeft: "10px" }}
                        color={this.state.act === index ? "danger " : "info"}
                        onClick={() => {
                          this.handleOrders(btn, index);
                        }}
                      >
                        {btn}
                      </Button>
                    ))
                  )}
                  <br />
                  <GridContainer style={{ position: "relative", left: "10px" }}>
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <label
                        style={{
                          color: "black",
                        }}
                      >
                        By Date
                      </label>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={(e) => {
                            let year = e._d.getFullYear().toString();
                            let month = (e._d.getMonth() + 101)
                              .toString()
                              .substring(1);
                            let day = (e._d.getDate() + 100)
                              .toString()
                              .substring(1);
                            this.setState(
                              {
                                frmDate: year + "/" + month + "/" + day,
                              },
                              () => {
                                //console.log(this.state.frmDate);
                              }
                            );
                          }}
                          inputProps={{
                            placeholder: "From Date",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={(e) => {
                            let year = e._d.getFullYear().toString();
                            let month = (e._d.getMonth() + 101)
                              .toString()
                              .substring(1);
                            let day = (e._d.getDate() + 100)
                              .toString()
                              .substring(1);
                            this.setState(
                              {
                                toDate: year + "/" + month + "/" + day,
                              },
                              () => {
                                //console.log(this.state.toDate);
                              }
                            );
                          }}
                          inputProps={{
                            placeholder: "To Date",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <Button
                        style={{
                          position: "relative",
                          top: "13px",
                          right: "20px",
                        }}
                        onClick={this.searchReport}
                        color="success"
                      >
                        Search
                      </Button>
                    </GridItem>
                    {this.state.repButton ? (
                      <GridItem xs={12} sm={12} md={2}>
                        <br />
                        <a
                          href={
                            "https://admin.akclasses.in/api/offline_order_excel_report?frmdate=" +
                            this.state.frmDate +
                            "&todate=" +
                            this.state.toDate
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            style={{
                              position: "relative",
                              top: "13px",
                              right: "60px",
                            }}
                            onClick={() => {
                              this.setState({ repButton: false });
                            }}
                            color="danger"
                          >
                            Download
                          </Button>
                        </a>
                      </GridItem>
                    ) : null}

                    {/* <GridContainer style={{ position: "relative", left: "10px" }}> */}
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      {/* <FormControl
                        style={{
                          // position: "absolute",
                          width: "120px",
                        }}
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Search by
                        </InputLabel>
                        <Select
                          // s
                          // key={1}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.search_by}
                          inputProps={{
                            name: "search_by",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState(
                                {
                                  [e.target.name]: e.target.value,
                                },
                                () => {
                                  console.log(this.state.search_by);
                                }
                              );
                            },
                          }}
                        >
                          <MenuItem value="user_name">User Name</MenuItem>
                          <MenuItem value="email">Email Id</MenuItem>
                          <MenuItem value="mobile_no">Mobile Number</MenuItem>
                        </Select>
                      </FormControl> */}

                      <CustomInput
                        style={
                          {
                            // marginLeft: "30px"
                          }
                        }
                        labelText="Search..."
                        id="float"
                        inputProps={{
                          required: true,
                          value: this.state.searchOrderId,
                          onChange: (e) =>
                            this.setState({ searchOrderId: e.target.value }),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <Button
                        style={{
                          position: "relative",
                          top: "13px",
                          right: "15px",
                          // marginBottom:"-26px"
                        }}
                        onClick={this.searchOrderId}
                        color="success"
                      >
                        Search
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <h3>Offline Orders</h3>
                  {this.state.category === "Extend_Validity" ? (
                    <Button
                      className=""
                      onClick={() => {
                        this.setState({ extend: true, visible: true });
                      }}
                      color="warning"
                    >
                      Add Offline Order
                    </Button>
                  ) : (
                    <div>
                      <Link
                        to={"/admin/add" + this.state.category + "offlineorder"}
                      >
                        <Button className="" color="warning">
                          Add Offline Order
                        </Button>
                      </Link>
                      <Link to="/admin/akWebPortal/offlineorders">
                        <Button className="" color="success">
                          AK Web
                        </Button>
                      </Link>
                    </div>
                  )}

                  <Table
                    style={{
                      fontWeight: "bold",
                    }}
                    tableHead={
                      this.state.category === "Extend_Validity" ||
                      this.state.category === "Test_Series" ||
                      this.state.category === "Books"
                        ? [
                            "Sr No.",
                            "Order No",
                            "Batch",
                            <div key={1} style={{ width: "70px" }}>
                              Order Date
                            </div>,
                            <div key={1} style={{ width: "150px" }}>
                              Product Details
                            </div>,
                            "Faculty Details",
                            "Student Details",
                            "Reference By",
                            "Order Type",
                            "Grand Total",
                            // "Reference Code",
                            "Shipping Details",
                            "Extension",
                            "Shipping Status",
                            "Actions",
                          ]
                        : [
                            "Sr No.",

                            <div key={1} style={{ width: "100px" }}>
                              Order Date
                            </div>,
                            <div key={1} style={{ width: "300px" }}>
                              Product Details
                            </div>,
                            "Student Details",

                            // "Product Key Extended",
                            // "Reference Code",
                            "Shipping Details",
                            "Extension",
                            // "Shipping Status",
                            "Actions",
                          ]
                    }
                    tableData={
                      this.state.category === "Extend_Validity" ||
                      this.state.category === "Test_Series" ||
                      this.state.category === "Books"
                        ? this.state.secondaryCategories.map((sec, index) => [
                            [index + 1],
                            [sec.ORDER_ID],
                            [sec.batch ? sec.batch.batch_name : "NA"],
                            [
                              sec.ORDER_DATE.slice(8, 10) +
                                "-" +
                                sec.ORDER_DATE.slice(5, 7) +
                                "-" +
                                sec.ORDER_DATE.slice(0, 4),
                            ],
                            [sec.course ? sec.course.TITLE : "NA"],
                            [this.findCourse(sec.COURSE_ID)],
                            [
                              <div key={index}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sec.NAME
                                    ? sec.NAME
                                    : sec.user
                                    ? (sec.user.FIRST_NAME
                                        ? sec.user.FIRST_NAME
                                        : "") +
                                      " " +
                                      (sec.user.LAST_NAME === "null"
                                        ? "______"
                                        : sec.user.LAST_NAME)
                                    : "NA"}
                                  ,
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    // fontWeight:'bold',
                                    color: "blue",
                                  }}
                                >
                                  {sec.EMAIL
                                    ? sec.EMAIL
                                    : sec.user
                                    ? sec.user.email
                                    : "NA"}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sec.CONTACT_NO
                                    ? sec.CONTACT_NO
                                    : sec.user
                                    ? sec.user.MOBILE
                                    : "NA"}
                                  ,
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sec.ADDRESS
                                    ? sec.ADDRESS
                                    : sec.user
                                    ? sec.user.ADDRESS_1
                                    : "NA"}
                                </span>
                                ,
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sec.CITY
                                    ? sec.CITY
                                    : sec.user
                                    ? sec.user.CITY
                                    : "NA"}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sec.PINCODE
                                    ? sec.PINCODE
                                    : sec.user
                                    ? sec.user.PINCODE
                                    : "NA"}
                                </span>
                                <br />
                                {/* <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  PW :{" "}
                                  {sec.user
                                    ? sec.user.DE_PASSWORD
                                      ? sec.user.DE_PASSWORD
                                      : "NA"
                                    : "NA"}
                                </span> */}
                                <br />
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  Referral Code :{" "}
                                  {this.findReferenceCode(sec.ORDER_ID)}
                                </span>
                              </div>,
                            ],

                            [
                              sec.vender_list
                                ? sec.vender_list.institute_name
                                : "NA",
                            ],
                            [sec.user_type],
                            [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"],
                            // [this.findReferenceCode(sec.ORDER_ID)],
                            [
                              sec.DELIVERY_STATUS === "Order Placed" ? (
                                <Button
                                  key={index}
                                  color="danger"
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      mail: false,
                                      addShip: true,
                                      logDetail: false,
                                      showStrategy: false,
                                      import: false,
                                      mess: false,
                                      print: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                >
                                  <i
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      margin: "0px",
                                    }}
                                    className="material-icons"
                                  >
                                    &#xe558;
                                  </i>
                                </Button>
                              ) : sec.DELIVERY_STATUS === "Shipped" ? (
                                <Button
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      addShip: false,
                                      mess: false,
                                      import: false,
                                      mail: false,
                                      logDetail: true,
                                      print: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                  key={index}
                                  color="success"
                                >
                                  {sec.DELIVERY_STATUS}
                                </Button>
                              ) : (
                                <Button
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      addShip: true,
                                      mail: false,
                                      mess: false,
                                      logDetail: false,
                                      import: false,
                                      print: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                  key={index}
                                  color="success"
                                >
                                  {this.state.category === "Extend_Validity"
                                    ? "Extended"
                                    : "Activated"}
                                </Button>
                              ),
                            ],
                            [
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    extension:
                                      sec.course.product_expiry_status ===
                                        "No" ||
                                      sec.course.product_expiry_status === ""
                                        ? false
                                        : true,
                                    notExpiredCourse:
                                      sec.course.product_expiry_status ===
                                        "No" ||
                                      sec.course.product_expiry_status === ""
                                        ? true
                                        : false,
                                    proDetail: false,
                                    mail: false,
                                    addShip: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: false,
                                    mess: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                              >
                                Add Extension
                              </Button>,
                              <Button
                                key={index}
                                color={
                                  sec.extension_order &&
                                  sec.extension_order.length > 0
                                    ? "success"
                                    : "secondary"
                                }
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    extOrders: true,
                                    extension: false,
                                    proDetail: false,
                                    mail: false,
                                    addShip: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: false,
                                    mess: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                              >
                                View Extension
                              </Button>,
                            ],
                            [sec?.DELIVERY_STATUS],
                            [
                              <span key={1}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      proDetail: true,
                                      addShip: false,
                                      mess: false,
                                      import: false,
                                      mail: false,
                                      print: false,
                                      logDetail: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe417;</i>
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      comment: true,
                                      print: false,
                                      mail: false,
                                      mess: false,
                                      showStrategy: false,
                                      import: false,
                                      addShip: false,
                                      logDetail: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe0b9;</i>
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      showStrategy: false,
                                      comment: false,
                                      logDetail: false,
                                      mess: false,
                                      mail: false,
                                      import: false,
                                      addShip: false,
                                      delModal: false,
                                      print: true,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe8ad;</i>
                                </span>
                                <Link
                                  key={index}
                                  style={{ color: "black" }}
                                  to={{
                                    pathname:
                                      "/admin/update" +
                                      this.state.category +
                                      "offlineorder/" +
                                      sec.ID,
                                    state: { categoryData: sec },
                                  }}
                                >
                                  <i className="material-icons">&#xe3c9;</i>
                                </Link>
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      comment: false,
                                      logDetail: false,
                                      mail: false,
                                      showStrategy: false,
                                      import: false,
                                      addShip: false,
                                      mess: false,
                                      print: false,
                                      delModal: true,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe5c9;</i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    bottom: "4px",
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      mess: true,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      import: false,
                                      showStrategy: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="fab">&#xf39f;</i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      mail: true,
                                      showStrategy: false,
                                      import: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe0be;</i>
                                </span>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      statLoading: true,
                                      visible: true,
                                      ftofbatch: false,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      showStrategy: true,
                                      addShip: false,
                                      mail: false,
                                      print: false,
                                      import: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                    axios
                                      .get(
                                        // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                        Constants.getUrls.getUserStrat +
                                          "?ordId=" +
                                          sec.ID +
                                          "&user_id=" +
                                          sec.user.ID +
                                          "&course_id=" +
                                          sec.course.ID
                                      )
                                      .then((resp) => {
                                        console.log(resp);
                                        if (resp.data.status === "success") {
                                          resp.data.notAddedData.forEach(
                                            (v) => {
                                              this.state.notaddedStrategies.push(
                                                {
                                                  label:
                                                    v.strategy.course.TITLE +
                                                    " (" +
                                                    v.strategy.duration +
                                                    " days, " +
                                                    v.strategy.strategy_type +
                                                    " , " +
                                                    v.strategy.type +
                                                    ")",
                                                  value: v.strategy.id,
                                                }
                                              );
                                            }
                                          );

                                          this.setState({
                                            statLoading: false,
                                            addedStrategies:
                                              resp.data.addedData.length > 0
                                                ? resp.data.addedData
                                                : [],
                                            // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                          });
                                        } else {
                                          this.setState({
                                            statLoading: false,
                                            addedStrategies: [],
                                            notaddedStrategies: [],
                                          });
                                        }
                                      });
                                  }}
                                >
                                  <i
                                    className="fas fa-lightbulb"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      ftofbatch: false,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      showStrategy: false,
                                      import: true,
                                      addShip: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i
                                    title="Import User"
                                    className="fas fa-file-import"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    bottom: "4px",
                                  }}
                                  onClick={() => {
                                    this.handleFtoFBatch(
                                      sec,
                                      sec.course.ftofbatch
                                    );
                                    this.setState({
                                      visible: true,
                                      ftofbatch: true,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      import: false,
                                      showStrategy: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="fab">&#xf2d5;</i>
                                </span>
                              </span>,
                            ],
                          ])
                        : this.state.secondaryCategories.map((sec, index) => [
                            [index + 1],
                            [
                              sec.ORDER_DATE.slice(8, 10) +
                                "-" +
                                sec.ORDER_DATE.slice(5, 7) +
                                "-" +
                                sec.ORDER_DATE.slice(0, 4),
                            ],
                            [
                              <div key={index}>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    Order No.:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.ORDER_ID}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    Course:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {sec.course ? sec.course.TITLE : "NA"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "1px",
                                    }}
                                  >
                                    Batch:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {sec.course.course_batches &&
                                    sec.course.course_batches.length > 0
                                      ? sec.course.course_batches
                                          .map(
                                            (cbatch) =>
                                              cbatch?.batch?.batch_name || "NA"
                                          )
                                          .join(", ")
                                      : "NA"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    Attempt:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {" "}
                                    {sec.course.course_batches &&
                                    sec.course.course_batches.length > 0
                                      ? sec.course.course_batches
                                          .map(
                                            (cbatch) =>
                                              cbatch?.batch?.attempt?.title ||
                                              "NA"
                                          )
                                          .join(", ")
                                      : "NA"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "15px",
                                    }}
                                  >
                                    Faculty:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "15px",
                                    }}
                                  >
                                    {this.findCourse(sec.COURSE_ID)}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    Franchise
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {sec.vender_list
                                      ? sec.vender_list.institute_name
                                      : "NA"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "15px",
                                    }}
                                  >
                                    MRP:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "15px",
                                    }}
                                  >
                                    {sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    Discount:
                                  </td>
                                  <tr
                                    style={{
                                      border: "1px solid #959595",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        color: "red",
                                      }}
                                    >
                                      Coupon
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.COUPON_CODE || "No Data"}
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      border: "1px solid #959595",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        color: "red",
                                      }}
                                    >
                                      Amount
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.coupon_discount_amount || "No Data"}
                                    </td>
                                  </tr>
                                </tr>
                              </div>,

                              // sec.course ? sec.course.TITLE : "NA"
                            ],
                            // [this.findCourse(sec.product_id)],
                            [
                              <div key={index}
                              
                              >
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "25px",
                                      }}
                                    >
                                      Name:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.FIRST_NAME
                                        ? (sec.FIRST_NAME
                                            ? sec.FIRST_NAME
                                            : "") +
                                          " " +
                                          (sec.LAST_NAME === "null" ? "" : "")
                                        : sec.user
                                        ? sec.user.FIRST_NAME
                                          ? sec.user.FIRST_NAME
                                          : ""
                                        : //   +
                                          // " " +
                                          // (sec.user.LAST_NAME === "null"
                                          //     ? "______"
                                          //     : sec.user.LAST_NAME)
                                          "NA"}
                                      ,
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      // fontWeight:'bold',
                                      color: "blue",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "27px",
                                      }}
                                    >
                                      {" "}
                                      Email:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.EMAIL
                                        ? sec.EMAIL
                                        : sec.user
                                        ? sec.user.email
                                        : "NA"}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "12px",
                                      }}
                                    >
                                      {" "}
                                      Contact:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.CONTACT_NO
                                        ? sec.CONTACT_NO
                                        : sec.user
                                        ? sec.user.MOBILE
                                        : "NA"}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "9px",
                                      }}
                                    >
                                      Address:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.ADDRESS ? (
                                        <div>
                                          {sec.ADDRESS}
                                          {sec.ADDRESS_2 && <br />}
                                          {sec.ADDRESS_2}
                                        </div>
                                      ) : sec.user ? (
                                        <div>
                                          {sec.user.ADDRESS_1}
                                          {sec.user.ADDRESS_2 && <br />}
                                          {sec.user.ADDRESS_2}
                                        </div>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "29px",
                                      }}
                                    >
                                      State:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {" "}
                                      {sec.STATE
                                        ? sec.STATE
                                        : sec.user
                                        ? sec.user.STATE
                                        : "NA"}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "38px",
                                      }}
                                    >
                                      City:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {" "}
                                      {sec.CITY
                                        ? sec.CITY
                                        : sec.user
                                        ? sec.user.CITY
                                        : "NA"}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                        paddingRight: "9px",
                                      }}
                                    >
                                      Pincode:
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {sec.PINCODE
                                        ? sec.PINCODE
                                        : sec.user
                                        ? sec.user.PINCODE
                                        : "NA"}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                PW :{" "}
                                {sec.user
                                  ? sec.user.DE_PASSWORD
                                    ? sec.user.DE_PASSWORD
                                    : "NA"
                                  : "NA"}
                              </span> */}
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      Referral Code :{" "}
                                      {this.findReferenceCode(sec.ORDER_ID)}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderLeft: "1px solid #959595",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      Payment Staus: {sec?.PAYMENT_STATUS}
                                    </td>
                                  </span>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                    fontWeight: "bold",
                                    backgroundColor: sec?.user?.TYPE === "Premium" ? "green" :
                                    sec?.user?.TYPE === "Free" ? "red" :
                                    "transparent",
                                    color: sec?.user?.TYPE === "Books" ? "black" :"white"
                                    
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <td
                                  
                                    style={{
                                      // border: "1px solid #959595",
                                      fontWeight: "bold",
                                  
                                      
                                    }}
                                    
                                    >
                                      User Type: {sec?.user?.TYPE}
                                    </td>
                                  </span>
                                </tr>
                              </div>,
                            ],
                            // [sec.PRODUCT_EXTENDED],
                            // [this.findReferenceCode(sec.ORDER_ID)],
                            [
                              sec.DELIVERY_STATUS === "Order Placed" ? (
                                <Button
                                  key={index}
                                  color="danger"
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      mail: false,
                                      addShip: true,
                                      logDetail: false,
                                      import: false,
                                      showStrategy: false,
                                      mess: false,
                                      print: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                >
                                  <i
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      margin: "0px",
                                    }}
                                    className="material-icons"
                                  >
                                    &#xe558;
                                  </i>
                                </Button>
                              ) : sec.DELIVERY_STATUS === "Shipped" ? (
                                <Button
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      addShip: false,
                                      mail: false,
                                      mess: false,
                                      import: false,
                                      logDetail: true,
                                      print: false,
                                      showStrategy: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                  key={index}
                                  color="success"
                                >
                                  {sec.DELIVERY_STATUS}
                                </Button>
                              ) : (
                                <Button
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: false,
                                      showStrategy: false,
                                      addShip: true,
                                      mail: false,
                                      import: false,
                                      mess: false,
                                      logDetail: false,
                                      print: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                  key={index}
                                  color="success"
                                >
                                  {this.state.category === "Extend_Validity"
                                    ? "Extended"
                                    : "Activated"}
                                </Button>
                              ),
                            ],
                            [
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    extension:
                                      sec.course.product_expiry_status ===
                                        "No" ||
                                      sec.course.product_expiry_status === ""
                                        ? false
                                        : true,
                                    notExpiredCourse:
                                      sec.course.product_expiry_status ===
                                        "No" ||
                                      sec.course.product_expiry_status === ""
                                        ? true
                                        : false,
                                    proDetail: false,
                                    mail: false,
                                    addShip: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: false,
                                    mess: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                              >
                                Add Extension
                              </Button>,
                              <Button
                                key={index}
                                color={
                                  sec.extension_order &&
                                  sec.extension_order.length > 0
                                    ? "success"
                                    : "secondary"
                                }
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    extOrders: true,
                                    extension: false,
                                    proDetail: false,
                                    mail: false,
                                    addShip: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: false,
                                    mess: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                              >
                                View Extension
                              </Button>,
                            ],
                            // [sec?.DELIVERY_STATUS],
                            [
                              <span key={1}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      proDetail: true,
                                      addShip: false,
                                      mess: false,
                                      showStrategy: false,
                                      mail: false,
                                      print: false,
                                      import: false,
                                      logDetail: false,
                                      delModal: false,
                                      comment: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe417;</i>
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      comment: true,
                                      print: false,
                                      mail: false,
                                      mess: false,
                                      import: false,
                                      showStrategy: false,
                                      addShip: false,
                                      logDetail: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe0b9;</i>
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      comment: false,
                                      logDetail: false,
                                      showStrategy: false,
                                      mess: false,
                                      import: false,
                                      mail: false,
                                      addShip: false,
                                      delModal: false,
                                      print: true,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe8ad;</i>
                                </span>
                                <Link
                                  key={index}
                                  style={{ color: "black" }}
                                  to={{
                                    pathname:
                                      "/admin/update" +
                                      this.state.category +
                                      "offlineorder/" +
                                      sec.ID,
                                    state: { categoryData: sec },
                                  }}
                                >
                                  <i className="material-icons">&#xe3c9;</i>
                                </Link>
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      showStrategy: false,
                                      mail: false,
                                      mess: false,
                                      import: false,
                                      print: false,
                                      delModal: true,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe5c9;</i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    bottom: "4px",
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      mess: true,
                                      comment: false,
                                      mail: false,
                                      logDetail: false,
                                      showStrategy: false,
                                      import: false,
                                      addShip: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="fab">&#xf39f;</i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      import: false,
                                      showStrategy: false,
                                      mail: true,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="material-icons">&#xe0be;</i>
                                </span>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      statLoading: true,
                                      visible: true,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      showStrategy: true,
                                      import: false,
                                      addShip: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                    axios
                                      .get(
                                        // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                        Constants.getUrls.getUserStrat +
                                          "?ordId=" +
                                          sec?.ID +
                                          "&user_id=" +
                                          sec?.user?.ID +
                                          "&course_id=" +
                                          sec?.course?.ID
                                      )
                                      .then((resp) => {
                                        console.log(resp);
                                        if (resp.data.status === "success") {
                                          resp.data.notAddedData.forEach(
                                            (v) => {
                                              this.state.notaddedStrategies.push(
                                                {
                                                  label:
                                                    v.strategy.course.TITLE +
                                                    " (" +
                                                    v.strategy.duration +
                                                    " days, " +
                                                    v.strategy.strategy_type +
                                                    " , " +
                                                    v.strategy.type +
                                                    ")",
                                                  value: v.strategy.id,
                                                }
                                              );
                                            }
                                          );

                                          this.setState({
                                            statLoading: false,
                                            addedStrategies:
                                              resp.data.addedData.length > 0
                                                ? resp.data.addedData
                                                : [],
                                            // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                          });
                                        } else {
                                          this.setState({
                                            statLoading: false,
                                            addedStrategies: [],
                                            notaddedStrategies: [],
                                          });
                                        }
                                      });
                                  }}
                                >
                                  <i
                                    className="fas fa-lightbulb"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.handleModal(sec);
                                    this.setState({
                                      visible: true,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      showStrategy: false,
                                      import: true,
                                      addShip: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i
                                    title="Import User"
                                    className="fas fa-file-import"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                                <br />
                                <a
                                  href={
                                    "https://admin.akclasses.in/api/downloadOfflinePdf/" +
                                    sec.ID
                                  }
                                  target="_blank"
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                      position: "relative",
                                      // bottom: "4px"
                                    }}
                                    onClick={() => {
                                      this.downloadInvoice(sec.ID);
                                    }}
                                  >
                                    <i
                                      title="Download Invoice"
                                      className="fas fa-file-invoice"
                                      style={{ fontSize: "24px" }}
                                    ></i>
                                  </span>
                                </a>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    bottom: "4px",
                                  }}
                                  onClick={() => {
                                    this.handleFtoFBatch(
                                      sec,
                                      sec.course.ftofbatch
                                    );
                                    this.setState({
                                      visible: true,
                                      ftofbatch: true,
                                      mess: false,
                                      comment: false,
                                      logDetail: false,
                                      addShip: false,
                                      import: false,
                                      showStrategy: false,
                                      mail: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i className="fab">&#xf2d5;</i>
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    bottom: "4px",
                                    left: "7px",
                                  }}
                                  onClick={() => {
                                    this.handleTestSeries(sec);
                                    this.setState({
                                      visible: true,
                                      mess: false,
                                      comment: false,
                                      showStrategy: false,
                                      ftofbatch: false,
                                      testModal: true,
                                      logDetail: false,
                                      import: false,
                                      mail: false,
                                      addShip: false,
                                      print: false,
                                      delModal: false,
                                      proDetail: false,
                                    });
                                  }}
                                >
                                  <i class="fas fa-newspaper"></i>
                                </span>
                              </span>,
                            ],
                          ])
                    }
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            minWidth="3000"
            minHeight="330"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    {this.state.proDetail ? (
                      <div>
                        <h3>Product Details</h3>
                        <Table
                          tableHead={[
                            "Product Name",
                            "Price",
                            "Product Key",
                            // "F to F Key",
                            "OS Type",
                            "Media Type",
                            "Views & Validity",
                            // "Tracking No.",
                            // "Shipping Date",
                            "Payment Status",
                            "Delivery Status",
                            "Language",
                            "Attempt",
                          ]}
                          tableData={[
                            [
                              this.state.product,
                              this.state.price,
                              this.state.pKey,
                              // this.state.ftof_key,
                              this.state.osType,
                              this.state.mediaType,
                              this.state.viewValidity,
                              // this.state.trackingNo,
                              // this.state.shippingDate,
                              this.state.payStatus,
                              this.state.delStatus,
                              this.state.language,
                              this.state.attempt,
                            ],
                          ]}
                        />
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.showStrategy ? (
                      <div>
                        <h5 style={{ textAlign: "center" }}>
                          Do You Want To Add Strategy For This Order?
                        </h5>
                        {this.state.mailS ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Strategy Added Successfully!
                            </span>
                          </div>
                        ) : null}
                        -
                        {this.state.mailFail ? (
                          <div>
                            <span
                              style={{
                                color: "red",
                                textTransform: "capitalize",
                              }}
                            >
                              {this.state.strMessage}
                            </span>
                          </div>
                        ) : null}
                        {this.state.statLoading ? (
                          <h6 style={{ textAlign: "center" }}>
                            Fetching Strategy Details...
                          </h6>
                        ) : (
                          <div style={{ width: "600px" }}>
                            <label htmlFor="Courses">Strategies</label>
                            <MultiSelect
                              disableSearch
                              options={this.state.notaddedStrategies}
                              value={this.state.notAddedStrats}
                              onChange={(data) => {
                                this.setState({ notAddedStrats: data });
                              }}
                              labelledBy={"Strategies"}
                            />
                            <h6 style={{ textAlign: "center" }}>
                              Alloted Strategies
                            </h6>
                            <Table
                              tableHead={[
                                "Course",
                                "Duration",
                                "Strategy Type",
                                "Order Type",
                                "Action",
                              ]}
                              tableData={this.state.addedStrategies.map(
                                (sec) => [
                                  [sec.strategies.course.TITLE],
                                  [sec.strategies.duration],
                                  [sec.strategies.strategy_type],
                                  [sec.strategies.type],
                                  [
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          stratId: sec.id,
                                          visible: true,
                                          delStrategyModal: true,
                                          showStrategy: false,
                                        });
                                      }}
                                    >
                                      Delete
                                    </Button>,
                                  ],
                                ]
                              )}
                            />
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <br />
                                <Button
                                  className=""
                                  color="warning"
                                  onClick={this.sendEmail}
                                >
                                  {this.state.loading ? <Loader /> : "Yes"}
                                </Button>
                                <Button
                                  className=""
                                  color="danger"
                                  onClick={() => {
                                    this.closeModal();
                                    this.setState({
                                      visible: false,
                                      showStrategy: false,
                                    });
                                  }}
                                >
                                  No
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                      </div>
                    ) : this.state.print ? (
                      <div>
                        <div>
                          <img
                            src="https://akclasses.in/static/media/akLogo.93e45c6ce5fc7e0fbea5.png"
                            alt=""
                            width="200px"
                            style={{
                              paddingBottom: "15px",
                              textAlign: "center",
                              marginLeft: "350px",
                              marginRight: "350px",
                            }}
                          />
                        </div>
                        <div>
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            To,
                          </h6>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {this.state.student}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {this.state.address}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {this.state.city},{this.state.state},
                            {this.state.pincode}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {this.state.contact}
                          </span>
                        </div>
                        <br />
                        <div>
                          <h6
                            style={{
                              marginBottom: "0px",
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            From,
                          </h6>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Aman Khedia Classes,
                            <br />
                            The Park Meadows, Flat No. 101,
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Plot No. 48, B.U. Kothari Layout Kachimet,
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Amaravati Road, Nagpur. (MH) 440033
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Office: +91 876 657 5808
                          </span>
                        </div>
                        {this.state.printView ? (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.setState({ printView: false });
                                  setTimeout(() => {
                                    window.print();
                                  }, 1);
                                  setTimeout(() => {
                                    this.setState({ printView: true });
                                  }, 2);
                                }}
                              >
                                Print
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.closeModal();
                                }}
                              >
                                Cancel
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ) : null}
                      </div>
                    ) : this.state.comment ? (
                      <div>
                        <h3>Add Note</h3>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Note</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={this.state.notes}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  notes: data,
                                });
                              }}
                            />
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Note Added Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.handleSubmit}
                            >
                              {this.state.loading ? <Loader /> : "Add"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.delModal ? (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Order Deleted Successfuly!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.delStrategyModal ? (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Strategy Deleted Successfuly!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleStratDelete(this.state.stratId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.logDetail ? (
                      <div>
                        <h3>Shipping Details</h3>
                        <Table
                          tableHead={[
                            "Courier Company",
                            "Tracking No.",
                            "Track Now",
                            "Update",
                          ]}
                          tableData={[
                            [
                              this.state.courierCom,
                              this.state.trackingNo,
                              <a
                                key={1}
                                href={this.state.tracLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button color="danger">
                                  <i
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      margin: "0px",
                                    }}
                                    className="material-icons"
                                  >
                                    &#xe558;
                                  </i>
                                </Button>
                              </a>,
                              <Button
                                key={1}
                                onClick={() => {
                                  this.setState({
                                    visible: true,
                                    proDetail: false,
                                    addShip: false,
                                    print: false,
                                    logDetail: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                                className="btn-round"
                                color="info"
                              >
                                Update
                              </Button>,
                            ],
                          ]}
                        />
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.addShip ? (
                      <form>
                        <h4 style={{ textAlign: "center" }}>
                          Order ID:{this.state.orderId}
                        </h4>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText={"Product Name :" + this.state.product}
                              id="disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={6} sm={12} md={6}>
                            <CustomInput
                              labelText={"Student Name :" + this.state.student}
                              id="disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText={"Address :" + this.state.address}
                              id="disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText={"Producy Key :" + this.state.pKey}
                              id="disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Logistics</label>
                              <select
                                style={{ padding: "11px" }}
                                name="logic"
                                // value={this.state.logic}
                                onChange={this.handleInput}
                              >
                                <option value="">Select</option>
                                {this.state.logistics.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.name}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Order Status</label>
                              <select
                                style={{ padding: "11px" }}
                                name="delStatus"
                                value={this.state.delStatus}
                                onChange={(e) => {
                                  this.handleSelect(
                                    e,
                                    this.state.orderId,
                                    this.state.rowId
                                  );
                                }}
                              >
                                <option value="">Select</option>
                                {this.state.orders.map((v, i) => (
                                  <option
                                    key={i}
                                    value={
                                      v === "Activated" || v === "Extended"
                                        ? "Packaged"
                                        : v
                                    }
                                  >
                                    {v}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                            {this.state.notifyStatus ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Status Changed Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Tracking ID"
                              id="float"
                              inputProps={{
                                disabled: false,
                                onChange: (e) =>
                                  this.setState({ trackingNo: e.target.value }),
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Product Key"
                              id="float"
                              inputProps={{
                                disabled: false,
                                value: this.state.pKey,
                                onChange: (e) =>
                                  this.setState({ pKey: e.target.value }),
                              }}
                              // value={this.state.pKey}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            {this.state.notification ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Shipping Details Added Successfuly!
                                </span>
                              </div>
                            ) : null}
                            <Button
                              className=""
                              color="warning"
                              onClick={this.addShipping}
                            >
                              {this.state.loading ? (
                                <Loader />
                              ) : (
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              )}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe5c9;
                              </i>
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    ) : this.state.import ? (
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          Import{" "}
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            {this.state.student}
                          </span>
                        </h3>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Type</label>
                              <select
                                style={{ padding: "11px" }}
                                value={this.state.type}
                                onChange={(e) => {
                                  this.setState({ type: e.target.value });
                                }}
                              >
                                {this.state.types.map((v, i) => (
                                  <option key={i} value={v.type}>
                                    {v.type}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  User Imported Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.importUser}
                            >
                              {this.state.loading ? "Importing..." : "Import"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.mess ? (
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          Send Message to{" "}
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            {this.state.student}
                          </span>
                        </h3>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>You Want To</label>
                              <select
                                style={{ padding: "11px" }}
                                value={this.state.type}
                                onChange={(e) => {
                                  this.setState(
                                    { type: e.target.value },
                                    () => {
                                      if (
                                        this.state.type ===
                                        "Send Written Message"
                                      ) {
                                        this.setState({ viewMessage: true });
                                      } else {
                                        this.setState({ viewMessage: false });
                                      }
                                    }
                                  );
                                }}
                              >
                                {this.state.options.map((v, i) => (
                                  <option key={i} value={v}>
                                    {v}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </GridItem>
                          {this.state.viewMessage ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <label>Message</label>
                              <br />
                              <textarea
                                name="message"
                                cols="100"
                                rows="5"
                                onChange={(e) => {
                                  this.setState({ message: e.target.value });
                                }}
                              >
                                {this.state.message}
                              </textarea>
                              {this.state.notifyNote ? (
                                <div>
                                  <span style={{ color: "green" }}>
                                    Message Sent Successfuly!
                                  </span>
                                </div>
                              ) : null}
                            </GridItem>
                          ) : null}

                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendMessage}
                            >
                              {this.state.loading ? "Sending..." : "Send"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.mail ? (
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          Send Mail to{" "}
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            {this.state.student}
                          </span>
                        </h3>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Mail</label>
                            <br />
                            <textarea
                              name="mail"
                              cols="100"
                              rows="5"
                              onChange={(e) => {
                                this.setState({ emailMess: e.target.value });
                              }}
                            >
                              {this.state.message}
                            </textarea>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Mail Sent Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendMail}
                            >
                              {this.state.loading ? "Sending..." : "Send"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.ftofbatch ? (
                      <div>
                        {this.state.fbatchName === "Not Found" ? (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <label>Allot Face To Face Batch</label>
                                <select
                                  style={{ padding: "11px" }}
                                  value={this.state.selectedBatchId} // Use selectedBatchId
                                  onChange={(e) => {
                                    this.setState(
                                      { selectedBatchId: e.target.value },
                                      () => {
                                        // if (
                                        //   this.state.type === "Send Written Message"
                                        // ) {
                                        //   this.setState({ viewMessage: true });
                                        // } else {
                                        //   this.setState({ viewMessage: false });
                                        // }
                                      }
                                    );
                                  }}
                                >
                                  <option>Select Batch</option>
                                  {this.state.ftofbatches.map((v, i) => (
                                    <option key={i} value={v.id}>
                                      {" "}
                                      {/* Use v.batch_id */}
                                      {v.batch_name}
                                    </option>
                                  ))}
                                </select>
                              </FormControl>
                            </GridItem>
                            {this.state.viewMessage ? (
                              <GridItem xs={12} sm={12} md={12}>
                                {this.state.notifyBatch ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      Face To Face Batch Alloted Successfully!
                                    </span>
                                  </div>
                                ) : null}
                              </GridItem>
                            ) : null}
                            <GridItem xs={12} sm={12} md={12}>
                              <Button
                                className=""
                                color="warning"
                                onClick={() => {
                                  this.allotFToFBatch(
                                    this.state.selectedBatchId
                                  );
                                }}
                              >
                                {this.state.loading ? "Processing..." : "Allot"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.setState({ visible: false });
                                }}
                              >
                                Cancel
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ) : (
                          <h5 style={{ textAlign: "center" }}>
                            Alloted Face To Face Batch{" "}
                            <span
                              style={{ color: "purple", fontWeight: "bold" }}
                            >
                              {this.state.fbatchName}
                            </span>
                          </h5>
                        )}
                      </div>
                    ) : this.state.testModal ? (
                      <div>
                        {/* {
                          this.state.showcourseAllotMessage ? 
                          <h4 style={{ textAlign: "center", color: "red", fontWeight: 500, paddingBottom: "15px" }}>
                            {this.state.courseAllotMessage}
                          </h4> : ""
                        } */}
                        {this.state.showcourseAllotMessage ? (
                          <div>
                            <span
                              style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: 500,
                                paddingBottom: "15px",
                                marginLeft: "25%",
                                fontSize: "16px",
                              }}
                            >
                              {this.state.courseAllotMessage}
                            </span>
                          </div>
                        ) : null}
                        <h5 style={{ textAlign: "center" }}>
                          Do You Want To Add Test Series For This Order?
                        </h5>
                        {this.state.mailFail ? (
                          <div>
                            <span
                              style={{
                                color: "red",
                                textTransform: "capitalize",
                              }}
                            >
                              {this.state.strMessage}
                            </span>
                          </div>
                        ) : null}
                        {this.state.statLoading ? (
                          <h6 style={{ textAlign: "center" }}>
                            Fetching Test Series Details...
                          </h6>
                        ) : (
                          <div style={{ width: "600px" }}>
                            <label htmlFor="Courses">Test Series</label>
                            <MultiSelect
                              disableSearch
                              options={this.state.testSeries}
                              value={this.state.test}
                              onChange={(data) => {
                                this.setState(
                                  {
                                    test: data,
                                  },
                                  () => {
                                    this.state.test.map((v) => {
                                      this.state.test_seriesId.push(v.value);
                                    });
                                    this.setState({
                                      test_seriesId: this.state.test_seriesId,
                                    });
                                  }
                                );
                              }}
                              labelledBy={"Test Series"}
                            />
                            <h6 style={{ textAlign: "center" }}>
                              Alloted Test Series
                            </h6>
                            <Table
                              tableHead={["Id", "Title"]}
                              tableData={this.state.allotedTestSeries.map(
                                (sec) => [[sec.id], [sec.title]]
                              )}
                            />
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <br />
                                <Button
                                  className=""
                                  color="warning"
                                  onClick={this.saveTestSeries}
                                >
                                  {this.state.loading ? <Loader /> : "Yes"}
                                </Button>
                                <Button
                                  className=""
                                  color="danger"
                                  onClick={() => {
                                    this.closeModal();
                                    this.setState({
                                      visible: false,
                                      showStrategy: false,
                                    });
                                  }}
                                >
                                  No
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                      </div>
                    ) : this.state.extension ? (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Add Extension</label>
                              <select
                                style={{ padding: "11px" }}
                                value={this.state.selectedExtension} // Use selectedExtension
                                onChange={(e) => {
                                  this.setState({
                                    selectedExtension: e.target.value,
                                  });
                                }}
                              >
                                <option>Select Extension Course</option>
                                {this.state.courses.map((v, i) => (
                                  <option key={i} value={v.ID}>
                                    {" "}
                                    {/* Use v.batch_id */}
                                    {v.TITLE + " (" + v.ID + ")"}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <label
                              style={{
                                color: "black",
                              }}
                            >
                              Expiry Date
                            </label>
                            <br />
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                onChange={(e) => {
                                  let year = e._d.getFullYear().toString();
                                  let month = (e._d.getMonth() + 101)
                                    .toString()
                                    .substring(1);
                                  let day = (e._d.getDate() + 100)
                                    .toString()
                                    .substring(1);
                                  this.setState(
                                    {
                                      expiryDate:
                                        year + "/" + month + "/" + day,
                                    },
                                    () => {
                                      //console.log(this.state.expiryDate);
                                    }
                                  );
                                }}
                                inputProps={{
                                  placeholder: "Select Expiry Date",
                                }}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Price"
                              id="float"
                              inputProps={{
                                value: this.state.extPrice,
                                onChange: (e) =>
                                  this.setState({ extPrice: e.target.value }),
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          {this.state.viewMessage ? (
                            <GridItem xs={12} sm={12} md={12}>
                              {this.state.notifyExt ? (
                                <div>
                                  <span style={{ color: "green" }}>
                                    Extension Added Successfully!
                                  </span>
                                </div>
                              ) : null}
                            </GridItem>
                          ) : null}
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.addExtension(
                                  this.state.orderId,
                                  this.state.selectedExtension,
                                  this.state.expiryDate,
                                  this.state.extPrice
                                );
                              }}
                            >
                              {this.state.loading ? "Processing..." : "Add"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.extOrders ? (
                      <div>
                        <h3>Extension Details</h3>
                        <Table
                          tableHead={[
                            "Order No",
                            "Extension Product Details",
                            "Grand Price",
                            "Duration",
                            "Attempt",
                            // "Batches",
                            "Faculty",
                            "Expiry Date",
                          ]}
                          tableData={this.state.extensionOrders.map((order) => [
                            order.order_id,
                            order.course.TITLE,
                            order.price,
                            order.course.DURATION,
                            order.course.attempt.title,
                            // order.course.course_batches[0].batch.batch_name,
                            order.course.faculties.name,
                            order.expiry_date,
                          ])}
                        />
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : this.state.notExpiredCourse ? (
                      <div>
                        <h4>Course is not expired, can not add extension.</h4>
                      </div>
                    ) : // <div>
                    //     <span> Select TestSeries </span>
                    //     <MultiSelect
                    //       disableSearch
                    //       options={this.state.testSeries}
                    //       name="test"
                    //       value={this.state.test}
                    //       onChange={(data) => {
                    //         // console.log(data);
                    //         this.setState(
                    //           {
                    //             test: data,
                    //           },
                    //           () => {
                    //             this.state.test.map((v) => {
                    //               this.state.test_seriesId.push(v.value);
                    //             });
                    //             this.setState({
                    //               test_seriesId: this.state.test_seriesId
                    //             })
                    //           }
                    //         );
                    //       }}
                    //     />

                    /* <h4 style={{ textAlign: "center" }}>
                          Alloted Test Series{" "}
                        </h4>
                        {this.state.testSeries.map((test, index) => (
                          <div key={index} style={{ color: "purple", fontWeight: "bold", textAlign: "left" }}>
                            {index + 1 +")"} {test.paper.TITLE}
                          </div>
                        ))} */
                    // </div>
                    this.state.extend ? (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Product</label>
                              <select
                                style={{ padding: "11px" }}
                                value={this.state.pro}
                                onChange={(e) => {
                                  this.setState({ pro: e.target.value });
                                }}
                              >
                                <option value="">Select</option>
                                {this.state.extensions.map((v, i) => (
                                  <option key={i} value={v.ID}>
                                    {v.TITLE}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                            {this.state.notifyStatus ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Status Changed Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Product Key"
                              id="float"
                              inputProps={{
                                required: true,
                                onChange: (e) =>
                                  this.setState({ pkey: e.target.value }),
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                            {this.state.warn ? (
                              <div>
                                <span style={{ color: "red" }}>
                                  {this.state.message + "!!!"}
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.applyKey}
                            >
                              {this.state.loading ? "Submitting..." : "Submit"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <form>
                        <h4 style={{ textAlign: "center" }}>
                          Update Shipping Details
                        </h4>
                        {/* <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4> */}
                        <GridContainer>
                          {/*   <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem> */}
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Logistics</label>
                              <select
                                style={{ padding: "11px" }}
                                name="logic"
                                value={this.state.logic}
                                onChange={this.handleInput}
                              >
                                <option value="">Select</option>
                                {this.state.logistics.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.name}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </GridItem>
                          {/*  <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={e => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {orders.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem> */}

                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Tracking ID"
                              id="float"
                              inputProps={{
                                disabled: false,
                                value: this.state.trackingNo,
                                onChange: (e) =>
                                  this.setState({ trackingNo: e.target.value }),
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Product Key"
                              id="float"
                              inputProps={{
                                disabled: false,
                                value: this.state.pKey,
                                onChange: (e) =>
                                  this.setState({ pKey: e.target.value }),
                              }}
                              // value={this.state.pKey}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            {this.state.notification ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Shipping Details Updated Successfuly!
                                </span>
                              </div>
                            ) : null}
                            <Button
                              className=""
                              color="info"
                              onClick={this.addShipping}
                            >
                              {this.state.loading ? (
                                <Loader />
                              ) : (
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              )}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe5c9;
                              </i>
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
          {this.state.secondaryCategories.length === 0 ? (
            <div>
              <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            </div>
          ) : this.state.empty === false ? (
            <div>
              <div style={{ float: "left" }}>
                Showing {this.state.from} to {this.state.to} of{" "}
                {this.state.total} entries.
              </div>
              <div style={{ float: "right", border: "1px solid grey" }}>
                <div
                  onClick={this.prevPages}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px",
                    }}
                    className="material-icons"
                  >
                    &#xe5c4;
                  </i>
                </div>
                {this.state.filteredBtnIdArray.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.currentPage(v);
                    }}
                    style={{
                      width: "34px",
                      textAlign: "center",
                      borderLeft:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 4)
                          ? ""
                          : "1px solid grey",
                      borderRight:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 0)
                          ? ""
                          : "1px solid grey",
                      backgroundColor:
                        this.state.active === v ? "skyblue" : "transparent",
                      padding: "4px 0px 4px 0px",
                      fontWeight: this.state.active === v ? "bold" : "",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    {v}
                  </div>
                ))}
                <div
                  onClick={this.nextPages}
                  style={{
                    width: "34px",
                    borderLeft:
                      this.state.filteredBtnIdArray.length === 2 ||
                      this.state.filteredBtnIdArray.length === 4
                        ? "1px solid grey"
                        : "",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px",
                    }}
                    className="material-icons"
                  >
                    &#xe5c8;
                  </i>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default OfflineOrders;
