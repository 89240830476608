import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

const Redeem = ({ close }) => {
  const [getApiData, setGetApiData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { id } = useParams();

  const apiCall = async () => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/wallet/getAllRecords?user_id=${id}&sortBy=created_at`
      );
      console.log(res.data);
      setGetApiData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius:"15px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const check = Array.isArray(getApiData) && getApiData.length !== 0;

  const handleOpenModal = (rowData) => {
    setSelectedRowData(rowData); 
    setOpen(true); 
  };

  const handleCloseModal = () => {
    setOpen(false); 
  };

  const handleSubmit = async () => {
    if (selectedRowData) {
      try {
        const response = await axios.post(
          "https://admin.akclasses.in/api/wallet/creditByUserId",
          {
            user_id: id,
            amount: selectedRowData.amount,
            description: selectedRowData.description,
          }
        );
        console.log(response.data);
        handleCloseModal();
      } catch (error) {
        console.log(error);
      }
    }
  };



  return (
    <div style={{ textAlign: "center" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>Description</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>Amount</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>Type</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>Date</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>Action</b>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          {check && (
            <TableBody>
              {getApiData
                .filter((item) => item.type === "debit")
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ border: "1px solid black" }}>
                      {item.description}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" }}>
                      {item.amount}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" }}>
                      {item.type}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" }}>
                      {item.created_at}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" }}>
                      <Button
                        onClick={() => handleOpenModal(item)} 
                        className="btn-round"
                        color="success"
                      >
                        SETTLED
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!check ? (
        <Typography variant="body1" textAlign="center" sx={{ mt: 5 }}>
          No Data Available
        </Typography>
      ) : null}

      
      <Modal
      style={{borderRadius:"5px"}}
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
            Are You Sure You Want To Make This Settled?
          </Typography>

          <Button
            onClick={handleSubmit}
            color="success"
            style={{margin:"15px"}}
            // sx={{ mt: 2, mr: 1 }}
          >
            YES
          </Button>
          <Button
            onClick={handleCloseModal}
            color="rose"
            sx={{ mt: 2 }}
          >
            NO
          </Button>
        </Box>
      </Modal>
    </div>
  )
};

export default Redeem;


