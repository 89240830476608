import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Button from "components/CustomButtons/Button.js";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import WalletAdd from "./WalletAdd";
import Redeem from "./Redeem";

const WalletManagement = () => {
  const [transection, setTransection] = useState([]);
  const [open, setOpen] = useState(false);

  // const [redeeModel, setRedeeModel] = useState(false);

  const handleModal = () => {
    setOpen(true);
  };
  // const handleRedeemModal = () => {
  //   setRedeeModel(true);
  // };

  const handleModalClose = () => {
    setOpen(false);
  };
  // const handleRedeemModalClose = () => {
  //   setRedeeModel(false);
  // };

  const location = useLocation();
  console.log(location.state.categoryData);
  const [id, setId] = useState(location.state.categoryData);

  const apiCall = async () => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/wallet/getAllRecords?user_id=${id}&sortBy=created_at`
      );
      console.log(res.data.data);
      setTransection(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  const hasReferralCodeUsed = transection.some(
    (item) => item.referral_code_used
  );

  // const handleSubmit = async (rowData) => {
  //   try {
  //     const response = await axios.post(
  //       "http://admin.akclasses.in/api/wallet/creditByUserId",
  //       {
  //         user_id: rowData.amount,
  //         amount: null,
  //         description: null,
  //       }
  //     );
  //     console.log("Redeem successful:", response.data);
  //   } catch (error) {
  //     console.log("Error redeeming:", error);
  //   }
  // };

  return (
    <div>
      {open ? (
        <WalletAdd close={handleModalClose} refreshData={apiCall} />
      ) : null}

      {/* {redeeModel ? (
        <Redeem close={handleRedeemModalClose} refreshData={apiCall} />
      ) : null} */}

      {/* <Link to={{
       pathname: `/admin/walletadd/${id}`
     }}> */}
      <Button
        onClick={handleModal}
        style={{
          position: "relative",
          left: "2px",
          bottom: "10px",
        }}
        color="success"
      >
        AddMoney
      </Button>
      
      {/* { </Link>} */}
      <Link to={{
       pathname: `/admin/redeem/${id}`
     }}>
      <Button
        
        style={{
          position: "relative",
          left: "2px",
          bottom: "10px",
        }}
        color="info"
      >
        REDEEM REQUEST
      </Button>
      </Link>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  {" "}
                  <b>DESCRIPTION</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  {" "}
                  <b>AMOUNT</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>TYPE</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>DATE</b>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ border: "1px solid black" }}>
                <TableSortLabel>
                  <b>TIME</b>
                </TableSortLabel>
              </TableCell>
             
              {/* {
                <TableCell sx={{ border: "1px solid black" }}>
                  <TableSortLabel>
                    <b>REDEEM</b>
                  </TableSortLabel>
                </TableCell>
               } */}
            </TableRow>
          </TableHead>
          <TableBody>
            {transection.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: "1px solid black" }}>
                  {item.description ? item.description : "not available"}
                </TableCell>
                <TableCell sx={{ border: "1px solid black" }}>
                  {item.amount}
                </TableCell>
                <TableCell sx={{ border: "1px solid black" }}>
                  {item.type}
                </TableCell>
                <TableCell sx={{ border: "1px solid black" }}>
                  {new Date(item.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ border: "1px solid black" }}>
                  {new Date(item.created_at).toLocaleTimeString()}
                </TableCell>
                



                
                {/* <TableCell sx={{ border: "1px solid black" }}>
                  <Button
                    onClick={() => handleSubmit(item)}
                    style={{
                      position: "relative",
                      left: "2px",
                      bottom: "10px",
                    }}
                    color="success"
                  >
                    GET
                  </Button>
                </TableCell> */}
            


              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WalletManagement;
