import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Loader from "components/Loader";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import { MultiSelect } from "react-multi-select-component";
import Button from "components/CustomButtons/Button.js";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
class BookOnlineOrders extends Component {
  state = {
    notifyStatus: false,
    notifyCollect: false,
    repButton: false,
    not: false,
    secondaryCategories: [],
    status: "",
    emailMess: "",
    statLoading: false,
    mail: false,
    pKey: "",
    student: "",
    osType: "",
    mediaType: "",
    per_page: "10",
    viewValidity: "",
    options: [],
    ftofbatches: [],
    email: "",
    orders: ["Order Placed", "Shipped", "Pending"],
    password: "",
    trackingNo: "",
    type: "Send Written Message",
    ordType: "Order Placed",
    act: 0,
    collecting: 0,
    active: 1,
    userSearch: false,
    frmDate: "",
    toDate: "",
    product: "",
    address: "",
    logic: "",
    allStrategies: [],
    strategies: [],
    previousStrategies: [],
    remainingStrategies: [],
    filteredStrategies: [],
    btnIdArray: [],
    referenceCodes: [],
    notification: false,
    newOrder: false,
    dateWise: false,
    delModal: false,
    logDetail: false,
    delLoading: false,
    notifyDelete: false,
    pincode: "",
    ord: true,
    logistics: [],
    visible: false,
    loading: false,
    courierCom: "",
    tracLink: "",
    proDetail: false,
    comment: false,
    notifyNote: false,
    searchOrderId: "",
    notes: "",
    delStatus: "",
    language: "",
    price: "",
    rowId: "",
    city: "",
    state: "",
    category: "Books",
    mobile: 0,
    contact: "",
    mess: false,
    print: false,
    printView: true,
    empty: false,
    showStrategy: false,
    ftofbatch: false,
    mailSent: false,
    mailFail: false,
    payStatus: "",
    message: "",
    shippingDate: "",
    fromInc: 1,
    toInc: 5,
    faculties: [],
    products: [],
    filteredBtnIdArray: [],
    types: [],
    userData: {},
    addedStrategies: [],
    notaddedStrategies: [],
    notAddedStrats: [],
    ftof_key: "",
    payment_status: "",
    search_by: "",
    ftofbatchId: "",
    fbatchName: "",
    selectedBatchId: "",
    showFbatchName: false,
    userId: "",
    productId: "",
    orderId: "",
    testModal: false,
    notifyTest: false,
    showPermissionModal: false,
    testSeries: [],
    showTestSeries: false,
    allotedTestSeries: [],
    notallotedTestSeries: [],
    test: [],
    test_seriesId: [],
    showcourseAllotMessage: false,
    courseAllotMessage: "",
    extensions: [],
    extension: false,
    selectedExtension: "",
    notifyExt: false,
    expiryDate: "",
    extPrice: "",
    courses: [],
    extOrders: false,
    extensionOrders: [],
    notExpiredCourse: false,
  };
  openModal() {
    this.setState({
      visible: true,
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      logDetail: false,
      import: false,
      print: false,
      showStrategy: false,
      ftofbatch: false,
      testModal: false,
      mail: false,
      mess: false,
      delModal: false,
      proDetail: false,
      comment: false,
      notaddedStrategies: [],
    });
  }
  componentDidMount() {
    // let category = localStorage.getItem("category");
    // // axios.get("https://admin.akclasses.in/api/faculty")
    // axios.get(Constants.getUrls.faculty).then((resp) => {
    //   // console.log(resp.data);
    //   this.setState({
    //     faculties: resp.data,
    //   });
    // });
    // // axios.get("https://admin.akclasses.in/api/get_type")
    // axios.get(Constants.getUrls.get_type).then((resp) => {
    //   //   console.log(resp.data);
    //   this.setState({
    //     types: resp.data,
    //   });
    // });
    // axios
    //   .get(
    //     "https://admin.akclasses.in/api/courses?type=COURSES&per_page=100&is_featured=&attempt_id=&category_id=&level_id=&stream_id=&subject_id=&lang="
    //   )
    //   .then((resp) => {
    //     console.log(resp);
    //     this.setState({
    //       courses: resp.data.data,
    //     });
    //   });
    // // axios.get("https://admin.akclasses.in/api/strategy")
    // axios.get(Constants.getUrls.strategy).then((resp) => {
    //   // console.log(resp.data);
    //   this.setState({
    //     allStrategies: resp.data,
    //   });
    // });
    // axios
    //   // .get("https://admin.akclasses.in/api/course_with_variant")
    //   .get(Constants.getUrls.course_with_variant)
    //   .then((resp) => {
    //     // console.log(resp.data);
    //     this.setState({
    //       products: resp.data,
    //     });
    //   });
    // this.setState({ category }, () => {
    //   axios
    //     .get(
    //       // "https://admin.akclasses.in/api/new_order?order_type=" +
    //       Constants.getUrls.new_order +
    //         "?order_type=" +
    //         this.state.category +
    //         "&per_page=" +
    //         this.state.per_page
    //     )
    //     .then((resp) => {
    //       // console.log(resp.data);
    //       let btnIdArray = [];
    //       for (let i = 1; i <= resp.data.last_page; i++) {
    //         btnIdArray.push(i);
    //       }
    //       this.setState(
    //         {
    //           btnIdArray,
    //           empty: false,
    //           newOrder: true,
    //           userSearch: false,
    //           dateWise: false,
    //           ord: false,
    //           secondaryCategories: resp.data.data,
    //           total: resp.data.total,
    //           from: resp.data.from,
    //           to: resp.data.to,
    //         },
    //         () => {
    //           console.log(this.state.secondaryCategories);
    //           let filteredBtnIdArray = this.state.btnIdArray.filter(
    //             (v) => v >= this.state.fromInc && v <= this.state.toInc
    //           );
    //           this.setState({
    //             filteredBtnIdArray,
    //           });
    //         }
    //       );
    //     });
    // });
    // axios.get("https://admin.akclasses.in/api/logistic")
    axios.get(Constants.getUrls.logistic).then((resp) => {
      //console.log(resp.data.data);
      this.setState({
        logistics: resp.data.data,
      });
    });
    // axios
    //   // .get("https://admin.akclasses.in/api/couponCodes?type=REFERENCE_CODE")
    //   .get(Constants.getUrls.couponCodes + "?type=REFERENCE_CODE")
    //   .then((resp) => {
    //     // console.log(resp.data);
    //     this.setState({
    //       referenceCodes: resp.data,
    //     });
    //   });
    // this.getTestSeries();
    this.getAllOrder();
  }
  addExtension = (orderId, extId, extDate, extRs) => {
    // console.log(orderId,extId,extDate,extRs);
    this.setState({ loading: true });
    let payLoad = {
      order_id: orderId,
      extension_course_id: extId,
      expiry_date: extDate,
      price: extRs,
    };
    console.log(payLoad);
    axios
      .post(Constants.postUrls.addExtensionOnlineOrder, payLoad)
      .then((resp) => {
        console.log(resp);
        this.setState({
          notifyExt: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  getAllOrder = () => {
    axios
      .get(
        // "https://admin.akclasses.in/api/new_order?order_type=" +
        Constants.getUrls.new_order +
          "?order_type=" +
          this.state.category +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            empty: false,
            newOrder: true,
            userSearch: false,
            dateWise: false,
            ord: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  comparer = (otherArray) => {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.id == current.id;
        }).length == 0
      );
    };
  };
  findFaculty = (data) => {
    let obj = this.state.faculties.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  findCourse = (id) => {
    let obj = this.state.products.find((v) => v.course.ID === id);
    // console.log(obj);
    if (obj !== undefined) {
      return obj.course.faculty.length > 1
        ? "AK Team"
        : this.findFaculty(obj.course.faculty);
    }
  };

  downloadInvoice = (id) => {
    axios.get(Constants.getUrls.downloadOnlinePdf + id).then((resp) => {
      console.log(resp);
    });
  };

  sendEmail = () => {
    this.setState({ loading: true });
    let strategies = [];
    this.state.notAddedStrats.forEach((v) => {
      strategies.push(v.value);
    });
    axios
      // .post("https://admin.akclasses.in/api/addUser_strategy", {
      .post(Constants.postUrls.addUser_strategy, {
        order_id: this.state.rowId,
        online: "",
        strIds: strategies,
      })
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.status === "failed") {
          this.setState({
            loading: false,
            mailFail: true,
            strMessage: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              mailFail: false,
              strMessage: "",
            });
          }, 4000);
        } else {
          this.setState({
            loading: false,
            mailSent: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      });
  };
  handleCollect = (id) => {
    this.setState({ collecting: id });
    axios
      .post("https://admin.akclasses.in/api/paymentStatus", { order_id: id })
      .then(() => {
        //console.log(res);
        this.setState({
          notifyCollect: true,
        });
        setTimeout(() => {
          // this.getCategories();
          this.setState({ notifyCollect: false, collecting: 0 });
          window.location.reload();
        }, 1000);
      });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        if (this.state.ordType === "Order Placed") {
          axios
            .get(
              // "https://admin.akclasses.in/api/new_order?order_type=" +
              Constants.getUrls.new_order +
                "?order_type=" +
                this.state.category +
                "&per_page=" +
                this.state.per_page
            )
            .then((resp) => {
              //console.log(resp.data);
              let btnIdArray = [];
              for (let i = 1; i <= resp.data.last_page; i++) {
                btnIdArray.push(i);
              }
              this.setState(
                {
                  btnIdArray,
                  empty: false,
                  newOrder: true,
                  userSearch: false,
                  dateWise: false,
                  ord: false,
                  secondaryCategories: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to,
                },
                () => {
                  let filteredBtnIdArray = this.state.btnIdArray.filter(
                    (v) => v >= this.state.fromInc && v <= this.state.toInc
                  );
                  this.setState({
                    filteredBtnIdArray,
                  });
                }
              );
            });
        } else if (this.state.ordType === "Pending") {
          axios
            .get(
              "https://admin.akclasses.in/api/paymentStatus?per_page=" +
                this.state.per_page
            )
            .then((resp) => {
              //console.log(resp.data);
              let btnIdArray = [];
              for (let i = 1; i <= resp.data.last_page; i++) {
                btnIdArray.push(i);
              }
              this.setState(
                {
                  btnIdArray,
                  empty: false,
                  newOrder: false,
                  userSearch: false,
                  dateWise: false,
                  ord: false,
                  secondaryCategories: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to,
                },
                () => {
                  let filteredBtnIdArray = this.state.btnIdArray.filter(
                    (v) => v >= this.state.fromInc && v <= this.state.toInc
                  );
                  this.setState({
                    filteredBtnIdArray,
                  });
                }
              );
            });
        } else {
          axios
            .get(
              "https://admin.akclasses.in/api/online_order_get?status=" +
                this.state.ordType +
                "&order_type=" +
                this.state.category +
                "&per_page=" +
                this.state.per_page
            )
            .then((resp) => {
              // console.log(resp);
              let btnIdArray = [];
              for (let i = 1; i <= resp.data.last_page; i++) {
                btnIdArray.push(i);
              }
              this.setState(
                {
                  btnIdArray,
                  empty: false,
                  newOrder: false,
                  dateWise: false,
                  ord: true,
                  secondaryCategories: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to,
                },
                () => {
                  let filteredBtnIdArray = this.state.btnIdArray.filter(
                    (v) => v >= this.state.fromInc && v <= this.state.toInc
                  );
                  this.setState({
                    filteredBtnIdArray,
                  });
                }
              );
            });
        }
      }
    );
  };
  handleCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.category === "Extend_Validity") {
          this.setState({
            orders: [
              "Order Placed",
              "Extended",
              "Shipped",
              "Cancelled",
              "Pending",
            ],
          });
        } else {
          this.setState({
            orders: [
              "Order Placed",
              "Activated",
              "Shipped",
              "Cancelled",
              "Pending",
            ],
          });
        }
        axios
          .get(
            // "https://admin.akclasses.in/api/new_order?order_type=" +
            Constants.getUrls.new_order +
              "?order_type=" +
              this.state.category +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                empty: false,
                newOrder: true,
                userSearch: false,
                dateWise: false,
                ord: false,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_online_order_datevise?order_type=" +
          this.state.category +
          "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&per_page=" +
          this.state.per_page +
          "&status=" +
          this.state.ordType
      )
      .then((resp) => {
        //console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            empty: false,
            repButton: true,
            ord: false,
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            dateWise: true,
            newOrder: false,
            userSearch: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.logic);
      }
    );
  };

  handleSelect = (e, ordId, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.delStatus);
        axios
          .post(
            // "https://admin.akclasses.in/api/orderstatus?ordId=" +
            Constants.postUrls.changeOnlineOrderStatus +
              "?ordId=" +
              ordId +
              "&status=" +
              this.state.delStatus +
              "&id=" +
              id
          )
          .then((res) => {
            console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({
                notifyStatus: false,
              });
            }, 2000);
          });
      }
    );
  };

  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/online_order_get?status=" +
        this.state.ordType +
        "&order_type=" +
        this.state.category +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.newOrder
      ? // ? "https://admin.akclasses.in/api/new_order?order_type=" +
        Constants.getUrls.new_order +
        "?order_type=" +
        this.state.category +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.userSearch
      ? "https://admin.akclasses.in/api/online/show_order?name=" +
        this.state.searchOrderId +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.dateWise
      ? "https://admin.akclasses.in/api/get_online_order_datevise?order_type=" +
        this.state.category +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/paymentStatus?per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleFtoFBatch = (sec, fBatchId) => {
    this.setState(
      {
        ftofbatchId: sec.ftofbatch,
        product: sec.course ? sec.course.TITLE : "",
        orderId: sec.ORDER_ID,
        rowId: sec.ID,
        osType: sec.variant ? sec.variant.os_type.os_type : "",
        mediaType: sec.variant ? sec.variant.media_type.media_type : "",
        viewValidity: sec.variant
          ? sec.variant.view_and_validity.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
        language: sec.course ? sec.course.language : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
        pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
        ftof_key: sec?.ftof_key,
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        logic: sec.logistic ? sec.logistic.id : "",
        student: sec.FIRST_NAME
          ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
            " " +
            (sec.LAST_NAME ? "" : "")
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? "" : "")
          : "",
        password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        tracLink: sec.logistic ? sec.logistic.tracking_link : "",
        courierCom: sec.logistic ? sec.logistic.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        state: sec.STATE ? sec.STATE : "",
        userData: sec.user,
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
      },
      () => {
        console.log(this.state.ftofbatchId);
        if (this.state.ftofbatchId !== null) {
          this.setState(
            {
              showFbatchName: true,
            },
            () => {
              if (this.state.showFbatchName === true) {
                axios
                  .get(
                    Constants.getUrls.getSingleFbatch + this.state.ftofbatchId
                  )
                  .then((res) => {
                    console.log(res);
                    this.setState({
                      fbatchName: res.data.data.batch_name,
                    });
                  });
              } else {
                this.setState({
                  fbatchName: "Not Found",
                });
              }
            }
          );
        } else {
          this.setState(
            {
              showFbatchName: false,
            },
            () => {
              if (this.state.showFbatchName === false) {
                this.setState({
                  fbatchName: "Not Found",
                });
              }
            }
          );
        }
        axios.get(Constants.getUrls.faceToFaceBatch).then((resp) => {
          console.log(resp);
          this.setState({
            ftofbatches: resp.data.data,
          });
        });
      }
    );
    this.openModal();
  };

  getTestSeries = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/courses?type=Test_Series&per_page=10"
      )
      .then((resp) => {
        console.log(resp);
        // return;
        resp.data.data.map((val) => {
          console.log(val);
          this.state.testSeries.push({
            label: val?.TITLE,
            value: val?.ID,
          });
          this.setState(
            {
              testSeries: this.state.testSeries,
            },
            () => {
              console.log(this.state.testSeries);
            }
          );
        });
      });
  };

  handleTestSeries = (sec) => {
    console.log(sec);
    const payload = {
      user_id: sec.user.ID,
      order_no: sec.ORDER_ID,
      course_id: sec.course.ID,
      order_type: "Online",
    };
    axios
      .post(Constants.postUrls.getAllotNotAllotTest, payload)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status === "success") {
          this.setState(
            {
              testModal: true,
              showTestSeries: false,
              allotedTestSeries: resp.data.allotedTitles,
              notallotedTestSeries: resp.data.notAllotedTitles,
              userId: sec.user.ID,
              productId: sec.course.ID,
              orderId: sec.ORDER_ID,
              testSeries: [],
            },
            () => {
              this.state.notallotedTestSeries.map((val) => {
                console.log(val);
                this.state.testSeries.push({
                  label: val?.title,
                  value: val?.id,
                });
                this.setState(
                  {
                    testSeries: this.state.testSeries,
                  },
                  () => {
                    console.log(this.state.testSeries);
                  }
                );
              });
            }
          );
        }
        this.setState({
          // testModal: true,
          showcourseAllotMessage: true,
          courseAllotMessage: resp.data.message,
        });
      });
    this.openModal();
  };

  saveTestSeries = () => {
    console.log(this.state.productId, this.state.orderId, this.state.userId);
    const payload = {
      user_id: this.state.userId,
      course_id: this.state.productId,
      order_no: this.state.orderId,
      order_type: "Online",
    };
    axios.post(Constants.postUrls.allotTestSeries, payload).then((resp) => {
      console.log(resp);
      if (resp.data.status == "success") {
        this.setState(
          {
            notifyTest: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                visible: false,
                notifyTest: false,
              });
            }, 2000);
          }
        );
      }
    });
  };

  handleModal = (sec) => {
    axios
      .get(
        "https://admin.akclasses.in/api/getExtensionOrders?orderId=" +
          sec.ORDER_ID
      )
      .then((resp) => {
        console.log(resp.data);
        console.log(resp.data.data.length);
        this.setState({
          extensionOrders: resp.data.data,
        });
      });
    this.setState(
      {
        product: sec.course ? sec.course.TITLE : "",
        orderId: sec.ORDER_ID,
        rowId: sec.ID,
        osType: sec.variant ? sec.variant.os_type.os_type : "",
        mediaType: sec.variant ? sec.variant.media_type.media_type : "",
        viewValidity: sec.variant
          ? sec.variant.view_and_validity.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
        language: sec.course ? sec.course.language : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
        pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
        ftof_key: sec?.ftof_key,
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        logic: sec.logistic ? sec.logistic.id : "",
        student: sec.FIRST_NAME
          ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
            " " +
            (sec.LAST_NAME ? "" : "")
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? "" : "")
          : "",
        password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        tracLink: sec.logistic ? sec.logistic.tracking_link : "",
        courierCom: sec.logistic ? sec.logistic.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        state: sec.STATE ? sec.STATE : "",
        userData: sec.user,
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
      },
      () => {
        if (this.state.delStatus === "Shipped") {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
              "Tracking Details",
            ],
          });
        } else {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
            ],
          });
        }
      }
    );
    this.openModal();
  };

  allotFToFBatch = (selectedid) => {
    console.log(selectedid);
    this.setState({ loading: true });
    let payLoad = {
      ftofbatch: selectedid,
    };
    axios
      .post(
        Constants.postUrls.updateFtoFBatchonline + this.state.rowId,
        payLoad
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          // notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  sendMessage = () => {
    this.setState({ loading: true });
    let payLoad = {
      message:
        this.state.type === "Send Written Message"
          ? this.state.message
          : this.state.type === "Send Login Credentials"
          ? "Hey," +
            this.state.student +
            "\nYour Email Id is " +
            this.state.email +
            " and Password is " +
            this.state.password
          : this.state.type === "Send Product Key"
          ? "Hey," +
            this.state.student +
            "\nYour Product Key is " +
            this.state.pKey
          : "Hey," +
            this.state.student +
            "\nCourier Company is " +
            this.state.courierCom +
            ",Tracking Id is " +
            this.state.trackingNo +
            " and Trackin Link is " +
            this.state.tracLink,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/user_online_ord_sms/" +
          this.state.rowId,
        payLoad
      )
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  importUser = () => {
    this.setState({ loading: true });
    let payLoad = {
      type: this.state.type,
      name: this.state.student,
      email: this.state.email,
      mobile: this.state.mobile,
      key: this.state.pKey,
      // password: this.state.password,
      date: this.state.orderDate,
    };
    axios.post("https://admin.akclasses.in/api/user_sms", payLoad).then(() => {
      // console.log(resp);
      this.setState({
        notifyNote: true,
        loading: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };
  sendMail = () => {
    this.setState({ loading: true });
    let payLoad = {
      messages: this.state.emailMess,
      name: this.state.student,
      email: this.state.email,
    };
    axios
      .post("https://admin.akclasses.in/api/email_user", payLoad)
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    let payload = {
      order_id: id,
      type: "online",
    };
    axios
      .post("https://admin.akclasses.in/api/order/delete", payload)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    axios
      .post(
        // "https://admin.akclasses.in/api/online/orderNote/" + this.state.rowId,
        Constants.postUrls.addShippingDetails + this.state.rowId,
        {
          note: this.state.notes,
        }
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notifyNote: false });
          window.location.reload();
        }, 2000);
      });
  };

  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      prod_key: this.state.pKey,
      logistic: this.state.logic,
    };
    axios
      .post(
        // "https://admin.akclasses.in/api/add_onlineshiping/" + this.state.rowId,
        Constants.postUrls.addOnlineShopping + this.state.rowId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          not: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  handleOrders = (btn, index) => {
    if (btn === "Order Placed") {
      axios
        .get(
          // "https://admin.akclasses.in/api/new_order?order_type=" +
          Constants.getUrls.new_order +
            "?order_type=" +
            this.state.category +
            "&per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          // console.log(resp.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              fromInc: 1,
              active: 1,
              toInc: 5,
              empty: false,
              newOrder: true,
              ord: false,
              dateWise: false,
              userSearch: false,
              ordType: btn,
              act: index,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    } else if (btn === "Pending") {
      axios
        .get(
          "https://admin.akclasses.in/api/paymentStatus?per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          // console.log(resp.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              fromInc: 1,
              active: 1,
              toInc: 5,
              empty: false,
              newOrder: false,
              ord: false,
              dateWise: false,
              userSearch: false,
              ordType: btn,
              act: index,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    } else {
      let value = btn === "Activated" || btn === "Extended" ? "Packaged" : btn;
      axios
        .get(
          // "https://admin.akclasses.in/api/online_order_get?status=" +
          Constants.getUrls.getOnlineOrders +
            "?status=" +
            value +
            "&order_type=" +
            this.state.category +
            "&per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          // console.log(resp.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              empty: false,
              fromInc: 1,
              active: 1,
              toInc: 5,
              btnIdArray,
              ordType: value,
              ord: true,
              userSearch: false,
              newOrder: false,
              dateWise: false,
              act: index,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    }
  };
  searchUser = () => {
    let payload;
    if (this.state.search_by === "user_name") {
      let payload = {
        name: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    if (this.state.search_by === "email") {
      let payload = {
        email: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    if (this.state.search_by === "mobile_no") {
      let payload = {
        mobile_no: this.state.searchOrderId,
        per_page: 100,
      };
      this.searchOrderId(payload);
    }
    // if (this.state.search_by === "batch_id") {
    //   let payload = {
    //     batch_id: this.state.user,
    //   };
    //   this.searchOrderId(payload);
    // }
  };
  searchOrderId = (e) => {
    let payload = {
      search: this.state.searchOrderId,
      per_page: "",
    };
    console.log(payload);
    axios
      .post("https://admin.akclasses.in/api/online/show_order", payload)
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            userSearch: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            ord: false,
            newOrder: false,
            dateWise: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
        // Object.size = obj => {
        //   let size = 0,
        //     key;
        //   for (key in obj) {
        //     if (obj.hasOwnProperty(key)) size++;
        //   }
        //   return size;
        // };

        // // Get the size of an object
        // let size = Object.size(resp.data);
        // if (size > 0) {
        //   let array1 = [];
        //   array1.push(resp.data);
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true,
        //     secondaryCategories: array1
        //   });
        // } else {
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true,
        //     secondaryCategories: []
        //   });
        // }
      });
  };

  findReferenceCode = (id) => {
    let obj = this.state.referenceCodes.find((v) => v.REF_ORDER_NO === id);
    return obj === undefined ? "NA" : obj.CODE;
  };
  handlePaymentStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          order_id: id,
          payment_status: this.state.payment_status,
        };
        axios
          .post("https://admin.akclasses.in/api/update/payment-status", payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              // window.location.reload();
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getAllOrder();
                }
              );
            }, 1000);
          });
      }
    );
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Shipping Added Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Types
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.category}
                      inputProps={{
                        name: "category",
                        id: "simple-select",
                        onChange: (e) => {
                          this.handleCategory(e);
                        },
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="Books"
                      >
                        Books
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <br />
                <label
                  style={{
                    marginLeft: "12px",
                    color: "black",
                    fontSize: "17px",
                  }}
                >
                  Filter The Orders
                </label>
                <br />
                <br />
                <label
                  style={{ color: "black", position: "relative", left: "10px" }}
                >
                  By Attempt
                </label>
                <br />
                {this.state.orders.map((btn, index) => (
                  <Button
                    key={index}
                    id={btn}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    color={
                      this.state.act === index && btn === "Pending"
                        ? "danger"
                        : this.state.act === index
                        ? "danger"
                        : btn === "Pending"
                        ? "danger"
                        : "info"
                    }
                    onClick={() => {
                      this.handleOrders(btn, index);
                    }}
                  >
                    {btn}
                  </Button>
                ))}
                <br />
                <GridContainer style={{ position: "relative", left: "10px" }}>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <label
                      style={{
                        color: "black",
                      }}
                    >
                      By Date
                    </label>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              frmDate: year + "/" + month + "/" + day,
                            },
                            () => {
                              //console.log(this.state.frmDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "From Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              toDate: year + "/" + month + "/" + day,
                            },
                            () => {
                              //console.log(this.state.toDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "To Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "13px",
                        right: "20px",
                      }}
                      onClick={this.searchReport}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                  {this.state.repButton ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <a
                        href={
                          "https://admin.akclasses.in/api/order_excel_report?order_type=" +
                          this.state.category +
                          "&frmdate=" +
                          this.state.frmDate +
                          "&todate=" +
                          this.state.toDate +
                          "&per_page=" +
                          this.state.per_page +
                          "&status=" +
                          this.state.ordType
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "60px",
                          }}
                          onClick={() => {
                            this.setState({ repButton: false });
                          }}
                          color="danger"
                        >
                          Download
                        </Button>
                      </a>
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer style={{ position: "relative", left: "10px" }}>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    {/* <FormControl
                      style={{
                        // position: "absolute",
                        width: "120px",
                      }}
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Search by
                      </InputLabel>
                      <Select
                        // s
                        // key={1}
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.search_by}
                        inputProps={{
                          name: "search_by",
                          id: "simple-select",
                          onChange: (e) => {
                            this.setState(
                              {
                                [e.target.name]: e.target.value,
                              },
                              () => {
                                console.log(this.state.search_by);
                              }
                            );
                          },
                        }}
                      >
                        <MenuItem value="user_name">User Name</MenuItem>
                        <MenuItem value="email">Email Id</MenuItem>
                        <MenuItem value="mobile_no">Mobile Number</MenuItem>
                      </Select>
                    </FormControl> */}
                    <CustomInput
                      style={{
                        marginLeft: "30px",
                      }}
                      labelText="Search..."
                      id="float"
                      inputProps={{
                        required: true,
                        value: this.state.searchOrderId,
                        onChange: (e) =>
                          this.setState({ searchOrderId: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "35px",
                        right: "15px",
                      }}
                      onClick={this.searchOrderId}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                </GridContainer>
                <h3>Books Online Orders</h3>
                <Table
                  tableHead={
                    this.state.ordType === "Pending"
                      ? [
                          "Sr No.",

                          <div key={1} style={{ width: "70px" }}>
                            Order Date
                          </div>,
                          <div key={1} style={{ width: "300px" }}>
                            Product Details
                          </div>,

                          "Student Details",

                          // "Reference By",

                          "Collect",
                          "Actions",
                        ]
                      : this.state.category === "Extend_Validity" ||
                        this.state.category === "Test_Series" ||
                        this.state.category === "Books"
                      ? [
                          "Sr No.",

                          <div key={1} style={{ width: "100px" }}>
                            Order Date
                          </div>,
                          <div key={1} style={{ width: "300px" }}>
                            Product Details
                          </div>,

                          "Student Details",
                          // "Order Status",
                          // "Reference By",

                          // "Reference Code",
                          "Shipping Details",
                          "Extension",
                          "Actions",
                        ]
                      : [
                          "Sr No.",
                          "Order No",
                          "Batches",
                          <div key={1} style={{ width: "70px" }}>
                            Order Date
                          </div>,
                          <div key={1} style={{ width: "150px" }}>
                            Product Details
                          </div>,
                          "Faculty Details",
                          "Student Details",
                          // "Order Status",
                          // "Reference By",
                          <div key={1} style={{ width: "200px" }}>
                            Coupon Code
                          </div>,
                          "Discount Amount",
                          "Grand Total",
                          // "Product Key Extended",
                          // "Reference Code",
                          "Shipping Details",
                          "Extension",
                          "Actions",
                        ]
                  }
                  tableData={
                    this.state.ordType === "Pending"
                      ? this.state.secondaryCategories.map((sec, index) => [
                          [index + 1],

                          [
                            sec.ORDER_DATE.slice(8, 10) +
                              "-" +
                              sec.ORDER_DATE.slice(5, 7) +
                              "-" +
                              sec.ORDER_DATE.slice(0, 4),
                          ],
                          [
                            <div key={index}>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Order No.:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {sec.ORDER_ID}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Course:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.course ? sec.course.TITLE : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "1px",
                                  }}
                                >
                                  Batch:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {sec.course.course_batches &&
                                  sec.course.course_batches.length > 0
                                    ? sec.course.course_batches
                                        .map(
                                          (cbatch) =>
                                            cbatch?.batch?.batch_name || "NA"
                                        )
                                        .join(", ")
                                    : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Attempt:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {" "}
                                  {sec.course.course_batches &&
                                  sec.course.course_batches.length > 0
                                    ? sec.course.course_batches
                                        .map(
                                          (cbatch) =>
                                            cbatch?.batch?.attempt?.title ||
                                            "NA"
                                        )
                                        .join(", ")
                                    : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Faculty:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {this.findCourse(sec.product_id)}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Mode:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.order_from ? sec.order_from : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  MRP:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Discount:
                                </td>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      color: "red",
                                    }}
                                  >
                                    Coupon
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.COUPON_CODE || "No Data"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      color: "red",
                                    }}
                                  >
                                    Amount
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.coupon_discount_amount || "No Data"}
                                  </td>
                                </tr>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Amount Received
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.GRAND_TOTAL || "No Data"}
                                </td>
                              </tr>
                            </div>,

                            // sec.course ? sec.course.TITLE : "NA"
                          ],

                          [
                            <div key={index}>
                          
                            
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "25px",
                                    }}
                                  >
                                    Name:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "25px",
                                    }}
                                  >
                                    {sec.FIRST_NAME
                                      ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
                                        " " +
                                        (sec.LAST_NAME === "null" ? "" : "")
                                      : sec.user
                                      ? sec.user.FIRST_NAME
                                        ? sec.user.FIRST_NAME
                                        : ""
                                      : //   +
                                        // " " +
                                        // (sec.user.LAST_NAME === "null"
                                        //     ? "______"
                                        //     : sec.user.LAST_NAME)
                                        "NA"}
                                    ,
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    // fontWeight:'bold',
                                    color: "blue",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "28px",
                                    }}
                                  >
                                    {" "}
                                    Email:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.EMAIL
                                      ? sec.EMAIL
                                      : sec.user
                                      ? sec.user.email
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "12px",
                                    }}
                                  >
                                    {" "}
                                    Contact:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.CONTACT_NO
                                      ? sec.CONTACT_NO
                                      : sec.user
                                      ? sec.user.MOBILE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Address:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.ADDRESS ? (
                                      <div>
                                        {sec.ADDRESS}
                                        {sec.ADDRESS_2 && <br />}
                                        {sec.ADDRESS_2}
                                      </div>
                                    ) : sec.user ? (
                                      <div>
                                        {sec.user.ADDRESS_1}
                                        {sec.user.ADDRESS_2 && <br />}
                                        {sec.user.ADDRESS_2}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "29px",
                                    }}
                                  >
                                    State:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {" "}
                                    {sec.STATE
                                      ? sec.STATE
                                      : sec.user
                                      ? sec.user.STATE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "38px",
                                    }}
                                  >
                                    City:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {" "}
                                    {sec.CITY
                                      ? sec.CITY
                                      : sec.user
                                      ? sec.user.CITY
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "9px",
                                    }}
                                  >
                                    Pincode:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.PINCODE
                                      ? sec.PINCODE
                                      : sec.user
                                      ? sec.user.PINCODE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                {/* <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                PW :{" "}
                                {sec.user
                                  ? sec.user.DE_PASSWORD
                                    ? sec.user.DE_PASSWORD
                                    : "NA"
                                  : "NA"}
                              </span> */}
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Referral Code :{" "}
                                    {this.findReferenceCode(sec.ORDER_ID)}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Payment Staus: {sec?.PAYMENT_STATUS}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <td
                                  //  style={{
                                  //   // backgroundColor: sec?.user?.TYPE === "Premium" ? "green" : "red",
                                  //   // backgroundColor:"green",
                                  //   color: "white"
                                  // }}
                                  >
                                    User Type: {sec?.user?.TYPE}
                                  </td>
                                </span>
                              </tr>
                            </div>,
                          ],
                          // [
                          //   <Select
                          //     key={index}
                          //     MenuProps={{
                          //       className: classes.selectMenu,
                          //     }}
                          //     classes={{
                          //       select: classes.select,
                          //     }}
                          //     value={sec.PAYMENT_STATUS}
                          //     inputProps={{
                          //       name: "payment_status",
                          //       id: "simple-select",
                          //       onChange: (e) => {
                          //         this.handlePaymentStatus(e, sec.ID);
                          //       },
                          //     }}
                          //   >
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Pending"
                          //     >
                          //       Pending
                          //     </MenuItem>
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Collected"
                          //     >
                          //       Collected
                          //     </MenuItem>
                          //   </Select>,
                          // ],
                          // [
                          //   sec.order_from
                          //     ? sec.order_from === "ak"
                          //       ? "Ak Classes"
                          //       : "Vsmart"
                          //     : "NA",
                          // ],

                          [
                            <Button
                              key={index}
                              onClick={() => {
                                this.handleCollect(sec.ORDER_ID);
                              }}
                              className="btn-round"
                              color="success"
                            >
                              {this.state.collecting === sec.ORDER_ID
                                ? "Collecting..."
                                : this.state.notifyCollect
                                ? "Collected"
                                : "Yes"}
                            </Button>,
                          ],
                          [
                            <Button
                              key={index}
                              color="danger"
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extension:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? false
                                      : true,
                                  notExpiredCourse:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? true
                                      : false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              Add Extension
                            </Button>,
                            <Button
                              key={index}
                              color={
                                sec.extension_order &&
                                sec.extension_order.length > 0
                                  ? "success"
                                  : "secondary"
                              }
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extOrders: true,
                                  extension: false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              View Extension
                            </Button>,
                          ],
                          [
                            <span key={1}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    logDetail: false,
                                    print: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: false,
                                    mail: false,
                                    mess: false,
                                    delModal: false,
                                    proDetail: true,
                                    comment: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe417;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: true,
                                    delModal: false,
                                    mess: false,
                                    mail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    print: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0b9;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mail: false,
                                    comment: false,
                                    logDetail: false,
                                    mess: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: false,
                                    delModal: false,
                                    print: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8ad;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    comment: false,
                                    print: false,
                                    mail: false,
                                    mess: false,
                                    import: false,
                                    logDetail: false,
                                    delModal: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe5c9;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: true,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="fab">&#xf39f;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    addShip: false,
                                    import: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mail: true,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0be;</i>
                              </span>
                              <br />
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    statLoading: true,
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    import: false,
                                    logDetail: false,
                                    showStrategy: true,
                                    ftofbatch: false,
                                    testModal: false,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                  axios
                                    .get(
                                      // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                      Constants.getUrls.getUserStrat +
                                        "?ordId=" +
                                        sec.ID +
                                        "&user_id=" +
                                        sec.user.ID +
                                        "&course_id=" +
                                        sec.course.ID
                                    )
                                    .then((resp) => {
                                      console.log(resp);
                                      if (resp.data.status === "success") {
                                        resp.data.notAddedData.forEach((v) => {
                                          this.state.notaddedStrategies.push({
                                            label:
                                              v.strategy.course.TITLE +
                                              " (" +
                                              v.strategy.duration +
                                              " days, " +
                                              v.strategy.strategy_type +
                                              " , " +
                                              v.strategy.type +
                                              ")",
                                            value: v.strategy.id,
                                          });
                                        });

                                        this.setState({
                                          statLoading: false,
                                          addedStrategies:
                                            resp.data.addedData.length > 0
                                              ? resp.data.addedData
                                              : [],
                                          // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                        });
                                      } else {
                                        this.setState({
                                          statLoading: false,
                                          addedStrategies: [],
                                          notaddedStrategies: [],
                                        });
                                      }
                                    });
                                }}
                              >
                                <i
                                  className="fas fa-lightbulb"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: true,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i
                                  title="Import User"
                                  className="fas fa-file-import"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                            </span>,
                            <span
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                position: "relative",
                                bottom: "4px",
                              }}
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  mess: true,
                                  comment: false,
                                  showStrategy: false,
                                  ftofbatch: false,
                                  testModal: false,
                                  logDetail: false,
                                  import: false,
                                  mail: false,
                                  addShip: false,
                                  print: false,
                                  delModal: false,
                                  proDetail: false,
                                });
                              }}
                            >
                              <i className="fab">&#xf39f;</i>
                            </span>,
                          ],
                        ])
                      : this.state.category === "Extend_Validity" ||
                        this.state.category === "Test_Series" ||
                        this.state.category === "Books"
                      ? this.state.secondaryCategories.map((sec, index) => [
                          [index + 1],
                          [
                            sec.ORDER_DATE.slice(8, 10) +
                              "-" +
                              sec.ORDER_DATE.slice(5, 7) +
                              "-" +
                              sec.ORDER_DATE.slice(0, 4),
                          ],
                          [
                            <div key={index}>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Order No.:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {sec.ORDER_ID}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Course:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.course ? sec.course.TITLE : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "1px",
                                  }}
                                >
                                  Batch:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {sec.course.course_batches &&
                                  sec.course.course_batches.length > 0
                                    ? sec.course.course_batches
                                        .map(
                                          (cbatch) =>
                                            cbatch?.batch?.batch_name || "NA"
                                        )
                                        .join(", ")
                                    : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Attempt:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {" "}
                                  {sec.course.course_batches &&
                                  sec.course.course_batches.length > 0
                                    ? sec.course.course_batches
                                        .map(
                                          (cbatch) =>
                                            cbatch?.batch?.attempt?.title ||
                                            "NA"
                                        )
                                        .join(", ")
                                    : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  Faculty:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {this.findCourse(sec.product_id)}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Mode:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.order_from ? sec.order_from : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  MRP:
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Discount:
                                </td>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      color: "red",
                                    }}
                                  >
                                    Coupon
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.COUPON_CODE || "No Data"}
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #959595",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      color: "red",
                                    }}
                                  >
                                    Amount
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.coupon_discount_amount || "No Data"}
                                  </td>
                                </tr>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  Amount Received
                                </td>
                                <td
                                  style={{
                                    borderLeft: "1px solid #959595",
                                    textAlign: "left",
                                    padding: "8px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {sec.GRAND_TOTAL || "No Data"}
                                </td>
                              </tr>
                            </div>,

                            // sec.course ? sec.course.TITLE : "NA"
                          ],

                          [
                            <div key={index}>
                    
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "25px",
                                    }}
                                  >
                                    Name:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "25px",
                                    }}
                                  >
                                    {sec.FIRST_NAME
                                      ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
                                        " " +
                                        (sec.LAST_NAME === "null" ? "" : "")
                                      : sec.user
                                      ? sec.user.FIRST_NAME
                                        ? sec.user.FIRST_NAME
                                        : ""
                                      : //   +
                                        // " " +
                                        // (sec.user.LAST_NAME === "null"
                                        //     ? "______"
                                        //     : sec.user.LAST_NAME)
                                        "NA"}
                                    ,
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    // fontWeight:'bold',
                                    color: "blue",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "28px",
                                    }}
                                  >
                                    {" "}
                                    Email:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.EMAIL
                                      ? sec.EMAIL
                                      : sec.user
                                      ? sec.user.email
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "12px",
                                    }}
                                  >
                                    {" "}
                                    Contact:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.CONTACT_NO
                                      ? sec.CONTACT_NO
                                      : sec.user
                                      ? sec.user.MOBILE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Address:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.ADDRESS ? (
                                      <div>
                                        {sec.ADDRESS}
                                        {sec.ADDRESS_2 && <br />}
                                        {sec.ADDRESS_2}
                                      </div>
                                    ) : sec.user ? (
                                      <div>
                                        {sec.user.ADDRESS_1}
                                        {sec.user.ADDRESS_2 && <br />}
                                        {sec.user.ADDRESS_2}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "29px",
                                    }}
                                  >
                                    State:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {" "}
                                    {sec.STATE
                                      ? sec.STATE
                                      : sec.user
                                      ? sec.user.STATE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "38px",
                                    }}
                                  >
                                    City:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {" "}
                                    {sec.CITY
                                      ? sec.CITY
                                      : sec.user
                                      ? sec.user.CITY
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                      paddingRight: "9px",
                                    }}
                                  >
                                    Pincode:
                                  </td>
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {sec.PINCODE
                                      ? sec.PINCODE
                                      : sec.user
                                      ? sec.user.PINCODE
                                      : "NA"}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                {/* <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                PW :{" "}
                                {sec.user
                                  ? sec.user.DE_PASSWORD
                                    ? sec.user.DE_PASSWORD
                                    : "NA"
                                  : "NA"}
                              </span> */}
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Referral Code :{" "}
                                    {this.findReferenceCode(sec.ORDER_ID)}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    Payment Staus: {sec?.PAYMENT_STATUS}
                                  </td>
                                </span>
                              </tr>
                              <tr
                                style={{
                                  border: "1px solid #959595",
                                  fontWeight: "bold",
                                  backgroundColor: sec?.user?.TYPE === "Premium" ? "green" :
                                  sec?.user?.TYPE === "Free" ? "red" :
                                  "transparent",
                                  color: sec?.user?.TYPE === "Books" ? "black" :"white"
                                  
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderLeft: "1px solid #959595",
                                      textAlign: "left",
                                      padding: "8px",
                                     
                                      // color: "white"
                                    }}
                                    
                                  >
                                    User Type: {sec?.user?.TYPE}
                                  </td>
                                </span>
                              </tr>
                            </div>,
                          ],
                          // [
                          //   <Select
                          //     key={index}
                          //     MenuProps={{
                          //       className: classes.selectMenu,
                          //     }}
                          //     classes={{
                          //       select: classes.select,
                          //     }}
                          //     value={sec.PAYMENT_STATUS}
                          //     inputProps={{
                          //       name: "payment_status",
                          //       id: "simple-select",
                          //       onChange: (e) => {
                          //         this.handlePaymentStatus(e, sec.ID);
                          //       },
                          //     }}
                          //   >
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Pending"
                          //     >
                          //       Pending
                          //     </MenuItem>
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Collected"
                          //     >
                          //       Collected
                          //     </MenuItem>
                          //   </Select>,
                          // ],
                          // [
                          //   sec.order_from
                          //     ? sec.order_from === "ak"
                          //       ? "Ak Classes"
                          //       : "Vsmart"
                          //     : "NA",
                          // ],

                          // [this.findReferenceCode(sec.ORDER_ID)],
                          [
                            sec.DELIVERY_STATUS === "Order Placed" ? (
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Shipped" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    proDetail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: true,
                                    import: false,
                                    mail: false,
                                    mess: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                                key={index}
                                color="success"
                              >
                                Shipped
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Packaged" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                                key={index}
                                color="success"
                              >
                                {this.state.category === "Extend_Validity"
                                  ? "Extended"
                                  : "Activated"}
                              </Button>
                            ) : (
                              <Button
                                key={index}
                                color={
                                  sec.DELIVERY_STATUS === "Cancelled"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {sec.DELIVERY_STATUS}
                              </Button>
                            ),
                          ],
                          [
                            <Button
                              key={index}
                              color="danger"
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extension:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? false
                                      : true,
                                  notExpiredCourse:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? true
                                      : false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              Add Extension
                            </Button>,
                            <Button
                              key={index}
                              color={
                                sec.extension_order &&
                                sec.extension_order.length > 0
                                  ? "success"
                                  : "secondary"
                              }
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extOrders: true,
                                  extension: false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              View Extension
                            </Button>,
                          ],
                          [
                            <span key={1}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    logDetail: false,
                                    print: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mail: false,
                                    mess: false,
                                    delModal: false,
                                    import: false,
                                    proDetail: true,
                                    comment: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe417;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: true,
                                    delModal: false,
                                    mess: false,
                                    mail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: false,
                                    logDetail: false,
                                    print: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0b9;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mail: false,
                                    comment: false,
                                    logDetail: false,
                                    mess: false,
                                    import: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    delModal: false,
                                    print: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8ad;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    comment: false,
                                    import: false,
                                    print: false,
                                    mail: false,
                                    mess: false,
                                    logDetail: false,
                                    delModal: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe5c9;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: true,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="fab">&#xf39f;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    addShip: false,
                                    import: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mail: true,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0be;</i>
                              </span>
                              <br />
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    statLoading: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: true,
                                    ftofbatch: false,
                                    testModal: false,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                  axios
                                    .get(
                                      // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                      Constants.getUrls.getUserStrat +
                                        "?ordId=" +
                                        sec.ID +
                                        "&user_id=" +
                                        sec.user.ID +
                                        "&course_id=" +
                                        sec.course.ID
                                    )
                                    .then((resp) => {
                                      console.log(resp);
                                      if (resp.data.status === "success") {
                                        resp.data.notAddedData.forEach((v) => {
                                          this.state.notaddedStrategies.push({
                                            label:
                                              v.strategy.course.TITLE +
                                              " (" +
                                              v.strategy.duration +
                                              " days, " +
                                              v.strategy.strategy_type +
                                              " , " +
                                              v.strategy.type +
                                              ")",
                                            value: v.strategy.id,
                                          });
                                        });

                                        this.setState({
                                          statLoading: false,
                                          addedStrategies:
                                            resp.data.addedData.length > 0
                                              ? resp.data.addedData
                                              : [],
                                          // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                        });
                                      } else {
                                        this.setState({
                                          statLoading: false,
                                          addedStrategies: [],
                                          notaddedStrategies: [],
                                        });
                                      }
                                    });
                                }}
                              >
                                <i
                                  className="fas fa-lightbulb"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    statLoading: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: true,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                  axios
                                    .get(
                                      // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                      Constants.getUrls.getUserStrat +
                                        "?ordId=" +
                                        sec.ID +
                                        "&user_id=" +
                                        sec.user.ID +
                                        "&course_id=" +
                                        sec.course.ID
                                    )
                                    .then((resp) => {
                                      console.log(resp);
                                      if (resp.data.status === "success") {
                                        resp.data.notAddedData.forEach((v) => {
                                          this.state.notaddedStrategies.push({
                                            label:
                                              v.strategy.course.TITLE +
                                              " (" +
                                              v.strategy.duration +
                                              " days, " +
                                              v.strategy.strategy_type +
                                              " , " +
                                              v.strategy.type +
                                              ")",
                                            value: v.strategy.id,
                                          });
                                        });

                                        this.setState({
                                          statLoading: false,
                                          addedStrategies:
                                            resp.data.addedData.length > 0
                                              ? resp.data.addedData
                                              : [],
                                          // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                        });
                                      } else {
                                        this.setState({
                                          statLoading: false,
                                          addedStrategies: [],
                                          notaddedStrategies: [],
                                        });
                                      }
                                    });
                                }}
                              >
                                <i
                                  title="Import User"
                                  className="fas fa-file-import"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: true,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="fab">&#xf39f;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: true,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i class="fas fa-newspaper"></i>
                              </span>
                            </span>,
                          ],
                        ])
                      : this.state.secondaryCategories.map((sec, index) => [
                          [index + 1],
                          [sec.ORDER_ID],
                          [
                            sec.course.course_batches &&
                            sec.course.course_batches.length > 0
                              ? sec.course.course_batches
                                  .map(
                                    (cbatch) =>
                                      cbatch?.batch?.batch_name || "NA"
                                  )
                                  .join(", ")
                              : "NA",
                          ],
                          [
                            sec.ORDER_DATE.slice(8, 10) +
                              "-" +
                              sec.ORDER_DATE.slice(5, 7) +
                              "-" +
                              sec.ORDER_DATE.slice(0, 4),
                          ],
                          [sec.course ? sec.course.TITLE : "NA"],
                          [this.findCourse(sec.product_id)],
                          [
                            <div key={index}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {sec.FIRST_NAME
                                  ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
                                    " " +
                                    (sec.LAST_NAME === "null" ? "" : "")
                                  : sec.user
                                  ? sec.user.FIRST_NAME
                                    ? sec.user.FIRST_NAME
                                    : ""
                                  : //   +
                                    // " " +
                                    // (sec.user.LAST_NAME === "null"
                                    //     ? "______"
                                    //     : sec.user.LAST_NAME)
                                    "NA"}
                                ,
                              </span>
                              <br />
                              <span
                                style={{
                                  fontSize: "15px",
                                  // fontWeight:'bold',
                                  color: "blue",
                                }}
                              >
                                {sec.EMAIL
                                  ? sec.EMAIL
                                  : sec.user
                                  ? sec.user.email
                                  : "NA"}
                              </span>
                              <br />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {sec.CONTACT_NO
                                  ? sec.CONTACT_NO
                                  : sec.user
                                  ? sec.user.MOBILE
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {sec.ADDRESS
                                  ? sec.ADDRESS
                                  : sec.user
                                  ? sec.user.ADDRESS_1
                                  : "NA"}
                              </span>
                              <br />

                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {sec.CITY
                                  ? sec.CITY
                                  : sec.user
                                  ? sec.user.CITY
                                  : "NA"}
                              </span>
                              <br />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {sec.PINCODE
                                  ? sec.PINCODE
                                  : sec.user
                                  ? sec.user.PINCODE
                                  : "NA"}
                              </span>
                              <br />
                              {/* <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                PW :{" "}
                                {sec.user
                                  ? sec.user.DE_PASSWORD
                                    ? sec.user.DE_PASSWORD
                                    : "NA"
                                  : "NA"}
                              </span> */}
                              <br />
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "green",
                                }}
                              >
                                RC : {this.findReferenceCode(sec.ORDER_ID)}
                              </span>
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                Payment Staus: {sec?.PAYMENT_STATUS}
                              </span>
                            </div>,
                          ],
                          // [
                          //   <Select
                          //     key={index}
                          //     MenuProps={{
                          //       className: classes.selectMenu,
                          //     }}
                          //     classes={{
                          //       select: classes.select,
                          //     }}
                          //     value={sec.PAYMENT_STATUS}
                          //     inputProps={{
                          //       name: "payment_status",
                          //       id: "simple-select",
                          //       onChange: (e) => {
                          //         this.handlePaymentStatus(e, sec.ID);
                          //       },
                          //     }}
                          //   >
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Pending"
                          //     >
                          //       Pending
                          //     </MenuItem>
                          //     <MenuItem
                          //       classes={{
                          //         root: classes.selectMenuItem,
                          //         selected: classes.selectMenuItemSelected,
                          //       }}
                          //       value="Collected"
                          //     >
                          //       Collected
                          //     </MenuItem>
                          //   </Select>,
                          // ],
                          // [
                          //   sec.order_from
                          //     ? sec.order_from === "ak"
                          //       ? "Ak Classes"
                          //       : "Vsmart"
                          //     : "NA",
                          // ],
                          [
                            <div key={index}>
                              Coupon Code Name :
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  marginLeft: "5px",
                                  // color: "green",
                                }}
                              >
                                {sec.coupon_code_name}
                              </span>
                              <br />
                              Coupon Code :
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  marginLeft: "5px",
                                  // color: "green",
                                }}
                              >
                                {sec.COUPON_CODE}
                              </span>
                              <br />
                              Discount Amount :
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  marginLeft: "5px",
                                  // color: "green",
                                }}
                              >
                                {sec.coupon_discount_amount}
                              </span>
                              <br />
                              Amount To Pay :
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  marginLeft: "5px",
                                  // color: "green",
                                }}
                              >
                                {sec.amount_to_pay}
                              </span>
                            </div>,
                          ],
                          [sec.DISCOUNT_AMOUNT ? sec.DISCOUNT_AMOUNT : "NA"],
                          [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"],
                          // [sec.PRODUCT_EXTENDED],
                          // [this.findReferenceCode(sec.ORDER_ID)],
                          [
                            sec.DELIVERY_STATUS === "Order Placed" ? (
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Shipped" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    proDetail: false,
                                    logDetail: true,
                                    import: false,
                                    mess: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    comment: false,
                                  });
                                }}
                                key={index}
                                color="success"
                              >
                                Shipped
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Packaged" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                                key={index}
                                color="success"
                              >
                                {this.state.category === "Extend_Validity"
                                  ? "Extended"
                                  : "Activated"}
                              </Button>
                            ) : (
                              <Button
                                key={index}
                                color={
                                  sec.DELIVERY_STATUS === "Cancelled"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {sec.DELIVERY_STATUS}
                              </Button>
                            ),
                          ],
                          [
                            <Button
                              key={index}
                              color="danger"
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extension:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? false
                                      : true,
                                  notExpiredCourse:
                                    sec.course.product_expiry_status === "No" ||
                                    sec.course.product_expiry_status === ""
                                      ? true
                                      : false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              Add Extension
                            </Button>,
                            <Button
                              key={index}
                              color={
                                sec.extension_order &&
                                sec.extension_order.length > 0
                                  ? "success"
                                  : "secondary"
                              }
                              onClick={() => {
                                this.handleModal(sec);
                                this.setState({
                                  visible: true,
                                  extOrders: true,
                                  extension: false,
                                  proDetail: false,
                                  mail: false,
                                  addShip: false,
                                  logDetail: false,
                                  import: false,
                                  showStrategy: false,
                                  mess: false,
                                  print: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                            >
                              View Extension
                            </Button>,
                          ],
                          [
                            <span key={1}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    logDetail: false,
                                    print: false,
                                    mail: false,
                                    mess: false,
                                    import: false,
                                    delModal: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    proDetail: true,
                                    comment: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe417;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mail: false,
                                    comment: true,
                                    import: false,
                                    delModal: false,
                                    mess: false,
                                    logDetail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    print: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0b9;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    logDetail: false,
                                    mail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mess: false,
                                    import: false,
                                    delModal: false,
                                    print: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8ad;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    print: false,
                                    import: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mail: false,
                                    mess: false,
                                    logDetail: false,
                                    delModal: true,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe5c9;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: true,
                                    comment: false,
                                    mail: false,
                                    logDetail: false,
                                    import: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="fab">&#xf39f;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    import: false,
                                    addShip: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    mail: true,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0be;</i>
                              </span>
                              <br />
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    statLoading: true,
                                    mess: false,
                                    comment: false,
                                    showStrategy: true,
                                    ftofbatch: false,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                  axios
                                    .get(
                                      // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                      Constants.getUrls.getUserStrat +
                                        "?ordId=" +
                                        sec.ID +
                                        "&user_id=" +
                                        sec.user.ID +
                                        "&course_id=" +
                                        sec.course.ID
                                    )
                                    .then((resp) => {
                                      console.log(resp);
                                      if (resp.data.status === "success") {
                                        resp.data.notAddedData.forEach((v) => {
                                          this.state.notaddedStrategies.push({
                                            label:
                                              v.strategy.course.TITLE +
                                              " (" +
                                              v.strategy.duration +
                                              " days, " +
                                              v.strategy.strategy_type +
                                              " , " +
                                              v.strategy.type +
                                              ")",
                                            value: v.strategy.id,
                                          });
                                        });

                                        this.setState({
                                          statLoading: false,
                                          addedStrategies:
                                            resp.data.addedData.length > 0
                                              ? resp.data.addedData
                                              : [],
                                          // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                        });
                                      } else {
                                        this.setState({
                                          statLoading: false,
                                          addedStrategies: [],
                                          notaddedStrategies: [],
                                        });
                                      }
                                    });
                                }}
                              >
                                <i
                                  className="fas fa-lightbulb"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  // bottom: "4px"
                                }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    logDetail: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: false,
                                    import: true,
                                    addShip: false,
                                    mail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i
                                  title="Import User"
                                  className="fas fa-file-import"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                              <br />
                              <a
                                href={
                                  "https://admin.akclasses.in/api/downloadOnlinePdf/" +
                                  sec.ID
                                }
                                target="_blank"
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    position: "relative",
                                    // bottom: "4px"
                                  }}
                                  onClick={() => {
                                    this.downloadInvoice(sec.ID);
                                  }}
                                >
                                  <i
                                    title="Download Invoice"
                                    className="fas fa-file-invoice"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                              </a>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                }}
                                onClick={() => {
                                  this.handleFtoFBatch(
                                    sec,
                                    sec.course.ftofbatch
                                  );
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: true,
                                    testModal: false,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i className="fab">&#xf2d5;</i>
                              </span>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  position: "relative",
                                  bottom: "4px",
                                  left: "7px",
                                }}
                                onClick={() => {
                                  this.handleTestSeries(sec);
                                  this.setState({
                                    visible: true,
                                    mess: false,
                                    comment: false,
                                    showStrategy: false,
                                    ftofbatch: false,
                                    testModal: true,
                                    logDetail: false,
                                    import: false,
                                    mail: false,
                                    addShip: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: false,
                                  });
                                }}
                              >
                                <i class="fas fa-newspaper"></i>
                              </span>
                            </span>,
                          ],
                        ])
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          minWidth="3000"
          minHeight="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {/* <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4>Stacked Form</h4>
                </CardHeader> */}
                <CardBody>
                  {this.state.proDetail ? (
                    <div>
                      <h3>Product Details</h3>
                      <Table
                        tableHead={[
                          "Product Name",
                          "Price",
                          "Product Key",
                          // "F to F Key",
                          "OS Type",
                          "Media Type",
                          "Views & Validity",
                          // "Tracking No.",
                          // "Shipping Date",
                          // "Payment Status",
                          "Delivery Status",
                          "Language",
                        ]}
                        tableData={[
                          [
                            this.state.product,
                            this.state.price,
                            this.state.pKey,
                            // this.state.ftof_key,
                            this.state.osType,
                            this.state.mediaType,
                            this.state.viewValidity,
                            // this.state.trackingNo,
                            // this.state.shippingDate,
                            // this.state.payStatus,
                            this.state.delStatus,
                            this.state.language,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.print ? (
                    <div>
                      <div>
                        <img
                          src="https://akclasses.in/static/media/akLogo.93e45c6ce5fc7e0fbea5.png"
                          alt=""
                          width="200px"
                          style={{
                            paddingBottom: "15px",
                            textAlign: "center",
                            marginLeft: "350px",
                            marginRight: "350px",
                          }}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          To,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.student}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.address}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.city},{this.state.state},
                          {this.state.pincode}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.contact}
                        </span>
                      </div>
                      <br />
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          From,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Aman Khedia Classes,
                          <br />
                          The Park Meadows, Flat No. 101,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Plot No. 48, B.U. Kothari Layout Kachimet,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Amaravati Road, Nagpur. (MH) 440033
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Office: +91 876 657 5808
                        </span>
                      </div>
                      {this.state.printView ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ printView: false });
                                setTimeout(() => {
                                  window.print();
                                }, 1);
                                setTimeout(() => {
                                  this.setState({ printView: true });
                                }, 2);
                              }}
                            >
                              Print
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  ) : this.state.showStrategy ? (
                    <div>
                      <h5 style={{ textAlign: "center" }}>
                        Do You Want To Add Strategy For This Order?
                      </h5>
                      {this.state.mailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Strategy Added Successfully!
                          </span>
                        </div>
                      ) : null}
                      {this.state.mailFail ? (
                        <div>
                          <span
                            style={{
                              color: "red",
                              textTransform: "capitalize",
                            }}
                          >
                            {this.state.strMessage}
                          </span>
                        </div>
                      ) : null}
                      {this.state.statLoading ? (
                        <h6 style={{ textAlign: "center" }}>
                          Fetching Strategy Details...
                        </h6>
                      ) : (
                        <div style={{ width: "600px" }}>
                          <label htmlFor="Courses">Strategies</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.notaddedStrategies}
                            value={this.state.notAddedStrats}
                            onChange={(data) => {
                              this.setState({ notAddedStrats: data });
                            }}
                            labelledBy={"Strategies"}
                          />
                          <h6 style={{ textAlign: "center" }}>
                            Alloted Strategies
                          </h6>
                          <Table
                            tableHead={[
                              "Course",
                              "Duration",
                              "Strategy Type",
                              "User Type",
                            ]}
                            tableData={this.state.addedStrategies.map((sec) => [
                              [sec.strategies.course.TITLE],
                              [sec.strategies.duration],
                              [sec.strategies.strategy_type],
                              [sec.strategies.type],
                            ])}
                          />
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="warning"
                                onClick={this.sendEmail}
                              >
                                {this.state.loading ? <Loader /> : "Yes"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.closeModal();
                                  this.setState({
                                    visible: false,
                                    showStrategy: false,
                                  });
                                }}
                              >
                                No
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  ) : this.state.extension ? (
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Add Extension</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.selectedExtension} // Use selectedExtension
                              onChange={(e) => {
                                this.setState({
                                  selectedExtension: e.target.value,
                                });
                              }}
                            >
                              <option>Select Extension Course</option>
                              {this.state.courses.map((v, i) => (
                                <option key={i} value={v.ID}>
                                  {" "}
                                  {/* Use v.batch_id */}
                                  {v.TITLE + " (" + v.ID + ")"}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <label
                            style={{
                              color: "black",
                            }}
                          >
                            Expiry Date
                          </label>
                          <br />
                          <FormControl fullWidth>
                            <Datetime
                              timeFormat={false}
                              onChange={(e) => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    expiryDate: year + "/" + month + "/" + day,
                                  },
                                  () => {
                                    //console.log(this.state.expiryDate);
                                  }
                                );
                              }}
                              inputProps={{
                                placeholder: "Select Expiry Date",
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Price"
                            id="float"
                            inputProps={{
                              value: this.state.extPrice,
                              onChange: (e) =>
                                this.setState({ extPrice: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {this.state.viewMessage ? (
                          <GridItem xs={12} sm={12} md={12}>
                            {this.state.notifyExt ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Extension Added Successfully!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.addExtension(
                                this.state.orderId,
                                this.state.selectedExtension,
                                this.state.expiryDate,
                                this.state.extPrice
                              );
                            }}
                          >
                            {this.state.loading ? "Processing..." : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.extOrders ? (
                    <div>
                      <h3>Extension Details</h3>
                      <Table
                        tableHead={[
                          "Order No",
                          "Extension Product Details",
                          "Grand Price",
                          "Duration",
                          "Attempt",
                          // "Batches",
                          "Faculty",
                          "Expiry Date",
                        ]}
                        tableData={this.state.extensionOrders.map((order) => [
                          order.order_id,
                          order.course.TITLE,
                          order.price,
                          order.course.DURATION,
                          order.course.attempt.title,
                          // order.course.course_batches[0].batch.batch_name,
                          order.course.faculties.name,
                          order.expiry_date,
                        ])}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.notExpiredCourse ? (
                    <div>
                      <h4>Course is not expired, can not add extension.</h4>
                    </div>
                  ) : this.state.comment ? (
                    <div>
                      <h3>Add Note</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Note</label>
                          <br />
                          <CKEditor
                            required={true}
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.notes}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                notes: data,
                              });
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Note Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.logDetail ? (
                    <div>
                      <h3>Shipping Details</h3>
                      <Table
                        tableHead={[
                          "Courier Company",
                          "Tracking No.",
                          "Track Now",
                          "Update",
                        ]}
                        tableData={[
                          [
                            this.state.courierCom,
                            this.state.trackingNo,
                            <a
                              key={1}
                              href={this.state.tracLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="danger">
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            </a>,
                            <Button
                              key={1}
                              onClick={() => {
                                this.setState({
                                  visible: true,
                                  proDetail: false,
                                  addShip: false,
                                  print: false,
                                  logDetail: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                              className="btn-round"
                              color="info"
                            >
                              Update
                            </Button>,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mess ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Message to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>You Want To</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type}
                              onChange={(e) => {
                                this.setState({ type: e.target.value }, () => {
                                  if (
                                    this.state.type === "Send Written Message"
                                  ) {
                                    this.setState({ viewMessage: true });
                                  } else {
                                    this.setState({ viewMessage: false });
                                  }
                                });
                              }}
                            >
                              {this.state.options.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        {this.state.viewMessage ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Message</label>
                            <br />
                            <textarea
                              name="message"
                              cols="100"
                              rows="5"
                              onChange={(e) => {
                                this.setState({ message: e.target.value });
                              }}
                            >
                              {this.state.message}
                            </textarea>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Message Sent Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMessage}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.import ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Import{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Type</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type}
                              onChange={(e) => {
                                this.setState({ type: e.target.value });
                              }}
                            >
                              {this.state.types.map((v, i) => (
                                <option key={i} value={v.type}>
                                  {v.type}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                User Imported Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.importUser}
                          >
                            {this.state.loading ? "Importing..." : "Import"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mail ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Mail to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Mail</label>
                          <br />
                          <textarea
                            name="mail"
                            cols="100"
                            rows="5"
                            onChange={(e) => {
                              this.setState({ emailMess: e.target.value });
                            }}
                          >
                            {this.state.message}
                          </textarea>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Mail Sent Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMail}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.ftofbatch ? (
                    <div>
                      {this.state.fbatchName === "Not Found" ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <label>Allot Face To Face Batch</label>
                              <select
                                style={{ padding: "11px" }}
                                value={this.state.selectedBatchId} // Use selectedBatchId
                                onChange={(e) => {
                                  this.setState(
                                    { selectedBatchId: e.target.value },
                                    () => {
                                      // if (
                                      //   this.state.type === "Send Written Message"
                                      // ) {
                                      //   this.setState({ viewMessage: true });
                                      // } else {
                                      //   this.setState({ viewMessage: false });
                                      // }
                                    }
                                  );
                                }}
                              >
                                <option>Select Batch</option>
                                {this.state.ftofbatches.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {" "}
                                    {/* Use v.batch_id */}
                                    {v.batch_name}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </GridItem>
                          {this.state.viewMessage ? (
                            <GridItem xs={12} sm={12} md={12}>
                              {this.state.notifyBatch ? (
                                <div>
                                  <span style={{ color: "green" }}>
                                    Face To Face Batch Alloted Successfully!
                                  </span>
                                </div>
                              ) : null}
                            </GridItem>
                          ) : null}
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.allotFToFBatch(this.state.selectedBatchId);
                              }}
                            >
                              {this.state.loading ? "Processing..." : "Allot"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <h5 style={{ textAlign: "center" }}>
                          Alloted Face To Face Batch{" "}
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            {this.state.fbatchName}
                          </span>
                        </h5>
                      )}
                    </div>
                  ) : this.state.testModal ? (
                    <div>
                      {this.state.showcourseAllotMessage ? (
                        <div>
                          <span
                            style={{
                              textAlign: "center",
                              color: "red",
                              fontWeight: 500,
                              paddingBottom: "15px",
                              marginLeft: "25%",
                              fontSize: "16px",
                            }}
                          >
                            {this.state.courseAllotMessage}
                          </span>
                        </div>
                      ) : null}
                      <h5 style={{ textAlign: "center" }}>
                        Do You Want To Add Test Series For This Order?
                      </h5>
                      {this.state.mailFail ? (
                        <div>
                          <span
                            style={{
                              color: "red",
                              textTransform: "capitalize",
                            }}
                          >
                            {this.state.strMessage}
                          </span>
                        </div>
                      ) : null}
                      {this.state.statLoading ? (
                        <h6 style={{ textAlign: "center" }}>
                          Fetching Test Series Details...
                        </h6>
                      ) : (
                        <div style={{ width: "600px" }}>
                          <label htmlFor="Courses">Test Series</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.testSeries}
                            value={this.state.test}
                            onChange={(data) => {
                              this.setState(
                                {
                                  test: data,
                                },
                                () => {
                                  this.state.test.map((v) => {
                                    this.state.test_seriesId.push(v.value);
                                  });
                                  this.setState({
                                    test_seriesId: this.state.test_seriesId,
                                  });
                                }
                              );
                            }}
                            labelledBy={"Test Series"}
                          />
                          <h6 style={{ textAlign: "center" }}>
                            Alloted Test Series
                          </h6>
                          <Table
                            tableHead={["Id", "Title"]}
                            tableData={this.state.allotedTestSeries.map(
                              (sec) => [[sec.id], [sec.title]]
                            )}
                          />
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="warning"
                                onClick={this.saveTestSeries}
                              >
                                {this.state.loading ? <Loader /> : "Yes"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.closeModal();
                                  this.setState({
                                    visible: false,
                                    showStrategy: false,
                                  });
                                }}
                              >
                                No
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  ) : (
                    // <div>
                    //     <span> Select TestSeries </span>
                    //     <MultiSelect
                    //       disableSearch
                    //       options={this.state.testSeries}
                    //       name="test"
                    //       value={this.state.test}
                    //       onChange={(data) => {
                    //         // console.log(data);
                    //         this.setState(
                    //           {
                    //             test: data,
                    //           },
                    //           () => {
                    //             this.state.test.map((v) => {
                    //               this.state.test_seriesId.push(v.value);
                    //             });
                    //             this.setState({
                    //               test_seriesId: this.state.test_seriesId
                    //             })
                    //           }
                    //         );
                    //       }}
                    //     />

                    /* <h4 style={{ textAlign: "center" }}>
                        Alloted Test Series{" "}
                      </h4>
                      {this.state.testSeries.map((test, index) => (
                        <div key={index} style={{ color: "purple", fontWeight: "bold", textAlign: "left" }}>
                          {index + 1 +")"} {test.paper.TITLE}
                        </div>
                      ))} */
                    // </div>
                    <form onSubmit={this.handleSubmit}>
                      <h4 style={{ textAlign: "center" }}>
                        Update Shipping Details
                      </h4>
                      {/* <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4> */}
                      <GridContainer>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.logic}
                              name="logic"
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={(e) => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.orders.map((v, i) => (
                                <option
                                  key={i}
                                  value={
                                    v === "Activated" || v === "Extended"
                                      ? "Packaged"
                                      : v
                                  }
                                >
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              value: this.state.trackingNo,
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              value: this.state.pKey,
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {this.state.not ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Shipping Details Updated Successfuly!
                              </span>
                            </div>
                          ) : null}
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty === false ? (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default BookOnlineOrders;
