import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "@mui/material/Snackbar";
import { Modal, Box, Typography, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
import { Link } from "@material-ui/core";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(styles);

const TrackerAllotedUsers = () => {
  const perPages = [10, 25, 50, 100];
  const history = useHistory();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [delModel, setDelModel] = useState(null);
  const [userIdDel, setUserIdDel] = useState("");
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    fetchTrackerWithUser();
  }, [currentPage, perPage, debouncedSearchQuery]);

  const location = useLocation();
  const [id, setId] = useState(location.item);

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    setCurrentPage(1);
  };

  const renderPagination = () => {
    const maxPagesToShow = 5;
    const startPage = Math.floor((currentPage - 1) / maxPagesToShow) * maxPagesToShow + 1;
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  
    const pageNumbers = [...Array(endPage - startPage + 1).keys()].map((n) => n + startPage);
  
    return (
      <div style={{ textAlign: "center", marginLeft: 350, float: "right" }}>
        <Button
          style={{ width: "14px" }}
          disabled={startPage <= 1} // Disable the button when startPage is <= 1
          onClick={() => handlePageChange(startPage - maxPagesToShow)}
        >
          <ArrowBackIcon />
        </Button>
        {pageNumbers.map((page) => (
          <Button
            style={{ width: "14px", margin: "0 4px", border: "1px solid" }}
            key={page}
            color={currentPage === page ? "success" : "transparent"}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        ))}
        <Button
          style={{ width: "14px" }}
          disabled={endPage >= totalPages} // Disable the button when endPage is >= totalPages
          onClick={() => handlePageChange(startPage + maxPagesToShow)}
        >
          <ArrowForwardIcon />
        </Button>
      </div>
    );
  };
  
  

  const fetchTrackerWithUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/users-with-trackers?per_page=${perPage}&page=${currentPage}&search_query=${debouncedSearchQuery}`
      );
      const { data, last_page, total, from, to } = res.data;
      setTableData(data);
      setTotalEntries(total);
      setTotalPages(last_page);
      setFrom(from);
      setTo(to);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleOpenDeleteConfirm = (user_id) => {
    setDelModel(user_id);
    setUserIdDel(user_id);
    setOpenDeleteConfirm(true);
  };

  const handleDelete = async (user_id) => {
    try {
      await axios.delete(Constants.userWithTracker.delete + user_id);
      setDeleteSnackbarOpen(true);
      fetchTrackerWithUser();
      setOpenDeleteConfirm(false);
      setDelModel(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleCancel = () => {
    setOpenDeleteConfirm(false);
    setDelModel(null);
  };

  const Loader = () => (
    <>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={loaderStyles.container}>
        <div style={loaderStyles.spinner}></div>
      </div>
    </>
  );

  const loaderStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      border: "8px solid #f3f3f3",
      borderTop: "8px solid #3498db",
      borderRadius: "50%",
      width: "60px",
      height: "60px",
      animation: "spin 1s linear infinite",
    },
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal open={openDeleteConfirm} onClose={handleCancel}>
            <Box sx={modalStyle}>
              <Typography variant="h6">Confirm Deletion</Typography>
              <Typography>
                Are you sure you want to remove this user?
              </Typography>
              <Button color="danger" onClick={() => handleDelete(userIdDel)}>
                Confirm
              </Button>
              <Button color="info" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Modal>

          <Snackbar
            open={deleteSnackbarOpen}
            autoHideDuration={3000}
            onClose={() => setDeleteSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message="Deleted Successfully"
            ContentProps={{
              sx: { backgroundColor: "green" },
            }}
          />

          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger"></CardIcon>
                  <FormControl style={{ float: "right", marginBottom: "10px" }}>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      displayEmpty
                    >
                      {perPages.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Search by Name or Mobile Number"
                    variant="outlined"
                    style={{
                      marginLeft: "1000px",
                      height: 40,
                      marginBottom: 10,
                      backgroundColor: "#f5f5f5",
                    }}
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    tableHead={["S.N.", "User Name", "Mobile No", "Action"]}
                    tableData={tableData.map((item, index) => [
                      index + 1,
                      item.user_name || "N/A",
                      item.user_mobile_number || "N/A",
                      [
                        <Button
                          key={`view-${index}`}
                          color="warning"
                          onClick={() => history.push(`/admin/viewUser/${item.user_id}`)}
                        >
                          View
                        </Button>,
                        <Button
                          key={`delete-${index}`}
                          onClick={() => handleOpenDeleteConfirm(item.user_id)}
                          color="danger"
                        >
                          Remove
                        </Button>,
                      ],
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <div style={{ marginTop: "16px",float:"left"}}>
              Showing {from} to {to} of {totalEntries} entries.
            </div>
            {renderPagination()}
          </GridContainer>
        </>
      )}
    </div>
  );
};

export default TrackerAllotedUsers;
