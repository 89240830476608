/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import MathJax from "react-mathjax-preview";
import { Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { atob } from "js-base64";
import renderHTML from "react-render-html";
import Assignment from "@material-ui/icons/Assignment";
const classes = makeStyles(styles);
export default class AddSubjectQuestion extends Component {
  state = {
    question: "",
    level: "",
    subject: "",
    chapter: "",
    source: "",
    products: [],
    productIds: [],
    questions: [],
    levels: [],
    subjects: [],
    chapters: [],
    sources: [],
    redirect: false,
    notification: false,
    loading: false,
    submission: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    no_of_questions: "",
    greaterQuestion: false,
    alreadyAddedQuestion: false,
    addedQuestions: [],
    quizId: window.location.pathname.split("/")[3],
    courseId: window.location.pathname.split("/")[4],
    selectedQuestion: [],
    check: false,
  };

  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      // console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });
    // const quizId = window.location.pathname.split('/')[3];
    // let quizId = JSON.stringify(this.props.match.params.id);
    // let decryptedId = atob(quizId);
    // console.log(decryptedId);
    axios
      .get(Constants.getUrls.showSingleCourse + this.state.quizId)
      .then((res) => {
        console.log(res);
        console.log(res.data[0].product.no_of_questions);
        if (res.data.status === "success") {
          let remaining =
            res.data[0].product.no_of_questions -
            res.data[0].product.questionbank.length;
          console.log(remaining);
          if (remaining > 0) {
            this.setState({
              no_of_questions: remaining,
            });
          } else if (remaining == 0) {
            this.setState({
              no_of_questions: 0,
            });
          } else {
            this.setState({
              no_of_questions: res.data[0].product.no_of_questions,
            });
          }
          // this.setState({

          //     addedQuestions: res.data.test_Series.questionbank
          // });
        }
        // if(res.data.status==="success")
        // {
        //     // console.log("successsss");
        //     this.setState({
        //         no_of_questions: res.data[0].product.no_of_questions,
        //         addedQuestions: res.data[0].questionbank
        //     });
        // }
      });
      axios.get(Constants.getUrls.getActivateSources).then((resp) => {
        console.log(resp.data);
        this.setState({
          sources: resp.data,
        });
      });
  }

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            console.log(resp);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
              questions: [],
              question: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
              questions: [],
              question: "",
              bAlert: false,
            });
          });
      }
    );
  };

  handleQuestion = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        questions: [],
        question: "",
        selectedQuestion: [],
      },
      () => {
        // let quizId = JSON.stringify(this.props.match.params.id);
        // console.log(atob(quizId));
        // let payload = {
        //     paper_id: this.state.quizId,
        //     level_id: this.state.level,
        //     subject_id: this.state.subject,
        //     chapter_id: e.target.value
        // };
        // console.log(payload);
        axios
          .get(
            Constants.getUrls.getNotaddedQuestions +
              "?paper_id=" +
              this.state.quizId +
              "&level_id=" +
              this.state.level +
              "&subject_id=" +
              this.state.subject +
              "&chapter_id=" +
              e.target.value +
              "source_id=" + e.target.value
          )
          .then((resp) => {
            console.log(resp);
            // if(this.state.no_of_questions > 0)
            // {
            // if(resp.data.status=="failed")
            // {
            //     axios.get(Constants.getUrls.show_all_questions + this.state.level + "/" + this.state.subject + "/" + e.target.value).then(res => {
            //         console.log(res);
            //         res.data.forEach(v => {
            //             this.state.questions.push({
            //                 label : renderHTML(v.question),
            //                 value : v.id
            //             });
            //         });
            //         this.setState({
            //             questions: this.state.questions,
            //             question: "",
            //             cAlert: false
            //         })
            //     });
            // }
            // else
            // {
            if (resp.data.status === "success") {
              if (resp.data.notAddedData.length > 0) {
                // resp.data.notAddedData.forEach(v => {
                //     this.state.questions.push({
                //         label: renderHTML(v.question),
                //         value: v.id
                //     });
                // });
                this.setState({
                  // questions: this.state.questions,
                  questions: resp.data.notAddedData,
                  question: "",
                  cAlert: false,
                });
              } else {
                this.setState({
                  questions: [],
                  question: "",
                  cAlert: false,
                });
              }
            }
            // }
            // }
          });
        // axios.post(Constants.postUrls.show_questions_for_course, payload)
        // .then(resp => {
        //     console.log(resp);
        //     resp.data.forEach(v => {
        //         this.state.questions.push({
        //             label : v.question,
        //             value : v.id
        //         });
        //     });
        //     this.setState({
        //         questions: this.state.questions,
        //         question: "",
        //         cAlert: false
        //     })
        // });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.chapter === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.selectedQuestion.length == 0) {
      this.setState({
        dAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });

      this.state.selectedQuestion.forEach((v) => {
        this.state.productIds.push(v.val);
      });
      console.log(this.state.productIds);
      let payLoad = {
        paper_id: this.state.quizId,
        course_id: this.state.courseId,
        level_id: this.state.level,
        subject_id: this.state.subject,
        chapter_id: this.state.chapter,
        question_id: this.state.productIds,
      };
      axios.post(Constants.postUrls.addPaperQuestions, payLoad).then((resp) => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            notification: false,
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
    }
  };

  handleChange = (data) => {
    console.log(data);
    // console.log(this.state.no_of_questions);
    // const quizId = window.location.pathname.split('/')[3];
    // console.log(atob(quizId));
    // axios.post(Constants.postUrls.alreadyAddedQuestions + atob(quizId) + '/' + data[0].value).then(resp => {
    //     console.log(resp);
    //     if(resp.data.status=="failed")
    //     {
    //         this.setState({
    //             alreadyAddedQuestion: true
    //         });
    //         setTimeout( () => {
    //             this.setState({
    //                 alreadyAddedQuestion: false
    //             });
    //             // window.location.reload();
    //         }, 6000);
    //     }
    // });
    // if(data.length > this.state.no_of_questions)
    // {
    //     this.setState({
    //         greaterQuestion: true
    //     });
    //     setTimeout( () => {
    //         this.setState({
    //             greaterQuestion: false
    //         });
    //     }, 6000);
    // }
    // else
    // {
    //     this.setState({
    //         products: data,
    //         dAlert:false,
    //         greaterQuestion:false,
    //         alreadyAddedQuestion: false
    //     });
    // }
  };
  
  handleLast = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false,
      },
      () => {
        let quizId = JSON.stringify(this.props.match.params.id);
        console.log(atob(quizId));
        let payload = {
          test_series_id: atob(quizId),
          level_id: this.state.level,
          subject_id: this.state.subject,
          chapter_id: this.state.chapter,
          source_id: this.state.source,
        };
        console.log(payload);
        axios
          .post(Constants.postUrls.show_questions_for_livequiz, payload)
          .then((resp) => {
            console.log(resp);
            // if(this.state.no_of_questions > 0)
            // {
            //     if(resp.data.status=="failed")
            //     {
            //         axios.get(Constants.getUrls.show_all_questions + this.state.level + "/" + this.state.subject + "/" + e.target.value).then(res => {
            //             console.log(res);
            //             res.data.forEach(v => {
            //                 this.state.questions.push({
            //                     label : v.question,
            //                     value : v.id
            //                 });
            //             });
            //             this.setState({
            //                 questions: this.state.questions,
            //                 question: "",
            //                 cAlert: false
            //             })
            //         });
            //     }
            //     else
            //     {
            //         resp.data[0].forEach(v => {
            //             this.state.questions.push({
            //                 label : v.question,
            //                 value : v.id
            //             });
            //         });
            if (resp.data.status === "success") {
              if (resp.data[0].length > 0) {
                // resp.data.notAddedData.forEach(v => {
                //     this.state.questions.push({
                //         label: renderHTML(v.question),
                //         value: v.id
                //     });
                // });
                this.setState({
                  // questions: this.state.questions,
                  questions: resp.data[0],
                  question: "",
                  cAlert: false,
                });
              } else {
                this.setState({
                  questions: [],
                  question: "",
                  cAlert: false,
                });
              }
            }
            //     }
            // }
          });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={
            "/admin/subjectquestion/" +
            this.state.quizId +
            "/" +
            this.state.courseId
          }
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {this.state.greaterQuestion ? (
                <div
                  style={{
                    position: "relative",
                    left: "600px",
                    width: "400px",
                  }}
                >
                  <SnackbarContent
                    message={
                      "You Can Add Only " +
                      this.state.no_of_questions +
                      " Question."
                    }
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              {this.state.alreadyAddedQuestion ? (
                <div
                  style={{
                    position: "relative",
                    left: "600px",
                    width: "400px",
                  }}
                >
                  <SnackbarContent
                    message={"This Question Is Already Added."}
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Test Series Question</h4>
                </CardHeader>

                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChapter,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.chapter}
                            inputProps={{
                              required: true,
                              name: "chapter",
                              id: "simple-select",
                              onChange: this.handleQuestion,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.chapters.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Source
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.source}
                            inputProps={{
                              required: true,
                              name: "source",
                              id: "simple-select",
                              onChange: this.handleLast,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Source
                            </MenuItem>
                            {this.state.sources.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Questions"}
                        </Button>
                        <Link
                          to={{
                            pathname:
                              "/admin/subjectquestion/" +
                              this.state.quizId +
                              "/" +
                              this.state.courseId,
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{
                            marginTop: "20px",
                            fontWeight: "500",
                            color: "green",
                            fontSize: "20px",
                          }}
                        >
                          {this.state.selectedQuestion.length > 0
                            ? this.state.selectedQuestion.length +
                              " Questions Added"
                            : ""}
                        </InputLabel>
                      </GridItem>

                      <GridItem xs={12}>
                        <Card>
                          <CardBody style={{ position: "relative" }}>
                            <Table
                              tableHead={["Sr.No.", "Check", "Question"]}
                              tableData={
                                this.state.questions &&
                                this.state.questions.map((question, index) => [
                                  [index + 1],
                                  [
                                    <input
                                      type="checkbox"
                                      name="checkQuestion"
                                      style={{ marginLeft: "27px" }}
                                      onChange={(checkbox) => {
                                        console.log(checkbox.check);
                                        const selectedQuestion = this.state.selectedQuestion.find(
                                          (que) => que.val === question.id
                                        );
                                        console.log(selectedQuestion);

                                        if (selectedQuestion) {
                                          const i = this.state.selectedQuestion.findIndex(
                                            (que) => que.val === question.id
                                          );
                                          this.state.selectedQuestion.splice(
                                            i,
                                            1
                                          );
                                          this.setState(
                                            {
                                              check: true,
                                              selectedQuestion: this.state
                                                .selectedQuestion,
                                              products: question.id,
                                              dAlert: false,
                                              greaterQuestion: false,
                                              alreadyAddedQuestion: false,
                                            },
                                            () => {
                                              console.log(
                                                this.state.selectedQuestion
                                              );
                                            }
                                          );
                                        } else {
                                          this.state.selectedQuestion.push({
                                            val: question.id,
                                          });
                                          this.setState(
                                            {
                                              check: true,
                                              selectedQuestion: this.state
                                                .selectedQuestion,
                                              products: question.id,
                                              dAlert: false,
                                              greaterQuestion: false,
                                              alreadyAddedQuestion: false,
                                            },
                                            () => {
                                              console.log(
                                                this.state.selectedQuestion
                                              );
                                            }
                                          );
                                        }
                                      }}
                                    />,
                                  ],
                                  [<MathJax math={question.question} />],
                                ])
                              }
                            />
                          </CardBody>
                        </Card>
                      </GridItem>

                      {this.state.submission ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Questions Added Successfully!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                      {this.state.notification ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Question Added!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
