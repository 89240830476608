import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "variables/EditorConfiguration";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
const classes = makeStyles(styles);

const fd = new FormData();

export default class Add_Manual_Notification extends Component {
  state = {
    loading: false,
    heading: "",
    buttonLink: "",
    buttonTitle: "",
    video: "",
    image: "",
    message: "",
    project: "",
    status: "",
    notiMessage: "",
    notification: false,
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    const { name, files } = e.target;
    this.setState({
      [name]: files[0], 
    });
  };
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    fd.append("heading", this.state.heading);
    fd.append("video_upload_file", this.state.video);
    fd.append("button_title", this.state.buttonTitle);
    fd.append("button_link", this.state.buttonLink);
    fd.append("message", this.state.message);
    fd.append("image", this.state.image);
    fd.append("project", this.state.project);
    // fd.append("status", "active");
    console.log(fd);
    axios.post(Constants.postUrls.addManualNotification, fd).then((resp) => {
      console.log(resp);
      if (resp.status === 200) {
        this.setState({
          notiMessage: resp.data[0],
          notification: true,
        });
        setTimeout(() => {
          this.setState({
            notification: false,
            redirect: true,
          });
        }, 3000);
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/manualNotification" />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.notiMessage}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Manual Notification</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Heading"
                          id="float"
                          inputProps={{
                            value: this.state.heading,
                            required: true,
                            onChange: this.handleInput,
                            name: "heading",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Button Link"
                          id="float"
                          inputProps={{
                            value: this.state.buttonLink,
                            // required: true,
                            onChange: this.handleInput,
                            name: "buttonLink",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Button Title"
                          id="float"
                          inputProps={{
                            value: this.state.buttonTitle,
                            // required: true,
                            onChange: this.handleInput,
                            name: "buttonTitle",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Video Upload File</label>
                        <br />
                        <input
                          id="video"
                          name="video"
                          onChange={this.handleFile}
                          accept="video/*"
                          type="file"
                          // required={true}
                          style={{
                            marginTop: "15px",
                            height: "50px",
                            width: "200px",
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Select Image</label>
                        <br />
                        <input
                          id="image"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                          style={{
                            marginTop: "15px",
                            height: "50px",
                            width: "200px",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Project
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.project}
                            inputProps={{
                              required: true,
                              name: "project",
                              id: "simple-select",
                              onChange: this.handleInput,
                            }}
                          >
                            <MenuItem value="vsmart">V Smart</MenuItem>
                            <MenuItem value="akcmain">AKC Math</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Add Detail Message</label>
                        <br />
                        {/* <CKEditor */}
                        {/* <textarea
                      
                          required={true}
                          editor={ClassicEditor}
                          data={this.state.message}
                          // config={editorConfiguration}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              message: data,
                              bAlert: false,
                            });
                          }}
                        /> */}

                        <textarea 
                        name="message" 
                        value={this.state.message}
                        onChange={this.handleInput}
                      
                        placeholder="Enter notification message"
                        rows="6"
                        cols="50"
                        >

                        </textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Notification"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/manualNotification",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
