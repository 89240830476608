import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import Constants from "variables/Constants";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles(styles);

const MainMaster = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [subjectToDelete, setSubjectToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterWeightage, setChapterWeightage] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [currentSubjectId, setCurrentSubjectId] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [subChapters, setSubChapters] = useState([]);
  const [chapterModal, setChapterModal] = useState(false);
  const [subChapterModal, setSubChapterModal] = useState(false);
  const [addChapter, setAddChapter] = useState(false);
  const [updateChapModal, setUpdateChapModal] = useState(false);
  const [updateChapterId,setUpdateChapterId] = useState("")

  useEffect(() => {
    fetchAllSubject();
    fetchAllChapter();
    fetchAllSubChapter();
  }, []);

  const fetchAllChapter = async () => {
    try {
      const res = await axios.get(Constants.TrackerChapter.getAll);
      console.log(res.data.data, "chapters");
      setChapters(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllSubChapter = async () => {
    try {
      const res = await axios.get(
        // `https://admin.akclasses.in/api/subchapter/`
        Constants.TrackerSubChapter.getAll
      );
      console.log(res.data.data);
      setSubChapters(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllSubject = async () => {
    try {
      const res = await axios.get(Constants.TrackerSubject.getAll);
      console.log(res.data.data);
      setTableData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createSubject = async (event) => {
    event.preventDefault();
    const payLoad = { title, description };
    try {
      await axios.post(Constants.TrackerSubject.postSuject, payLoad);
      handleCloseCreate();
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = async (id) => {
    await axios.delete(
      // `https://admin.akclasses.in/api/subject/${subjectToDelete.id}`
      Constants.TrackerSubject.getAll + "/" + subjectToDelete.id
    );
    setDeleteSnackbarOpen(true);
    fetchAllSubject();
    handleCloseDeleteConfirm();
  };

  const handleDelete = (subject) => {
    setSubjectToDelete(subject);
    setOpenDeleteConfirm(true);
  };

  const handleOpenUpdateModal = (subject) => {
    setSelectedSubject(subject);
    setTitle(subject.title);
    setDescription(subject.description);
    setOpenUpdate(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = { title, description };
    try {
      await axios.post(
        // `https://admin.akclasses.in/api/subject/${selectedSubject.id}`
        Constants.TrackerSubject.getAll + "/" + selectedSubject.id,
        payLoad
      );
      setSnackbarOpen(true);
      handleCloseUpdate();
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    setTitle("");
    setDescription("");
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setSelectedSubject(null);
    setTitle("");
    setDescription("");
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    setSubjectToDelete(null);
  };

  const handleStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(
        // `https://admin.akclasses.in/api/subject/${id}`
        Constants.TrackerSubject.getAll + "/" + id,
        {
          status: updatedStatus,
        }
      );
      fetchAllSubject();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitChapter = async (e) => {
    e.preventDefault();
    const payLoad = {
      title: chapterTitle,
      description: chapterWeightage,
      subject_id: currentSubjectId,
    };
    try {
      const res = await axios.post(Constants.TrackerChapter.getAll, payLoad);
      console.log(res.data);
      setAddChapter(false);
      fetchAllChapter();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChapterUpdate = async (e) => {
    e.preventDefault();
    const payLoad = { 
      title:chapterTitle, 
      description:chapterWeightage,
      subject_id: currentSubjectId };
    try {
      await axios.post(
        Constants.TrackerChapter.getAll + "/" + updateChapterId,
        payLoad
      );

      fetchAllChapter();
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  

  return (
    <div>
      {/* chapter model tabel */}
      <Modal
        open={chapterModal}
        onClose={() => setChapterModal(false)}
        aria-labelledby="chapter-modal-title"
        aria-describedby="chapter-modal-description"
      >
        <Box sx={modalStyle} style={{ width: 610 }}>
          <CancelIcon
            style={{ marginLeft: 530, cursor: "pointer" }}
            onClick={() => setChapterModal(false)}
          />
          <Button onClick={() => setAddChapter(true)} color="primary">
            Add chapter
          </Button>

          <Typography id="chapter-modal-title" variant="h6" component="h2">
            Chapter Table
          </Typography>

          <Table
            tableHead={[
              "ID",
              "SUBJECT NAME",
              "TITLE",
              "Weightage",
              "STATUS",
              "Actions",
              "Subchapter"
            ]}
            tableData={chapters.map((item, index) => [
              index + 1,
              item.subject?.title,
              item.title,
              item.description,
              [
                <Select
                  key={index}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={item.status}
                  inputProps={{
                    name: "status",
                  }}
                  onChange={(e) => handleStatusChange(item.id, e.target.value)}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="active"
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="inactive"
                  >
                    Inactive
                  </MenuItem>
                </Select>,
              ],
              [
                <Button
                  key={`update-${index}`}
                  onClick={() => {
                    setUpdateChapterId(item.id)
                    setUpdateChapModal(true)
                  }}
                  className="btn-round"
                  color="warning"
                >
                  Update
                </Button>,
                <Button
                  key={`delete-${index}`}
                  onClick={() => confirmDelete(item.id)}
                  className="btn-round"
                  color="danger"
                >
                  Delete
                </Button>,
              ],
              [   <Button
                key={`delete-${index}`}
                // onClick={() => confirmDelete(item.id)}
                className="btn-round"
                color="danger"
              >
                Subchapter
              </Button>,]
            ])}
          />
        </Box>
      </Modal>

      {/* update chapter model */}
      <Modal
        open={updateChapModal}
        onClose={() => setUpdateChapModal(false)}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Chapter Form
          </Typography>
          <form
          onSubmit={handleChapterUpdate}
          >
            {/* Subject Dropdown */}
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setCurrentSubjectId(e.target.value)}
              >
                {tableData.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={chapterTitle}
              onChange={(e) => setChapterTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={chapterWeightage}
              onChange={(e) => setChapterWeightage(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="danger"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setUpdateChapModal(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Create Modal */}
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Subject Form
          </Typography>
          <form onSubmit={createSubject}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="success"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="warning"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseCreate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Update Modal */}
      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Subject
          </Typography>
          <form onSubmit={handleUpdate}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="danger"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2 }}>
            <strong>Are you sure you want to delete this subject?</strong>
          </Typography>
          <Button
            className="btn-round"
            color="danger"
            variant="contained"
            onClick={confirmDelete}
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
          <Button
            className="btn-round"
            color="info"
            variant="contained"
            onClick={handleCloseDeleteConfirm}
            sx={{ mt: 2, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={addChapter}
        onClose={() => setAddChapter(false)}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Chapter Form
          </Typography>
          <form onSubmit={handleSubmitChapter}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              value={chapterTitle}
              onChange={(e) => setChapterTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="weightage"
              required
              value={chapterWeightage}
              onChange={(e) => setChapterWeightage(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="danger"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={() => setAddChapter(false)}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
            textAlign: "center",
          },
        }}
      />
      
      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
            textAlign: "center",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>

            <CardBody>
              <Button
                className="btn-round"
                color="info"
                onClick={() => setOpenCreate(true)}
              >
                Add Subject
              </Button>

              <Table
                tableHead={[
                  "Id",
                  "Subjects",
                  "Weightage",
                  "Status",
                  "Actions",
                ]}
                tableData={tableData.map((item, index) => [
                  index + 1,
                  item.title,
                  item.description,

                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status}
                      inputProps={{
                        name: "status",
                      }}
                      onChange={(e) =>
                        handleStatusChange(item.id, e.target.value)
                      }
                      onClick={(e) => console.log(e.target.value)}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <div
                      key={`action-buttons-${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Button
                        onClick={() => handleOpenUpdateModal(item)}
                        className="btn-round"
                        color="warning"
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => handleDelete(item)}
                        className="btn-round"
                        color="danger"
                      >
                        Delete
                      </Button>
                    </div>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default MainMaster;
