import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";

const useStyles = makeStyles(styles);

export default class Update_Manual_Notification extends Component {
  state = {
    id: "",
    loading: false,
    heading: "",
    buttonLink: "",
    buttonTitle: "",
    video: null, 
    image: null, 
    message: "",
    project: "",
    status: "",
    notiMessage: "",
    notification: false,
    redirect: false, 
    imagePreview: "",
  };

  componentDidMount() {
    const categoryData = this.props.location.state;
    if (categoryData) {
      this.setState({
        heading: categoryData.heading || "",
        buttonLink: categoryData.button_link || "",
        buttonTitle: categoryData.button_title || "",
        message: categoryData.message || "",
        project: categoryData.project || "",
        status: categoryData.status || "",
        id: categoryData.id,
        image: categoryData.image || null,
        imagePreview: categoryData.image ? `${Constants.imageBaseUrl}/${categoryData.image}` : "",
      });
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    const file = e.target.files[0];
    this.setState({
      [e.target.name]: file,
      imagePreview: file ? URL.createObjectURL(file) : this.state.imagePreview, // Update preview
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const fd = new FormData();
    fd.append("like_count", this.props.location.state.like_count);
    fd.append("views_count", this.props.location.state.views_count);
    fd.append("share_count", this.props.location.state.share_count);
    fd.append("heading", this.state.heading);
    fd.append("message", this.state.message);
    fd.append("image", this.state.image);
    fd.append("project", this.state.project);
    fd.append("status", this.state.status);
    fd.append("button_link", this.state.buttonLink);
    fd.append("button_title", this.state.buttonTitle);
    if (this.state.video) {
      fd.append("video_upload_file", this.state.video);
    }

    axios
      .post(`${Constants.postUrls.addManualNotification}/${this.state.id}`, fd)
      .then((resp) => {
        if (resp.status === 200) {
          this.setState({
            notiMessage: resp.data[0],
            notification: true,
            redirect: true,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error updating the notification!", error);
        this.setState({
          loading: false,
          notification: true,
          notiMessage: "Update failed. Please try again.",
        });
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/admin/manualNotification" />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification && (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.notiMessage}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Notification</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Heading"
                          id="float"
                          inputProps={{
                            value: this.state.heading,
                            required: true,
                            onChange: this.handleInput,
                            name: "heading",
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Button Link"
                          id="float"
                          inputProps={{
                            value: this.state.buttonLink,
                            // required: true,
                            onChange: this.handleInput,
                            name: "buttonLink",
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Button Title"
                          id="float"
                          inputProps={{
                            value: this.state.buttonTitle,
                            // required: false,
                            onChange: this.handleInput,
                            name: "buttonTitle",
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Video Upload File</label>
                        <br />
                        <input
                          id="video"
                          name="video"
                          onChange={this.handleFile}
                          accept="video/*"
                          type="file"
                          style={{
                            marginTop: "15px",
                            height: "50px",
                            width: "200px",
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
  <label>Select Image</label>
  <br />
  {this.state.imagePreview && (
    <img
      src={this.state.imagePreview}
      alt="Selected"
      style={{ marginTop: "15px", height: "100px", width: "auto", display: "block" }}
    />
  )}
  <input
    id="image"
    name="image"
    onChange={this.handleFile}
    accept="image/*"
    type="file"
    style={{
      marginTop: "15px",
      height: "50px",
      width: "200px",
    }}
  />
</GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required
                          fullWidth
                          className={useStyles.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={useStyles.selectLabel}
                          >
                            Select Project
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: useStyles.selectMenu,
                            }}
                            classes={{
                              select: useStyles.select,
                            }}
                            value={this.state.project}
                            inputProps={{
                              required: true,
                              name: "project",
                              id: "simple-select",
                              onChange: this.handleInput,
                            }}
                          >
                            <MenuItem value="vsmart">V Smart</MenuItem>
                            <MenuItem value="akcmain">AKC Math</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Add Detail Message</label>
                        <br />
                        <textarea
                          name="message"
                          value={this.state.message}
                          onChange={this.handleInput}
                          placeholder="Updated message"
                          rows="6"
                          cols="50"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Notification"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/manualNotification",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
