import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
// import SnackbarContent from "../views/Components/Notifications";
import TextField from "@mui/material/TextField";
import Constants from "../ak_web/src/components/common/Constants";
import { Alert } from "@mui/material";

function WalletManagement() {
  let [walletData, setWalletData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [search, setSearch] = useState("");
  const [approveMsg, setApproveMsg] = useState(false);

  function getAllData() {
    setLoading(true);
    console.log("test");
    axios
      .get(Constants.getUrls.getallWallet + `?q=${search}&status=pending`)
      .then((res) => {
        console.log("wallet data", res);
        setWalletData(res?.data?.data?.data);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAllData();
  }, [search]);

  function walletSearch(e) {
    setSearch(e.target.value);
    // setPage(1);
  }

  const handleStatus = (id) => {
    const payload = {
      id: id,
      status: "approved",
    };

    axios
      .put(
        `https://admin.akclasses.in/api/wallet/redemptions/update-status`,
        payload
      )
      .then(() => {
        // alert("Status change successfully");
        setApproveMsg(true);
        setTimeout(() => {
          getAllData();
          setApproveMsg(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: "80px",
          right: "20px",
          zIndex: 9999, // Ensures it stays above other elements
          width: "auto",
        }}
      >
        {approveMsg ? (
          <Alert severity="success" variant="filled">
            <b>Settled updated successfully</b>
          </Alert>
        ) : null}
      </div>

      <div>
        <h3>Pending Wallet</h3>
      </div>
      {/* <Button
        variant="contained"
        color="warning"
        sx={{
          marginRight: "10px",
          color: "white", 
          "&:hover": {
            color: "white", 
          },
        }}
        component={Link}
        to="/admin/pendingwallets"
      >
        Pending
      </Button> */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          variant="contained"
          sx={{
            color: "white",
            "&:hover": {
              color: "white",
            },
          }}
          color="success"
          component={Link}
          to="/admin/approvedwallets"
        >
          Approved
        </Button>

        <Box
          component="form"
          sx={{ "& > :not(style)": { width: "30ch" } }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Search by Name & Mobile"
            size="small"
            onChange={walletSearch}
            variant="outlined"
          />
        </Box>
      </Box>

      <TableContainer component={Paper} style={{ marginTop: "25px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Amount(Rs)</TableCell>
              <TableCell>Requested Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {loading ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <h3>Loading...</h3>
              </TableCell>
            </TableRow>
          ) : walletData?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <h3>No data found!</h3>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {walletData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.user?.FIRST_NAME ? item?.user?.FIRST_NAME : "NA"}
                  </TableCell>
                  <TableCell>
                    {item?.user?.MOBILE ? item?.user?.MOBILE : "NA"}
                  </TableCell>
                  <TableCell>{item?.amount ? item?.amount : "NA"}</TableCell>
                  <TableCell>
                    {item?.requested_at ? item?.requested_at : "NA"}
                  </TableCell>
                  <TableCell>{item?.status}</TableCell>
                  <TableCell>
                    {item?.status === "pending" && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleStatus(item?.id)}
                      >
                        Settled Now
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default WalletManagement;
