import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";
import { alignProperty } from "@mui/material/styles/cssUtils";
const classes = makeStyles(styles);
const perPages = ["1", "25", "50", "100"];
class Schedule extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    levels: [],
    streams: [],
    categories: [],
    category: "",
    level: "",
    stream: "",
    secondaryCategories: [],
    status: "",
    chatStatus: "",
    active: 1,
    act: 0,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    delNot: false,
    ord: true,
    chat: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    showNoti:false,
    notificationModalVisible: false,
    durations: [
      { label: "Quick", value: "0" },
      { label: "1 Min", value: "1" },
      { label: "5 Min", value: "5" },
      { label: "15 Min", value: "15" },
      { label: "30 Min", value: "30" },
      { label: "60 Min", value: "60" },
      { label: "120 Min", value: "120" },
    ],
    shedule: {},
    selectedDuration: [],
  };
  componentDidMount() {
    this.getSchedules();
    axios
      .get("https://admin.akclasses.in/api/courseCategoriesR")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          categories: resp.data,
        });
      });
  }
  getSchedules = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/portal/index_without_type?per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/portal/delete/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
         this.setState({
          notifyDelete: false,
          visible: false,
         })
         this.getSchedules();
          // window.location.reload();
          
        }, 1000);
      });
  };
  handleModal = (sec) => {
    this.setState({
      rowId: sec.id,
      visible: true,
    });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/index_without_type?per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                ord: true,
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  // handleModal = id => {
  //   this.setState({ act: id });
  //   axios
  //     .post("https://admin.akclasses.in/api/portal/delete/" + id)
  //     .then(() => {
  //       // console.log(res.data);
  //       this.setState({
  //         delNot: true,
  //         act: 0
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     });
  // };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post("https://admin.akclasses.in/api/portal/status/" + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getSchedules();
                }
              );
            }, 1000);
          });
      }
    );
  };

  handleChatStatus = (e, id, title) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        chat: true,
      },
      () => {
        const payload = {
          notification: {
            title: title,
            text: "Live Streaming Now!",
            sound: "default",
            badge: "8",
            color: "#FF9900",
            click_action: "MY_INTENT",
          },
          data: {
            obj: {
              Variable1: "value1",
              Variable2: "value2",
            },
          },
          priority: "high",
          to: "/topics/allUsers",
        };
        if (this.state.chatStatus === "active") {
          axios
            .post("https://fcm.googleapis.com/fcm/send", payload)
            .then(() => {
              // console.log(res)
            });
        }
        axios
          .post("https://admin.akclasses.in/api/portal/status/" + id, {
            chat_status: this.state.chatStatus,
          })
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.getSchedules();
              // window.location.reload();
            }, 1000);
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/index_without_type?per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/portal/index_without_type?per_page=" +
        this.state.per_page +
        "&course_category_id=" +
        this.state.category +
        "&level_id=" +
        this.state.level +
        "&stream_id=" +
        this.state.stream +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: [],
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        bAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: "",
            });
          });
      }
    );
  };

  searchRecord = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/portal/index_without_type?per_page=" +
          this.state.per_page +
          "&course_category_id=" +
          this.state.category +
          "&level_id=" +
          this.state.level +
          "&stream_id=" +
          this.state.stream
      )
      .then((resp) => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            ord: false,
            btnIdArray,
            secondaryCategories: resp.data.data,
            fromInc: 1,
            active: 1,
            toInc: 5,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  manageNotification = async () => {

    this.setState({
      notificationModalVisible: false,
    });


    const payload = {
      live_portal_id: this.state.shedule.id,
      duration: this.state.selectedDuration.map((dura) => dura.value),
    };
    const { data } = await axios.post(
      `https://admin.akclasses.in/api/meetings/StoreScheduleMeetingNotification`,
      payload
    );
    if (data.status === "success") {
      this.setState({
        showNoti: true,
        notificationModalVisible: false,
        selectedDuration: [],
      });
      setTimeout(() => {
        this.setState({ showNoti: false });
      }, 2000);
    }
    console.log(data); 
  };

  render() {
    return (
      <div>
        <GridContainer>

          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={
                  this.state.chat ? "Chat Status Changed!" : "Status Changed!"
                }
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Schedule Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
           {this.state.showNoti ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Live Quiz Notification Updated successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridItem xs={12} sm={12} md={4}>
                  <h6 stylle={{ marginBottom: "0px" }}>Filter</h6>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    required={true}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Category
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.category}
                      inputProps={{
                        name: "category",
                        id: "simple-select",
                        onChange: this.handleLevel,
                      }}
                    >
                      {this.state.categories.map((v, i) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          key={i}
                          value={v.ID}
                        >
                          {v.CATEGORY}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.state.aAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Level
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.level}
                      inputProps={{
                        required: true,
                        name: "level",
                        id: "simple-select",
                        onChange: this.handleStream,
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value=""
                      >
                        Select Category First
                      </MenuItem>
                      {this.state.levels.map((v, i) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          key={i}
                          value={v.id}
                        >
                          {v.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.state.bAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Stream
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.stream}
                      inputProps={{
                        required: true,
                        name: "stream",
                        id: "simple-select",
                        onChange: (e) => {
                          this.setState({
                            cAlert: false,
                            stream: e.target.value,
                          });
                        },
                        // //console.log(e.target.value);
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value=""
                      >
                        Select Level First
                      </MenuItem>
                      {this.state.streams.map((v, i) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          key={i}
                          value={v.id}
                        >
                          {v.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.state.cAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <Button
                  onClick={this.searchRecord}
                  style={{
                    position: "relative",
                    left: "20%",
                  }}
                  color="success"
                >
                  Search
                </Button>
                <h3>Schedules</h3>
                <Link to="/admin/addschedule">
                  <Button className="" color="warning">
                    Add Schedule
                  </Button>
                </Link>
                <Table
                  style={{ textAlign: "center" }}
                  tableHead={[
                    "Sr No.",
                    "Title",
                    "Batch",
                    "Faculty",
                    "Date",
                    "Time",
                    "Status",
                    "Action",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.title],
                      [sec?.batch?.batch_name],
                      [sec?.faculty?.name],
                      [sec.date.substring(0, 10)],
                      [sec.time],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleSelect(e, sec.id);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="active"
                          >
                            ACTIVE
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="inactive"
                          >
                            INACTIVE
                          </MenuItem>
                        </Select>,
                      ],

                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updateschedule/" + sec.id,
                            state: { categoryData: sec },
                          }}
                        >
                          <Button className="btn-round" color="success">
                            Update
                          </Button>
                        </Link>,
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="rose"
                        >
                          {this.state.act === parseInt(sec.id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>,

                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={() => {
                            this.setState({
                              shedule: sec,
                              notificationModalVisible: true,
                            });
                          }}
                        >
                          Manage
                        </Button>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
        
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Schedule Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
          
        )}
         <Modal
      
            visible={this.state.notificationModalVisible}
            width="500"
            height="100"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({
                notificationModalVisible: false,
                selectedDuration: [],
              })
            }
          
          >
            
            
              <GridItem xs={12} sm={12} md={12}>
                <Card 
            style={{position:'absolute',top:'-84%',left:'0%'}}
            >
                  <CardBody>
                    <div>
                      <h3>{this.state.shedule?.title}</h3>
                      {/* <strong>{this.state.shedule?.title}</strong> */}
                      
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MultiSelect
                            style={{ width: "50%", marginBottom: 100 }}
                            options={this.state.durations}
                            onChange={(data) => {
                              this.setState({ selectedDuration: data });
                            }}
                            value={this.state.selectedDuration}
                            labelledBy={"Durations"}
                          />
                          <br />
                          <GridContainer>
                            <GridItem xs={6} style={{ textAlign: "right" }}>
                              <Button
                                className=""
                                color="rose"
                                onClick={() => {
                                  this.setState({
                                    notificationModalVisible: false,
                                    selectedDuration: [],
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </GridItem>
                            <GridItem xs={6}>
                              <Button
                                className=""
                                color="success"
                                onClick={() => {
                                  this.manageNotification();
                                }}
                              >
                                Save
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            
          </Modal>
      </div>
    );
  }
}

export default Schedule;
